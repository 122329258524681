import React, {Fragment} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const SubscriptionRequiredModal = (props) => {
    const {
        open,
        handleConfirm,
        handleClose,
        loading = false,
    } = props

    const { t } = useTranslation();

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='centred'>{t("Modals.ActivateTitle")}</h2>
                    </div>
                    <div className='centred'>
                        {t("Modals.ActivateText")}
                    </div>
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleConfirm}
                            >
                                {t("Modals.ActivatePlanButton")}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                className='primaryColorOutlined'
                                disabled={loading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t("Modals.ActivateGotItButton")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SubscriptionRequiredModal
