import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'
import Types from './types'

const INITIAL_STATE = {
    runDataUpdate: 0,
    overallSources: null,
    source: null,
    list: null,
    lead: null,
    sources: [],
    lists: [],
    leads: [],
    newImport: null,
    leadColumns: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.SET_SOURCE:
            if (action.payload)
                return {
                    ...state,
                    source: action.payload,
                    leadColumns: null
                }
            else {
                return {
                    ...state,
                    source: action.payload,
                    lists: [],
                    leads: [],
                    leadColumns: null,
                    list: null,
                    lead: null
                }
            }

        case Types.SET_LIST:
            if (action.payload)
                return {
                    ...state,
                    ...state,
                    list: action.payload,
                    leadColumns: null
                }
            else {
                return {
                    ...state,
                    list: action.payload,
                    leads: [],
                    leadColumns: null,
                    lead: null
                }
            }

        case Types.SET_LEAD:
            return {
                ...state,
                lead: action.payload
            }

        case Types.SET_SOURCES:
            if (action.payload)
                return {
                    ...state,
                    sources: action.payload,
                    leadColumns: null
                }
            else {
                return {
                    ...state,
                    sources: action.payload,
                    lists: [],
                    leads: [],
                    leadColumns: null,
                    list: null,
                    lead: null
                }
            }

        case Types.ADD_SOURCE:
            const updatedSources = [...state.sources]
            updatedSources.push(action.payload)
            return {
                ...state,
                sources: updatedSources
            }

        case Types.SET_LISTS:
            return {
                ...state,
                lists: action.payload
            }

        case Types.ADD_LIST:
            const updatedLists = [...state.lists]
            updatedLists.push(action.payload)
            return {
                ...state,
                lists: updatedLists
            }

        case Types.SET_LEADS:
            return {
                ...state,
                leads: action.payload
            }

        case Types.SET_OVERALL_SOURCES:
            return {
                ...state,
                overallSources: action.payload
            }

        case Types.SET_RUN_DATA_UPDATE:
            return {
                ...state,
                runDataUpdate: action.payload
            }

        case Types.SET_NEW_IMPORT:
            return {
                ...state,
                newImport: action.payload
            }

        case Types.SET_LEAD_COLUMNS:
            return {
                ...state,
                leadColumns: action.payload
            }

        default:
            return state
    }
}

const persistConfig = {
    key: 'sources',
    storage
}

export default persistReducer(persistConfig, reducer)
