import React, {useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {
    getAttributes,
    getCampaign,
    getCampaignLogs,
    getCampaignSegments,
    getCampaignSources,
    getCampaignSteps,
    getSegments,
    getSources,
} from '../api'

const CampaignStateContext = React.createContext(undefined)
const CampaignDispatchContext = React.createContext(undefined)

const CampaignProvider = ({children}) => {

    const {campaignId} = useParams()
    const [campaign, setCampaign] = useState()
    const [segments, setSegments] = useState(   )
    const [sources, setSources] = useState()
    const [logs, setLogs] = useState([])
    const [steps, setSteps] = useState([])
    const [availableSegments, setAvailableSegments] = useState([])
    const [availableSources, setAvailableSources] = useState([])
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [smsStatuses, setSmsStatuses] = useState([])
    const [engagements, setEngagements] = useState([])

    const campaignRequest = useQuery(
        ['campaignView', campaignId],
        () => getCampaign(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignRequest.isSuccess) setCampaign(campaignRequest.data.data)
    }, [campaignRequest.isSuccess, campaignRequest.data])

    const campaignSegmentsRequest = useQuery(
        ['campaignSegmentsView', campaignId],
        () => getCampaignSegments(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignSegmentsRequest.isSuccess) setSegments(campaignSegmentsRequest.data.data)
    }, [campaignSegmentsRequest.isSuccess, campaignSegmentsRequest.data])

    const campaignSourcesRequest = useQuery(
        ['campaignSourcesView', campaignId],
        () => getCampaignSources(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignSourcesRequest.isSuccess) setSources(campaignSourcesRequest.data.data)
    }, [campaignSourcesRequest.isSuccess, campaignSourcesRequest.data])

    const campaignLogsRequest = useQuery(
        ['campaignLogsView', campaignId],
        () => getCampaignLogs(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignLogsRequest.isSuccess) setLogs(campaignLogsRequest.data.data)
    }, [campaignLogsRequest.isSuccess, campaignLogsRequest.data])

    const campaignStepsRequest = useQuery(
        ['campaignStepsView', campaignId],
        () => getCampaignSteps(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignStepsRequest.isSuccess) setSteps(campaignStepsRequest.data.data)
    }, [campaignStepsRequest.isSuccess, campaignStepsRequest.data])

    const attributesRequest = useQuery(
        ['attributes'],
        () => getAttributes(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (attributesRequest.isSuccess) setAvailableAttributes(attributesRequest.data.data)
    },[attributesRequest.isSuccess, attributesRequest.data])

    const campaignHasResources = useMemo(() => {
        if (campaignSegmentsRequest.isSuccess && campaignSourcesRequest.isSuccess) {

            return !!(!segments || !sources ||  segments.length || sources.length)
        }
        return undefined
    }, [
        campaignSegmentsRequest.isSuccess,
        campaignSourcesRequest.isSuccess,
        segments,
        sources
    ])

    const availableSegmentsRequest = useQuery(
        ['segmentsList'],
        () => getSegments(1, 100),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (availableSegmentsRequest.isSuccess)
            setAvailableSegments(availableSegmentsRequest.data.data)
    }, [
        availableSegmentsRequest.isSuccess, availableSegmentsRequest.data
    ])

    const availableSourcesRequest = useQuery(
        ['sourcesList'],
        () => getSources(1, 100),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (availableSourcesRequest.isSuccess)
            setAvailableSources(availableSourcesRequest.data.data)
    }, [
        availableSourcesRequest.isSuccess, availableSourcesRequest.data
    ])

    return (
        <CampaignStateContext.Provider value={{
            campaign,
            segments,
            sources,
            logs,
            steps,
            campaignRequest,
            campaignSegmentsRequest,
            campaignSourcesRequest,
            campaignLogsRequest,
            campaignStepsRequest,
            campaignHasResources,
            availableSegments,
            availableSources,
            availableSegmentsRequest,
            availableSourcesRequest,
            availableAttributes,
            smsStatuses,
            engagements
        }}>
            <CampaignDispatchContext.Provider value={{
                setSmsStatuses,
                setEngagements
            }}>
                {children}
            </CampaignDispatchContext.Provider>
        </CampaignStateContext.Provider>
    )
}

const useCampaignState = () => {
    const context = React.useContext(CampaignStateContext)
    if (context === undefined) {
        throw new Error('useCampaignState must be used within a CampaignProvider')
    }
    return context
}

const useCampaignDispatch = () => {
    const context = React.useContext(CampaignDispatchContext)
    if (context === undefined) {
        throw new Error('useCampaignDispatch must be used within a CampaignProvider')
    }
    return context
}

export {
    CampaignProvider,
    useCampaignState,
    useCampaignDispatch
}
