import React, {Fragment, useEffect, useState} from 'react';
import SaveInviteModal from '../../../components/modals/SaveInviteModal';
import EventEmitter from '../../../Utils/EventEmitter';

const InviteCreateAction = ({onSuccessSave}) => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true);

        const listener = EventEmitter.addListener('InviteCreate', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    return (
        <Fragment>
            <SaveInviteModal
                onSuccess={onSuccessSave}
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false);
                }}
            />
        </Fragment>
    );
};

export default InviteCreateAction;
