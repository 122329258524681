// import React from 'react'
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const enTranslation = require("./locales/en/translation.json")
const uaTranslation = require("./locales/ua/translation.json")


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      ua: {
        translation: uaTranslation
      },
    },
    lng: localStorage.getItem('language') || 'ua',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
