import React, {useEffect, useState} from 'react'
import {useAppDispatch} from '../Contexts/AppContext'
import ResendActivationCodeModal from './modals/ResendActivationCodeModal'
import {Alert} from '@material-ui/lab'
import {useMutation} from 'react-query'
import {resendActivationCode} from '../api'
import AlertToastr from '../Utils/alert'
import {useTranslation} from "react-i18next";

const AccessDenied = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs, setContextMenu} = useAppDispatch()
    const [showModal, setShowModal] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const mutation = useMutation(resendActivationCode)

    useEffect(() => {
        setBreadcrumbs([{label: t('Access Denied.Access Denied')}])
    }, [setBreadcrumbs, t])

    useEffect(() => {
        setContextMenu([])
    }, [setContextMenu])

    const handleSuccessResend = () => {
        setShowSuccessMessage(true)
    }

    useEffect(() => {
        if (mutation.error) {
            AlertToastr.showErrorResponseAlert(mutation.error)
        }
    }, [mutation.error])

    const resendCode = async () => {
        try {
            await mutation.mutateAsync('')
            // reset()
            setShowSuccessMessage(true)
            // handleClose()
            // queryClient.invalidateQueries('alphaNames').then()
            // queryClient.invalidateQueries('activeAlphaNames').then()
        } catch (e) {
            console.log(e)
        }
    }


    if (showSuccessMessage) {
        return (
            <div className="pageHolder">
                <div className="formHolder">
                    <Alert>
                        {t('Access Denied.A verification link has been resent to your email account')}
                    </Alert>
                </div>
            </div>

        )
    }

    return (
        <div className="pageHolder" style={{paddingTop: '15px', color: 'rgb(144 144 144)', fontSize: '14px'}}>
            <p className="text-center">You're not activated yet.</p>
            {/*<p className="text-center">Please, contact your system administrator.</p>*/}
            <p className="text-center">{t('Access Denied.If you didnt receive an activation link, please press')} <a onClick={(e) => {e.preventDefault(); resendCode()}} href="/"  className="text-mute text-semi-bold linkColorNormal cursor-pointer">{t('Access Denied.here')}</a> </p>
            <ResendActivationCodeModal
                open={showModal}
                handleClose={() => {
                    setShowModal(false)
                }}
                onSuccess={handleSuccessResend}
            />
        </div>
    )
}

export default AccessDenied

