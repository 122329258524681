import React, {useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {CircularProgress} from "@material-ui/core";
import {useMutation} from "react-query";
import {balanceShare} from "../../api";
import {useTranslation} from "react-i18next";
import Checkbox from '@material-ui/core/Checkbox'

const ShareBalanceModal = ({ open, member = null,
                               handleClose = () => { }, handleSuccess = (memberId, newBalance) => { } }) => {
    const [amount, setAmount] = useState(0)
    const [autoRefill, setAutoRefill] = useState(false)
    const [errorMessage, setError] = useState('')
    const mutation = useMutation(balanceShare)
    const { t } = useTranslation();

    const handleShare = async () => {
        try {
            if (!/^\d+(\.\d{1,2})?$/.test(amount.toString()) || parseFloat(amount.toString()) < 0) {
                setError(t("Transaction.Correct number"))
                return
            }

            const data = await mutation.mutateAsync({ amount, memberId: member.id, autoRefill })
            handleSuccess(member.id, data.balance_member)
            handleClose()
        } catch (error) {
            setError(t("Transaction.Correct number"))
        }
    }

    const handleAutoRefill = e => {
        setAutoRefill(e.target.checked)
    }

    useEffect(() => setError(''), [amount])

    return (
        <Dialog open={open} onClose={handleClose} className='modal'>
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{t("Modals.Top Up Balance")}</h2>
                </div>

                <TextField
                    error={Boolean(errorMessage)}
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    id="top_up_balance"
                    label={t("Modals.Amount")}
                    variant="outlined"
                    margin='normal'
                    helperText={errorMessage}
                />

                {member && member.role !== 'team_admin' &&
                    <label>
                        <Checkbox
                            disabled={false}
                            checked={autoRefill}
                            onChange={handleAutoRefill}
                            color="primary"
                        />
                        {t("Transaction.Auto refill balance")}
                    </label>
                }

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="outlined" color="primary" fullWidth onClick={handleClose}>
                            {t("Modals.Cancel")}
                        </Button>
                    </div>
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="contained" color="primary" fullWidth onClick={handleShare}>
                            {mutation.isLoading ? <CircularProgress size={24} color="inherit" /> : t("Modals.Pay")}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ShareBalanceModal
