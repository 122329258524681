import React, {Fragment, useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import {useHistory} from 'react-router-dom'
import Exit from '../../assets/img/exit.svg'
import EventEmitter from '../../Utils/EventEmitter'
import EditIcon from '../../assets/img-new/edit.svg'
import Delete from '../../assets/img-new/delete.svg'
import ImportFileModal from '../../components/modals/ImportFileModal'
import SaveListModal from '../../components/modals/SaveListModal'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import {getAttributes, removeLeads} from '../../api'
import PlusCircle from '../../assets/img/plus_circle.svg'
import LeadSaveModal from '../../components/modals/LeadSaveModal'
import {useTranslation} from "react-i18next";

const ListPageActions = ({source, list, selectedRows, loading}) => {
    const { t } = useTranslation();
    const {availableSources} = useAppState()
    const {setContextMenu} = useAppDispatch()
    const history = useHistory()
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [showImportModal, setShowImportModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteLeadsModal, setShowDeleteLeadsModal] = useState(false)
    const [showSaveLeadModal, setShowSaveLeadModal] =useState(false)
    const queryClient = useQueryClient()

    const attributesRequest = useQuery(
        ['attributes'],
        () => getAttributes(),
        {keepPreviousData: true}
    )

    const deleteLeadsMutation = useMutation(removeLeads)

    useEffect(() => {
        if (attributesRequest.isSuccess) setAvailableAttributes(attributesRequest.data.data)
    },[attributesRequest.isSuccess, attributesRequest.data])

    useEffect(() => {
        const items = [
            {
                label: t('Sources.Quick Insert'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('LeadQuickInsert'),
                disabled: loading
            },
            {
                label: t('Sources.Add Lead'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('LeadCreate'),
                disabled: loading
            },
            {
                label: t('Sources.Import File'),
                icon: Exit,
                action: () => EventEmitter.emit('ImportFile'),
                disabled: loading
            },
            {
                label: t('Sources.Edit List'),
                icon: EditIcon,
                action: () => EventEmitter.emit('ListEdit'),
                disabled: loading
            },
            {
                label: t('Sources.Delete Selection'),
                icon: Delete,
                action: () => EventEmitter.emit('LeadsBulkDeletion'),
                disabled: !selectedRows.length
            },
            // {
            //     label: t('Sources.Remove list'),
            //     icon: Delete,
            //     action: () => EventEmitter.emit('ListDelete'),
            //     disabled: loading
            // }
        ]
        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows, t])

    //listener: import file
    useEffect(() => {
        const onEvent = () => setShowImportModal(true)

        const listener = EventEmitter.addListener('ImportFile', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: list edit
    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('ListEdit', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: list delete
    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('ListDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: leads bulk delete
    useEffect(() => {
        const onEvent = () => setShowDeleteLeadsModal(true)

        const listener = EventEmitter.addListener('LeadsBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveLeadModal(true)

        const listener = EventEmitter.addListener('LeadCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    const deleteList = () => {
        setShowDeleteModal(false)
        DataService.deleteList(list.id)
            .then(response => {
                history.push(`/leads/sources/${source.id}`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const deleteItems = async () => {
        setShowDeleteLeadsModal(false)
        await deleteLeadsMutation.mutateAsync(selectedRows)
        queryClient.invalidateQueries('listLeads').then()
    }

    return (
        <Fragment>
            <ImportFileModal
                source={source}
                list={list}
                open={showImportModal}
                handleClose={() => setShowImportModal(false)}
            />
            <SaveListModal
                open={showSaveModal}
                handleClose={(e) => setShowSaveModal(false)}
                list={list}/>
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteList}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the list'}
            />
            <DeleteModal
                open={showDeleteLeadsModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteLeadsModal(false)}
                instance={'selected leads'}
            />
            {
                list && source && (
                    <LeadSaveModal
                        defaultValues={{
                            list_id: list?.id,
                            source_id: source?.id
                        }}
                        open={showSaveLeadModal}
                        handleClose={() => setShowSaveLeadModal(false)}
                        sources={availableSources}
                        attributes={availableAttributes}
                        attributesIsLoading={attributesRequest.isLoading}
                    />
                )
            }

        </Fragment>
    )
}

export default ListPageActions
