import React, {useEffect} from 'react'
import {useAppDispatch} from '../Contexts/AppContext'
import {useTranslation} from "react-i18next";

const Analytics = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs, setSection, setContextMenu} = useAppDispatch()

    useEffect(() => {
        setBreadcrumbs([{label: 'Analytics'}])
        setSection('analytics')
        setContextMenu([])
    }, [setBreadcrumbs, setSection, setContextMenu])

    return (
        <div className="pageHolder">
            {t("Analytics.Analytics, coming soon")}
        </div>
    )
}

export default Analytics
