import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {balanceTransactions, balanceTransactionsTotal} from "../../api";
import {useTranslation} from "react-i18next";
import ListTable from "../../components/ListTable/ListTable";
import {useAppDispatch} from "../../Contexts/AppContext";
import UserBalance from "../../components/balance/UserBalance";

const Transactions = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="contentPartTitleHolder">
        <div className="contentPartTitle">
          {t("New Profile.SubscriptionPage.Billing History")}
        </div>
      </div>

      <TransactionList />
    </>
  );
};

const TransactionList = () => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [pagination, setPagination] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const { setBreadcrumbs, setContextMenu } = useAppDispatch();

  const { isSuccess, data, isLoading, isFetching } = useQuery(
    ["transactions", page, limit, sorting],
    () => balanceTransactions(page, limit, sorting),
    { keepPreviousData: true }
  );

  const queryTotal = useQuery(
    ["transactions.total"],
    () => balanceTransactionsTotal(),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setBreadcrumbs([
      { label: t("New Profile.SubscriptionPage.Billing History") },
    ]);
    setContextMenu([]);
  }, [setBreadcrumbs, setContextMenu, t]);

  useEffect(() => {
    isSuccess ? setTransactions(data.data) : setTransactions([]);
  }, [isSuccess, data, sorting]);

  useEffect(() => {
    if (queryTotal.isSuccess)
      setPagination({ ...queryTotal.data, current_page: page });
  }, [queryTotal.isSuccess, page, queryTotal.data]);

  const handleLimitChange = useCallback((limit) => {
    setPage(1);
    setLimit(limit);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        Header: t("New Profile.SubscriptionPage.Date"),
        accessor: "created_at",
        className: "transactionDate",
        Cell: ({value}) => value.replace(' ', ' - '),
      },
      {
        Header: t("New Profile.SubscriptionPage.Details"),
        accessor: "description",
        Cell: ({ value, row }) => {
          if (value === "top_up") {
            return t("Transaction.Top Up Balance");
          } else if (value === "sent_single_message") {
            return t("Transaction.Sent Single Message");
          } else if (value === "subscribe_payment") {
            return t("Transaction.Subscribe payment from balance");
          } else if (value === "share_balance") {
            return t("Transaction.Share balance", { name: row.original?.name });
          } else if (value === "auto_share_balance") {
            return t("Transaction.Auto share balance", {
              name: row.original?.name,
            });
          } else if (value === "sent_message") {
            return t("Transaction.Campaign Sent Message", {
              name: row.original?.name,
              count: row.original?.count,
            });
          }

          return "";
        },
      },
      {
        Header: t("New Profile.SubscriptionPage.Amount"),
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <div className={row.original.amount < 0 ? "transactionAmountNegative" : "transactionAmountPositive" }><UserBalance value={row.original.amount} /></div>;
        },
        className: "transactionAmount",
      },
    ];
  }, [t]);

  const transactionList = useMemo(() => {
    return transactions.map((t) => {
      return { ...t };
    });
  }, [transactions]);

  return (
    <ListTable
      columns={tableColumns}
      data={transactionList}
      loading={isLoading}
      fetching={isFetching}
      pagination={pagination}
      pageLimit={limit}
      onPageChange={setPage}
      onLimitChange={handleLimitChange}
      onSort={setSorting}
      disableSearchBlock={true}
    />
  );
};

export default Transactions;
