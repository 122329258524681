import React, {Fragment, useCallback, useEffect, useState} from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import TimeLineComponent from "./TimeLineComponent";
import Chip from "@material-ui/core/Chip";
import {useHistory, useParams} from "react-router-dom";
import Loader from "react-loader-spinner";
import {useAppDispatch} from "../../Contexts/AppContext";
import {useQuery, useQueryClient} from "react-query";
import {
    getCampaign,
    getLead,
    getLeadCampaigns,
    getLeadLogs,
    getLeadSegments,
    getLeadSources,
    getList,
    getSegment,
    getSource,
} from "../../api";
import {formatLeadAttributes, formatLeadLog, snakeToTitle, statusClassMap,} from "../../helpers";
import PageLoader from "../../components/loaders/PageLoader";
import LeadPageActions from "./LeadPageActions";
import LeadAttributeRow from "../../components/leads/LeadAttributeRow";
import {useTranslation} from "react-i18next";
import LeadAddAttributeButton from "../../components/leads/LeadAddAttributeButton";

const LeadPage = () => {
  const { t } = useTranslation();
  const { leadId, segmentId, sourceId, campaignId, listId } = useParams();
  const [lead, setLead] = useState(null);
  const [list, setList] = useState(null);
  const [source, setSource] = useState(null);
  const [segment, setSegment] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [leadAttributes, setLeadAttributes] = useState([]);
  const [logInfo, setLogInfo] = useState([]);
  const [segments, setSegments] = useState([]);
  const [sources, setSources] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const { setBreadcrumbs, setSection } = useAppDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  //logs pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    if (segmentId) {
      setSection("segments");
      if (segment) {
        setBreadcrumbs([
          { label: t("Sources.Audiences"), link: "/" },
          { label: segment.name, link: `/audiences/${segmentId}` },
          // {label: leadId}
        ]);
      } else {
        setBreadcrumbs([
          { label: t("Sources.Audiences"), link: "/" },
          {
            label: segmentId,
            link: `/audiences    /${segmentId}`,
            loading: true,
          },
          // {label: leadId}
        ]);
      }
    } else if (campaignId) {
      setSection("campaigns");
      if (campaigns) {
        setBreadcrumbs([
          { label: t("Sources.Campaigns"), link: "/" },
          { label: campaign.name, link: `/campaigns/${campaignId}` },
          // {label: leadId}
        ]);
      } else {
        setBreadcrumbs([
          { label: t("Sources.Campaigns"), link: "/" },
          {
            label: campaignId,
            link: `/campaigns    /${campaignId}`,
            loading: true,
          },
          // {label: leadId}
        ]);
      }
    } else if (listId) {
      setSection("sources");
      if (!list && !source) {
        setBreadcrumbs([
          { label: t("Sources.Sources"), link: "/leads/sources" },
          { label: sourceId, link: `/leads/sources/${sourceId}`, loading: true },
          {
            label: listId,
            link: `/leads/sources/${sourceId}/${listId}`,
            loading: true,
          },
          { label: leadId },
        ]);
      } else {
        setBreadcrumbs([
          { label: t("Sources.Sources"), link: "/leads/sources" },
          { label: source && source.name, link: `/leads/sources/${sourceId}` },
          { label: list && list.name, link: `/leads/sources/${sourceId}/${listId}` },
          // {label: leadId}
        ]);
      }
    } else {
      setSection("leads");
      setBreadcrumbs([
        { label: t("Sources.Leads"), link: "/leads" },
        // {label: leadId}
      ]);
    }
  }, [
    leadId,
    segmentId,
    campaignId,
    sourceId,
    listId,
    segment,
    campaign,
    campaigns,
    list,
    source,
    setBreadcrumbs,
    setSection,
    t,
  ]);

  // Lead
  const leadRequest = useQuery(["leadView", leadId], () => getLead(leadId), {
    keepPreviousData: true,
  });
  useEffect(() => {
    if (leadRequest.isSuccess) {
      const leadModel = leadRequest.data.data;
      setLead(leadModel);
      if (leadModel.lead_values) {
        setLeadAttributes(formatLeadAttributes(leadModel.lead_values));
      }
    }
  }, [leadRequest.isSuccess, leadRequest.data]);

  // Lead's audiences
  const leadSegmentsRequest = useQuery(
    ["leadSegments", leadId],
    () => getLeadSegments(leadId),
    { keepPreviousData: true }
  );
  useEffect(() => {
    if (leadSegmentsRequest.isSuccess)
      setSegments(leadSegmentsRequest.data.data);
  }, [leadSegmentsRequest.isSuccess, leadSegmentsRequest.data]);

  // Lead's campaigns
  const leadCampaignsRequest = useQuery(
    ["leadCampaigns", leadId],
    () => getLeadCampaigns(leadId),
    { keepPreviousData: true }
  );
  useEffect(() => {
    if (leadCampaignsRequest.isSuccess)
      setCampaigns(leadCampaignsRequest.data.data);
  }, [leadCampaignsRequest.isSuccess, leadCampaignsRequest.data]);

  // Lead's sources
  const leadSourcesRequest = useQuery(
    ["leadSources", leadId],
    () => getLeadSources(leadId),
    { keepPreviousData: true }
  );
  useEffect(() => {
    if (leadSourcesRequest.isSuccess) setSources(leadSourcesRequest.data.data);
  }, [leadSourcesRequest.isSuccess, leadSourcesRequest.data]);

  // Lead's sources
  const leadLogsRequest = useQuery(
    ["leadLogs", leadId, page, limit],
    () => getLeadLogs(leadId, page, limit),
    { keepPreviousData: true, enabled: !leadCampaignsRequest.isFetching }
  );

  useEffect(() => {
    if (leadLogsRequest.isSuccess) {
      setLogInfo(formatLeadLog(leadLogsRequest.data.data));
      setPagination(leadLogsRequest.data.meta);
    }
  }, [leadLogsRequest.isSuccess, leadLogsRequest.data]);

  // Segment
  const segmentRequest = useQuery(
    ["segmentView", segmentId],
    () => getSegment(segmentId),
    { keepPreviousData: true, enabled: !!segmentId }
  );
  useEffect(() => {
    if (segmentRequest.isSuccess) setSegment(segmentRequest.data.data);
  }, [segmentRequest.isSuccess, segmentRequest.data]);

  // Campaign
  const campaignRequest = useQuery(
    ["campaignView", campaignId],
    () => getCampaign(campaignId),
    { keepPreviousData: true, enabled: !!campaignId }
  );
  useEffect(() => {
    if (campaignRequest.isSuccess) setCampaign(campaignRequest.data.data);
  }, [campaignRequest.isSuccess, campaignRequest.data]);

  // Source
  const sourceRequest = useQuery(
    ["sourceView", sourceId],
    () => getSource(sourceId),
    { keepPreviousData: true, enabled: !!sourceId }
  );
  useEffect(() => {
    if (sourceRequest.isSuccess) setSource(sourceRequest.data.data);
  }, [sourceRequest.isSuccess, sourceRequest.data]);

  // List
  const listRequest = useQuery(["listView", listId], () => getList(listId), {
    keepPreviousData: true,
    enabled: !!listId,
  });
  useEffect(() => {
    if (listRequest.isSuccess) setList(listRequest.data.data);
  }, [listRequest.isSuccess, listRequest.data]);

  const renderLeadAttributes = () => {
    return (
      <Fragment>
        {leadAttributes.map((attr, index) => (
          <LeadAttributeRow
            onSuccessUpdate={(updatedAttribute) => {
              setLeadAttributes((prevAttrs) => {
                const newAttrs = [...prevAttrs];
                const updated = newAttrs.find(
                  (attr) => attr.id === updatedAttribute.id
                );
                if (updated) {
                  updated.value = updatedAttribute.value;
                }
                return newAttrs;
              });
              queryClient.invalidateQueries("leadView").then();
              queryClient.invalidateQueries("leadLogs").then();
            }}
            key={index}
            attr={attr}
          />
        ))}
      </Fragment>
    );
  };

  const renderLeadSegments = () => {
    if (leadSegmentsRequest.isLoading) {
      return (
        <div className="tr">
          <div className="td">{t("Sources.Audiences")}</div>
          <div className="td">
            <div>
              <Loader type="ThreeDots" color="#0576ED" height={30} width={30} />
            </div>
          </div>
        </div>
      );
    }

    if (!segments) return null;

    const items = segments.filter((item) => !item.parent_segment_id);

    if (items.length === 0) return null;

    return (
      <div className="tr">
        <div className="td">{t("Sources.Audiences")}</div>
        <div className="td">
          {items.map((segment) => {
            return (
              <Chip
                key={segment.id}
                label={segment.name}
                onClick={() => history.push(`/audiences/${segment.id}`)}
                className="slChip"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderLeadSubsegments = () => {
    if (leadSegmentsRequest.isLoading) {
      return (
        <div className="tr">
          <div className="td">{t("Sources.Segments")}</div>
          <div className="td">
            <div>
              <Loader type="ThreeDots" color="#0576ED" height={30} width={30} />
            </div>
          </div>
        </div>
      );
    }
    if (!segments) return null;

    const items = segments.filter((item) => !!item.parent_segment_id);

    if (items.length === 0) return null;

    return (
      <div className="tr">
        <div className="td">{t("Sources.Segments")}</div>
        <div className="td">
          {items.map((segment) => {
            return (
              <Chip
                key={segment.id}
                label={segment.name}
                onClick={() =>
                  history.push(
                    `/audiences/${segment.parent_segment_id}/segments/${segment.id}`
                  )
                }
                className="slChip"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderLeadCampaigns = () => {
    if (leadCampaignsRequest.isLoading) {
      return (
        <div className="tr">
          <div className="td">{t("Sources.Campaigns")}</div>
          <div className="td">
            <div>
              <Loader type="ThreeDots" color="#0576ED" height={30} width={30} />
            </div>
          </div>
        </div>
      );
    }
    if (!campaigns || campaigns.length === 0) return null;
    return (
      <div className="tr">
        <div className="td">{t("Sources.Campaigns")}</div>
        <div className="td">
          {campaigns.map((campaign) => {
            return (
              <Chip
                key={campaign.id}
                label={campaign.name}
                onClick={() => history.push(`/campaigns/${campaign.id}`)}
                className="slChip"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderLeadSources = () => {
    if (leadSourcesRequest.isLoading) {
      return (
        <div className="tr">
          <div className="td">{t("Sources.Sources")}</div>
          <div className="td">
            <div>
              <Loader type="ThreeDots" color="#0576ED" height={30} width={30} />
            </div>
          </div>
        </div>
      );
    }
    if (!sources || sources.length === 0) return null;

    return (
      <div className="tr">
        <div className="td">{t("Sources.Sources")}</div>
        <div className="td">
          {sources.map((source) => {
            return (
              <Chip
                key={source.id}
                label={source.name}
                onClick={() => history.push(`/leads/sources/${source.id}`)}
                className="slChip"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderLeadLists = () => {
    if (leadSourcesRequest.isLoading) {
      return (
        <div className="tr">
          <div className="td">{t("Sources.Sources")}</div>
          <div className="td">
            <div>
              <Loader type="ThreeDots" color="#0576ED" height={30} width={30} />
            </div>
          </div>
        </div>
      );
    }
    if (!sources || sources.length === 0) return null;
    let lists = [];

    sources.forEach((source) => {
      if (source.lists) {
        source.lists.forEach((list) => {
          const listPresent = lists.find((item) => item.id === list.id);
          if (!listPresent) lists.push(list);
        });
      }
    });

    return (
      <div className="tr">
        <div className="td">{t("Sources.Lists")}</div>
        <div className="td">
          {lists.map((list) => {
            return (
              <Chip
                key={list?.processing_document_list?.id}
                label={list?.processing_document_list?.name}
                onClick={() =>
                  history.push(
                    `/leads/sources/${list.source_id}/${list?.processing_document_list?.id}`
                  )
                }
                className="slChip"
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderLeadTags = () => {
    if (lead && lead.status) {
      const statusMap = statusClassMap();

      const statusClass = `tagLikeChip ${statusMap[lead.status]} mb0`;
      return (
        <Chip
          label={snakeToTitle(lead.status)}
          color="primary"
          className={statusClass}
        />
      );
    }
    return null;
  };

  const redirectToList = () => {
    let redirectToUrl = "/leads";

    if (segmentId) redirectToUrl = `/audiences/${segmentId}`;
    else if (campaignId) redirectToUrl = `/campaigns/${campaignId}/audience`;
    else if (listId && sourceId) {
      redirectToUrl = `/leads/sources/${sourceId}/${listId}`;
    }

    history.replace(redirectToUrl);
  };

  const handleLimitChange = useCallback(
    (l) => {
      setPage(1);
      setLimit(l);
    },
    [setPage, setLimit]
  );

  return (
    <div className="pageHolder">
      <div className="leadPageWrapper">
        <div className="leadDetailsHolder">
          <div className="leadDetails">
            <div className="ldTitleHolder">
              <div className="TitleActiveWrapper"><h2>Lead Details</h2> {renderLeadTags()}</div>
              <div className="btnHolder">
                <LeadAddAttributeButton
                  leadId={leadId}
                  leadAttributes={leadAttributes}
                />
                <Button
                  disabled={leadLogsRequest.isFetching}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  Edit Inputs
                </Button>
              </div>
            </div>
            <div className="leadDetailsTableLike">
              {leadRequest.isLoading ? (
                <PageLoader />
              ) : (
                <div className="tableLike">
                  <div className="tr">
                    <div className="td">{t("Sources.Lead ID")}</div>
                    <div className="td">
                      <div className="editTextOnClick">
                        <div className="captionPart">{lead?.id}</div>
                      </div>
                    </div>
                  </div>
                  {renderLeadAttributes()}
                  {renderLeadSegments()}
                  {renderLeadSubsegments()}
                  {renderLeadSources()}
                  {renderLeadLists()}
                  {renderLeadCampaigns()}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="timeLineHolder">
          <div className="timeLine">
            <div className="ldTitleHolder">
              <h2>{t("Sources.Timeline")}</h2>
              <div className="btnHolder">
                <Button
                  disabled={leadLogsRequest.isFetching}
                  variant="contained"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  size="small"
                  onClick={(e) => {
                    setPage(1);
                    queryClient.invalidateQueries("leadLogs").then();
                  }}
                >
                  {t("Sources.Refresh")}
                </Button>
              </div>
            </div>
            {leadLogsRequest.isLoading ? (
              <PageLoader />
            ) : (
              <TimeLineComponent
                logInfo={logInfo}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
              />
            )}
          </div>
        </div>
      </div>
      <LeadPageActions
        lead={lead}
        loading={leadRequest.isLoading}
        onDelete={redirectToList}
      />
    </div>
  );
};

export default LeadPage;
