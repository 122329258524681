import React, {Fragment, useEffect, useRef} from 'react'
import {useForm} from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {useMutation} from 'react-query'
import {testCampaign} from '../../api';
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const CampaignTestModal = props => {
    const { t } = useTranslation();
    const {
        campaign,
        open,
        handleClose,
    } = props

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onTouched',
    })


    const phoneRef = useRef()

    const mutation = useMutation(testCampaign)

    useEffect(() => {
        if (mutation.error) {
            AlertToastr.showErrorResponseAlert(mutation.error)
        }
    }, [mutation.error])


    const onSubmit = handleSubmit(async data => {
        try {
            const response = await mutation.mutateAsync({
                id: campaign?.id,
                ...data
            })
            AlertToastr.showAlert(response.data.message)
            reset()
            handleClose()
        } catch (e) {
            console.log(e)
        }

    })

    const title = t('Modals.Test Campaign')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                onEntered={() => {
                    phoneRef.current.focus()
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                phoneRef.current = e
                            }}
                            name="phone"
                            label={t("Modals.Phone Number")}
                            variant="outlined"
                            margin='normal'
                        />
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={mutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={mutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {t('Modals.Send')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default CampaignTestModal
