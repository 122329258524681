import React, {useState} from 'react';
import {useMutation} from 'react-query';
import {subscriptionTest} from '../../api';
import {Button} from '@material-ui/core';
import AlertToastr from '../../Utils/alert';
import {useTranslation} from "react-i18next";


const UserActionsTest = () => {
    const { t } = useTranslation();
    const actions = [
        {'key': 'send-sms', 'label': t('Profile.Send 10 SMS')},
        {'key': 'import-contacts', 'label': t('Profile.Import 10 contacts')}
    ]
    const [activeActions, setActiveActions] = useState({})
    const mutation = useMutation(subscriptionTest)

    const action = (key) => async () => {
        setActiveActions((prev) => ({
            ...prev,
            [key]: true
        }))
        const response = await mutation.mutateAsync(key)

        setActiveActions(prev => {
            const newActions = {...prev};
            delete newActions[key]
            return newActions;
        })

        const {message, error} = response.data
        if (message) {
            AlertToastr.showAlert(message)
        }
        if (error) {
            AlertToastr.showErrorAlert(error)
        }
    }


    return (
        <div className="pageHolder">
            <ul style={{listStyle: 'none'}}>
                {
                    actions.map(a => (
                        <li key={a.key} style={{marginBottom: '5px'}}>
                            <Button
                                disabled={mutation.isLoading && activeActions[a.key]}
                                color="primary"
                                variant="contained"
                                onClick={action(a.key)}
                            >
                                {activeActions[a.key] ? t('Profile.Processing') : a.label}
                            </Button>
                        </li>
                    ))
                }

            </ul>


        </div>
    );
};

export default UserActionsTest;
