import React, {Fragment, useEffect, useRef} from 'react'
import {useForm} from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {useMutation, useQueryClient} from 'react-query'
import {storeShortUrlDomain, updateShortUrlDomain} from '../../api'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const SaveShortUrlDomainModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
    } = props

    const queryClient = useQueryClient()

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })


    const nameRef = useRef()

    const storeMutation = useMutation(storeShortUrlDomain)
    const updateMutation = useMutation(updateShortUrlDomain)

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error)
        }
    }, [storeMutation.error])

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error)
        }
    }, [updateMutation.error])

    const onSubmit = handleSubmit(async data => {
        if (defaultValues) {
            await updateMutation.mutateAsync({id: defaultValues.id, ...data})
            reset()
            handleClose()
            queryClient.invalidateQueries('shortUrlDomains').then()
        } else {
            try {
                await storeMutation.mutateAsync(data)
                reset()
                handleClose()
                queryClient.invalidateQueries('shortUrlDomains').then()
            } catch (e) {
                console.log(e)
            }
        }

    })

    const title = defaultValues ? t('Modals.Edit Domain') : t('Modals.Add Domain')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                onEntered={() => {
                    nameRef.current.focus()
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                nameRef.current = e
                            }}
                            name="url"
                            label={t("Modals.Domain Name")}
                            variant="outlined"
                            margin='normal'
                        />
                        <input type="hidden" ref={register} name='sort_order'/>
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading || updateMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading || updateMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {title}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveShortUrlDomainModal
