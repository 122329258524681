import React, {useMemo} from 'react'
import {useAuth} from "../Contexts/AuthProvider";

const LimitCounter = ({limitName = null}) => {
    const { subscriptions } = useAuth()

    const subsValues = useMemo(() => {
        let [usage, total, remaining] = [null, null, null]

        if(limitName && Array.isArray(subscriptions) && subscriptions.length > 0) {
            const foundName = subscriptions[0]
            if(foundName) {
                const foundLimit = foundName.features.find(item => item.hasOwnProperty('slug') && item.slug === limitName)
                if(foundLimit) {
                    remaining = foundLimit.feature_remaining
                    total = foundLimit.value
                    usage = foundLimit.feature_usage
                }
            }
        }

        return {total, usage, remaining}
    }, [subscriptions, limitName])

    return (
        <>
            {subsValues.total !== null && subsValues.remaining !== null &&
                <>{subsValues.remaining}/{subsValues.total}</>
            }
        </>
    )
}

export default LimitCounter

