import React, {Fragment, useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {Controller, useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useMutation, useQueryClient} from "react-query";
import {storeLookup, updateLookup} from "../../api";
import AlertToastr from "../../Utils/alert";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";

const LookupSaveModal = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { sources, open, handleClose, defaultValues, statusOptions } = props;

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const [availableLists, setAvailableLists] = useState([]);
  const sourceId = watch("source_id");

  useEffect(() => {
    setValue("list_id", "");
    if (!sourceId) return setAvailableLists([]);
    const sourceModel = sources.find((item) => item.id === sourceId);
    setAvailableLists(sourceModel ? sourceModel.lists : []);
  }, [sourceId, setValue, sources]);

  const storeMutation = useMutation(storeLookup);
  const updateMutation = useMutation(updateLookup);

  const onSubmit = handleSubmit(async (data) => {
    if (defaultValues) {
      try {
        await updateMutation.mutateAsync({
          id: defaultValues.id,
          ...data,
        });
        reset();
        handleClose();
        queryClient.invalidateQueries("lookupList").then();
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        )
          AlertToastr.showErrorAlert(error.response.data.errors);
      }
    } else {
      try {
        const defaultStatus = statusOptions[0];
        data.status = defaultStatus?.id;
        await storeMutation.mutateAsync(data);
        reset();
        handleClose();
        queryClient.invalidateQueries("lookupList").then();
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        )
          AlertToastr.showErrorAlert(error.response.data.errors);
      }
    }
  });

  const renderSourceField = () => {
    return (
      <Fragment>
        <Controller
          name={"source_id"}
          control={control}
          defaultValue=""
          render={({ onChange, value }) => {
            return (
              <TextField
                select
                onChange={onChange}
                value={value}
                variant="outlined"
                margin="normal"
                label="Source"
              >
                <MenuItem value="" disabled>
                  {t("Modals.Select Source")}
                </MenuItem>
                {sources.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
      </Fragment>
    );
  };

  const renderListField = () => {
    return (
      <Fragment>
        <Controller
          name="list_id"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => {
            return (
              <TextField
                select
                onChange={onChange}
                value={value}
                variant="outlined"
                margin="normal"
                label={t("Modals.List")}
              >
                <MenuItem value="" disabled>
                  {t("Modals.Select List")}
                </MenuItem>
                {availableLists.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
      </Fragment>
    );
  };

  const renderStatusField = () => {
    return (
      <Fragment>
        <Controller
          name="status"
          control={control}
          defaultValue=""
          render={({ value, onChange }) => {
            return <input type="hidden" value={value} />;
          }}
        />
      </Fragment>
    );
  };

  const title = defaultValues
    ? t("Modals.Edit Lookup")
    : t("Modals.Add Lookup");
  const buttonTitle = defaultValues
    ? t("Modals.Save")
    : t("Modals.Add Lookup Btn");

  return (
    <Dialog open={open} onClose={handleClose} className="modal">
      <div className="modalBody">
        <div className="modalTitleHolder mb30">
          <h2 className="mb15">{title}</h2>
        </div>
        <form onSubmit={onSubmit}>
          {renderSourceField()}
          {renderListField()}
          {/*{renderUserGatewayField()}*/}
          {/*{renderTypeField()}*/}
          {renderStatusField()}
          <div className="btnHolder hasPadTop twoBtn">
            <div className="part">
              <Button
                disabled={storeMutation.isLoading || updateMutation.isLoading}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleClose}
              >
                {t("Modals.Cancel")}
              </Button>
            </div>
            <div className="part">
              <Button
                disabled={storeMutation.isLoading || updateMutation.isLoading}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                onClick={(e) => {}}
              >
                {buttonTitle}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default LookupSaveModal;
