import React, {Fragment, useEffect, useState} from 'react'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import Export from '../../assets/img/export.svg'
import DeleteIcon from '../../assets/img-new/delete.svg'
import EditIcon from '../../assets/img-new/edit.svg'
import CopyIcon from '../../assets/img/copy.svg'
import LeadsExportModal from '../../components/modals/LeadsExportModal'
import SaveSegmentModal from '../../components/modals/SaveSegmentModal'
import {useHistory} from 'react-router-dom'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const SegmentLeadsPageActions = props => {
    const { t } = useTranslation();
    const {
        segment,
        loading,
        visibleColumns,
        search,
        sorting,
        entityTitle = t('segment leads page actions.Audience')
    } = props
    const {exportFiles} = useAppState()
    const {setContextMenu, setContextLinks} = useAppDispatch()
    const history = useHistory()
    const [showExportModal, setShowExportModal] = useState(false)
    const [showSaveSegmentModal, setShowSaveSegmentModal] = useState(false)
    const [showUpdateSegmentModal, setShowUpdateSegmentModal] = useState(false)
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    useEffect(() => {
        if (!segment) return

        if (exportFiles && exportFiles[segment.id]) {
            const fileData = exportFiles[segment.id]
            const enabled = fileData.type === 'export-completed'
            setContextLinks([
                {
                    label: enabled ? t('segment leads page actions.Download') : t('segment leads page actions.Generating file'),
                    url: fileData.url,
                    disabled: !enabled
                }
            ])
        }
    }, [segment, exportFiles, setContextLinks, t])

    useEffect(() => {
        const items = [
            {
                label: t('segment leads page actions.Export as CSV'),
                icon: Export,
                action: () => EventEmitter.emit('SegmentExport'),
                disabled: loading
            },
            {
                label: t('segment leads page actions.Edit'),
                icon: EditIcon,
                action: () => EventEmitter.emit('SegmentEdit'),
                disabled: loading
            },
            {
                label: t('segment leads page actions.Duplicate'),
                icon: CopyIcon,
                action: () => EventEmitter.emit('SegmentDuplicate'),
                disabled: loading
            },
            {
                label: t('segment leads page actions.Delete'),
                icon: DeleteIcon,
                action: () => EventEmitter.emit('SegmentDelete'),
                disabled: loading
            }
        ]
        setContextMenu(items)
    }, [setContextMenu, loading, entityTitle, t])


    useEffect(() => {
        const onEvent = () => setShowExportModal(true)

        const listener = EventEmitter.addListener('SegmentExport', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowUpdateSegmentModal(true)

        const listener = EventEmitter.addListener('SegmentEdit', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDuplicateModal(true)

        const listener = EventEmitter.addListener('SegmentDuplicate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('SegmentDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveSegmentModal(true)

        const listener = EventEmitter.addListener('SegmentCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    const deleteSegment = () => {
        setShowDeleteModal(false)
        DataService.deleteSegment(segment.id)
            .then(response => {
                history.push(`/`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }


    return (
        <Fragment>
            <LeadsExportModal
                search={search}
                sorting={sorting}
                visibleColumns={visibleColumns}
                open={showExportModal}
                handleClose={() => setShowExportModal(false)}
            />
            <SaveSegmentModal
                open={showSaveSegmentModal}
                handleClose={() => setShowSaveSegmentModal(false)}
                parentSegment={segment}
                isDuplicateSegmentMode={false}
                entityTitle={t('segment leads page actions.Edit')}
            />
            <SaveSegmentModal
                open={showUpdateSegmentModal}
                handleClose={() => setShowUpdateSegmentModal(false)}
                segment={segment}
                isDuplicateSegmentMode={false}
                instance={entityTitle}
                entityTitle={t('segment leads page actions.Edit')}
            />
            <SaveSegmentModal
                open={showDuplicateModal}
                handleClose={() => setShowDuplicateModal(false)}
                segment={segment}
                isDuplicateSegmentMode={true}
                onDuplicated={newSegment => {
                    setShowDuplicateModal(false)
                    history.push(`/audiences/${newSegment.id}`)
                }}
                entityTitle={t('segment leads page actions.Duplicate')}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteSegment}
                handleClose={(e) => setShowDeleteModal(false)}
                instance={entityTitle}
                entityTitle={entityTitle}
            />
        </Fragment>
    )
}

export default SegmentLeadsPageActions
