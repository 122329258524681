import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useApiCall from '../../Hooks/useApiCall'
import authEndpoints from '../../Endpoints/auth.endpoints'
import {useHistory} from 'react-router-dom';
import {useAuth} from '../../Contexts/AuthProvider'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const ForgotPasswordPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [code, setCode] = useState('')
    const [resetRequest, passwordReset] = useApiCall()
    const [sendPasswordRequest, sendPassword] = useApiCall()
    const [token, setToken] = useState(null)
    const { isLoggedIn } = useAuth()
    const history = useHistory();

    const sendEmail = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true)
        // if (email) {
            passwordReset({
                ...authEndpoints.resetPassword(),
                data: {
                    email: email
                }
            })
        // }
    }

    const sendCode = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true)
        if (code && token) {
            sendPassword({
                ...authEndpoints.sendPassword(),
                data: {
                    token: token,
                    code: code
                }
            })
        }

    }

    useEffect(() => {
        setIsButtonDisabled(false)
        if (resetRequest.error) {
            AlertToastr.showErrorAlert(resetRequest.error.message)
        }
    }, [resetRequest.error])

    useEffect(() => {
        setIsButtonDisabled(false)
        if (!resetRequest.response || !resetRequest.response.data || !resetRequest.response.data.token)
            return
        setToken(resetRequest.response.data.token)

    }, [resetRequest.response])

    useEffect(() => {
        setIsButtonDisabled(false)
        if (sendPasswordRequest.error) {
            AlertToastr.showErrorAlert(sendPasswordRequest.error.message)
        }
    }, [sendPasswordRequest.error])

    useEffect(() => {
        setIsButtonDisabled(false)
        if (!sendPasswordRequest.response || !sendPasswordRequest.response.is_completed)
            return
        AlertToastr.showAlert(t('Auth.New password successfully sent on your email'))
        history.push(`/signin`)

    }, [sendPasswordRequest.response, history, t])

    if (isLoggedIn) {
        return <Redirect to='/' />
    }

    if (!token)
        return (
            <div className='formHolder'>
                {/*<p className='mb20'>Forgot your password?</p>*/}
                <p className='mb20'>{t("Auth.Please enter your email address")}</p>
                <form noValidate autoComplete="off" >
                    <TextField
                        id="email"
                        label={t("Auth.Email")}
                        variant="outlined"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        margin='normal'
                    />
                    <div className="btnHolder mt20 mb15">
                        <Button
                            disabled={!!isButtonDisabled}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={sendEmail}
                        >
                            {t("Auth.Send")}
                        </Button>
                    </div>
                    <div className="linkHolder">
                        <a href="/signin" className="">{t("Auth.Back to login")}</a>
                    </div>
                </form>
            </div>
        )
    else
        return (
            <div className='formHolder'>
                <p className='mb20'>{t("Auth.Confirmation code")}</p>
                <form noValidate autoComplete="off">
                    <TextField
                        id="code"
                        label={t("Auth.Code")}
                        variant="outlined"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        margin='normal'
                    />
                    <div className="btnHolder mt20 mb15">
                        <Button
                            disabled={!!isButtonDisabled}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={sendCode}
                        >
                            {t("Auth.Send")}
                        </Button>
                    </div>
                </form>
            </div>
        )
}

export default ForgotPasswordPage
