import React, {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {storeCampaignSegments, storeCampaignSources} from '../api'
import AlertToastr from '../Utils/alert'
import {useCampaignState} from '../Contexts/CampaignContext'
import {useParams} from 'react-router-dom'
// import FilterRow from './FilterRow'
import FilterSegmentRow from './FilterSegmentRow'
import {useTranslation} from "react-i18next";


const SyncCampaignResourcesBlock = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient()
    const {campaignId} = useParams()
    const {
        segments,
        // sources,
        campaignSegmentsRequest,
        // campaignSourcesRequest,
        availableSegments,
        // availableSources,
    } = useCampaignState()

    const storeCampaignSegmentsMutation = useMutation(storeCampaignSegments)
    const storeCampaignSourcesMutation = useMutation(storeCampaignSources)

    useEffect(() => {
        if (storeCampaignSegmentsMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignSegmentsMutation.error)
        }
    }, [storeCampaignSegmentsMutation.error])

    useEffect(() => {
        if (storeCampaignSourcesMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignSourcesMutation.error)
        }
    }, [storeCampaignSourcesMutation.error])

    const handleSegmentsSync = async (items) => {
        if (!campaignId) return

        await storeCampaignSegmentsMutation.mutateAsync({
            id: campaignId,
            ids: items.map(i => i.id)
        })
        queryClient.invalidateQueries('campaignSegmentsView').then()
        queryClient.invalidateQueries('campaignLeads').then()
    }

    // const handleSourcesSync = async (items) => {
    //     if (!campaignId) return
    //
    //     await storeCampaignSourcesMutation.mutateAsync({
    //         id: campaignId,
    //         ids: items.map(i => i.id)
    //     })
    //     queryClient.invalidateQueries('campaignSourcesView').then()
    //     queryClient.invalidateQueries('campaignLeads').then()
    // }

    return (
        <div className="segmentsFilterHolder">
            <div className="segmentsFilter">
                <FilterSegmentRow
                    loading={storeCampaignSegmentsMutation.isLoading || campaignSegmentsRequest.isLoading}
                    label={t("Sync Campaign Resources Block.Add AudienceSegment")}
                    items={availableSegments}
                    selectedItems={segments}
                    onChange={handleSegmentsSync}
                />
                {/*<FilterRow*/}
                {/*    loading={storeCampaignSourcesMutation.isLoading || campaignSourcesRequest.isLoading}*/}
                {/*    label="Add Source"*/}
                {/*    items={availableSources}*/}
                {/*    selectedItems={sources}*/}
                {/*    onChange={handleSourcesSync}*/}
                {/*/>*/}
            </div>
        </div>
    )
}

export default SyncCampaignResourcesBlock
