import React, {useMemo} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useTranslation} from "react-i18next";


const HCPie = ({campaignStat, currentFilter}) => {
    const { t } = useTranslation();
    const options = useMemo(() => {

        if (!campaignStat) return {}



        return {
            credits: {enabled: false},
            legend: {
                verticalAlign: 'bottom',
                alignColumns: false,
                itemStyle: {
                    fontWeight: '400',
                }
                // symbolWidth: 7,
                // symbolHeight: 28
            },
            chart: {
                backgroundColor: '#fbfbfb',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: 340
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{point.y}, <b>{point.percentage:.1f}%</b>'
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    // colors: ['#0576ED', '#4D94F4', '#7CB2F9', '#A9CFFC', '#D8EBFF'],
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                innerSize: '50%',
                name: t('Charts.Messages'),
                colorByPoint: true,
                data: [
                    {
                        color: '#86CB92',
                        name: t('Charts.Delivered'),
                        y: campaignStat?.amount_status_delivered,
                        sliced: currentFilter === 'amount_status_delivered',
                        selected: currentFilter === 'amount_status_delivered',
                    },
                    {
                        name: t('Charts.Blocked by carrier'),
                        color: '#E05263',
                        y: campaignStat?.amount_status_blocked,
                        sliced: currentFilter === 'amount_status_blocked',
                        selected: currentFilter === 'amount_status_blocked',
                    },
                    {
                        color: '#2E49D1',
                        name: t('Charts.Inactive device'),
                        y: campaignStat?.amount_status_inactive,
                        sliced: currentFilter === 'amount_status_inactive',
                        selected: currentFilter === 'amount_status_inactive',
                    },
                    {
                        color: '#D852E0',
                        name: t('Charts.Failed'),
                        y: campaignStat?.amount_status_error,
                        sliced: currentFilter === 'amount_status_error',
                        selected: currentFilter === 'amount_status_error',
                    },
                    {
                        color: '#FB8B24',
                        name: t('Charts.Sent'),
                        y: campaignStat?.amount_status_sent,
                        sliced: currentFilter === 'amount_status_sent',
                        selected: currentFilter === 'amount_status_sent',
                    },
                    {
                        color: '#E8EB1F',
                        name: t('Charts.Sending'),
                        y: campaignStat?.amount_status_sending,
                        sliced: currentFilter === 'amount_status_sending',
                        selected: currentFilter === 'amount_status_sending',
                    },
                    {
                        color: '#3598DB',
                        name: t('Queued'),
                        y: campaignStat?.amount_status_queued,
                        sliced: currentFilter === 'amount_status_queued',
                        selected: currentFilter === 'amount_status_queued',
                    },
                ]
            }]
        }
    }, [campaignStat, currentFilter, t])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default HCPie
