import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppState} from '../Contexts/AppContext';
import EventEmitter from '../Utils/EventEmitter';
import Delete from '../assets/img-new/delete.svg';
import Settings from '../assets/img/settings.svg';
import DeleteModal from '../components/modals/DeleteModal';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getAttributes, removeLeads, updateLeadsStatus} from '../api';
import AlertToastr from '../Utils/alert';
import {statusContextMenu} from '../helpers';
import LeadSaveModal from '../components/modals/LeadSaveModal';
import PlusCircle from '../assets/img/plus_circle.svg';
import AccountPlus from '../assets/img/account-plus-outline.svg';
import {useTranslation} from 'react-i18next';
import {useAuth} from '../Contexts/AuthProvider';

const LeadsListActions = ({ selectedRows, loading }) => {
    const { t } = useTranslation();
    const { availableSources } = useAppState();
    const { setContextMenu } = useAppDispatch();
    const [availableAttributes, setAvailableAttributes] = useState([]);
    const [showSaveLeadModal, setShowSaveLeadModal] = useState(false);
    const [showDeleteLeadsModal, setShowDeleteLeadsModal] = useState(false);
    const queryClient = useQueryClient();
    const { user } = useAuth();

    const attributesRequest = useQuery(
        ['attributes'],
        () => getAttributes(),
        { keepPreviousData: true },
    );

    const updateLeadsStatusMutation = useMutation(updateLeadsStatus);
    const deleteLeadsMutation = useMutation(removeLeads);

    useEffect(() => {
        if (attributesRequest.isSuccess) setAvailableAttributes(attributesRequest.data.data);
    }, [attributesRequest.isSuccess, attributesRequest.data]);

    useEffect(() => {
        if (updateLeadsStatusMutation.error) {
            AlertToastr.showErrorResponseAlert(updateLeadsStatusMutation.error);
        }
    }, [updateLeadsStatusMutation.error]);

    useEffect(() => {
        if (deleteLeadsMutation.error) {
            AlertToastr.showErrorResponseAlert(deleteLeadsMutation.error);
        }
    }, [deleteLeadsMutation.error]);

    useEffect(() => {
        const items = !user || user.isTeamOperator()
            ? []
            : [{
                    label: t('Leads List Actions.Add Lead'),
                    icon: AccountPlus,
                    action: () => EventEmitter.emit('LeadCreate'),
                },
                {
                    label: t('Leads List Actions.Delete Selection'),
                    icon: Delete,
                    disabled: !selectedRows.length,
                    action: () => EventEmitter.emit('LeadsBulkDeletion'),
                },
                {
                    label: updateLeadsStatusMutation.isLoading ? t('Leads List Actions.Updating') : t('Leads List Actions.Set status'),
                    icon: Settings,
                    show: false,
                    disabled: !selectedRows.length || updateLeadsStatusMutation.isLoading,
                    items: statusContextMenu(),
                }];
        setContextMenu(items);
    }, [
        user,
        setContextMenu,
        loading,
        selectedRows,
        updateLeadsStatusMutation.isLoading,
        t,
    ]);

    const updateItemsStatus = useCallback(async status => {
        const response = await updateLeadsStatusMutation.mutateAsync({ ids: selectedRows, status });
        const { info, errors } = response;
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message));
        }
        if (errors && errors.length) {
            errors.forEach(message => AlertToastr.showErrorAlert(message));
        }

        queryClient.invalidateQueries('leadsList').then();
    }, [queryClient, selectedRows, updateLeadsStatusMutation]);

    useEffect(() => {
        const onEvent = () => setShowSaveLeadModal(true);

        const listener = EventEmitter.addListener('LeadCreate', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    //listener: leads bulk delete
    useEffect(() => {
        const onEvent = () => setShowDeleteLeadsModal(true);

        const listener = EventEmitter.addListener('LeadsBulkDeletion', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status);

        const listener = EventEmitter.addListener('LeadsBulkChangeStatus', onEvent);
        return () => {
            listener.remove();
        };
    }, [updateItemsStatus]);

    const deleteItems = async () => {
        setShowDeleteLeadsModal(false);
        try {
            await deleteLeadsMutation.mutateAsync(selectedRows);
            queryClient.invalidateQueries('leadsList').then();
        } catch (e) {
            console.log(e)
        }

    };

    return (
        <Fragment>
            <LeadSaveModal
                open={showSaveLeadModal}
                handleClose={() => setShowSaveLeadModal(false)}
                sources={availableSources}
                attributes={availableAttributes}
                attributesIsLoading={attributesRequest.isLoading}
            />
            <DeleteModal
                loading={deleteLeadsMutation.isLoading}
                open={showDeleteLeadsModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteLeadsModal(false)}
                instance={'selected leads'}
            />
        </Fragment>
    );
};

export default LeadsListActions;
