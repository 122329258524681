import React, {useEffect, useMemo, useRef, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import SmsCounter from "./SmsCounter";
import {useMutation, useQuery} from "react-query";
import {getLeadsList, getSMSGateways, sendSingleSms} from "../../api";
import AlertToastr from "../../Utils/alert";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../form/ReactHookFormSelect";
import {Autocomplete} from "@material-ui/lab";
import {useAsyncDebounce} from "react-table";

import SelectSmsTemplateButton from '../SelectSmsTemplateButton';
import SaveSmsTemplateButton from '../SaveSmsTemplateButton';
import {useAuth} from "../../Contexts/AuthProvider";

const SingleSmsModal = ({ open, handleClose = () => { } }) => {
    const { t } = useTranslation()
    const [phone, setPhone] = useState("")
    const [foundLeads, setFoundLeads] = useState([])
    const [autoCompleteRequest, setAutoCompleteRequest] = useState(false)
    const [searchPhone, setSearchPhone] = useState('')
    const [url, setUrl] = useState("")
    const [messageCursorPosition, setMessageCursorPosition] = useState()
    const [userGateways, setUserGateways] = useState([])
    const [availableAlphaNames, setAvailableAlphaNames] = useState([])
    const [currentUserGatewayId, setCurrentUserGatewayId] = useState(0)
    const mutation = useMutation(sendSingleSms)
    const { control, watch, setValue } = useForm({ mode: 'onTouched' })
    const message = watch('message')
    const availableAttributes = [{ id: 'link', name: 'Link', slug: 'link' }]
    const { user } = useAuth()
    const messageRef = useRef(null);

    const activeUserGateways = useMemo(() => {
        return userGateways.filter(item => item.active && !item.allow_lookup);
    }, [userGateways])

    const userGatewaysRequest = useQuery(
        ['smsGateways'],
        () => getSMSGateways(),
        { keepPreviousData: true }
    )

    useEffect(() => {
        if (userGatewaysRequest.isSuccess) {
            const { data } = userGatewaysRequest.data
            setUserGateways(data)
        }
    }, [userGatewaysRequest.isSuccess, userGatewaysRequest.data])

    const onSearchChange = useAsyncDebounce(value => setSearchPhone(value), 500)

    const autoCompleteLeads = useQuery(
        ['leadsList', 1, 5, phone, []],
        () => getLeadsList(1, 5, phone, []),
        {
            keepPreviousData: true,
            enabled: autoCompleteRequest && Boolean(searchPhone),
            onSuccess: data => {
                setAutoCompleteRequest(false)
            }
        }
    )

    useEffect(() => {
        if(Boolean(searchPhone)) {
            setAutoCompleteRequest(true)
        }
    }, [searchPhone])

    useEffect(() => {
        if (autoCompleteLeads.isSuccess) {
            const { data } = autoCompleteLeads.data
            if(Boolean(searchPhone) && Array.isArray(data) && data.length > 0) {
                const leadsValues = data
                    .filter(leads => Array.isArray(leads.lead_values) && leads.lead_values.length > 0)
                    .map(lead => {
                        const lv = lead.lead_values
                        let [tel, name, val] = ['', '', '']
                        const searchPhone = phone.toLowerCase()
                        lv.forEach(itemLead => {
                            if(!Boolean(itemLead.value)) return;

                            if(itemLead.attribute && itemLead.attribute.hasOwnProperty('type') && itemLead.attribute.type == 'phone') {
                                tel = itemLead.value
                                return
                            }
                            if(itemLead.value.toLowerCase().includes(searchPhone) && itemLead.attribute && itemLead.attribute.hasOwnProperty('type') && itemLead.attribute.type == 'string') {
                                name = itemLead.attribute.name
                                val = itemLead.value
                            }

                            if(name == '') {
                                name = itemLead.attribute.name
                                val = itemLead.value
                            }
                        })

                        return {phone: tel, name, value: val}
                    })
                    .filter(lead => Boolean(lead.phone))

                setFoundLeads(leadsValues)
                return
            }
        }

        setFoundLeads([])
    }, [autoCompleteLeads.isSuccess, autoCompleteLeads.data, searchPhone])

    const watchCurrentUserGatewayId = watch('gateway_id') || currentUserGatewayId
    const watchAlphaNamed = watch('alpha_name')

    useEffect(() => {
        setCurrentUserGatewayId(watchCurrentUserGatewayId)
    }, [watchCurrentUserGatewayId])

    const currentUserGatewayModel = useMemo(() => {
        if (!activeUserGateways || !activeUserGateways.length || !Number.isInteger(currentUserGatewayId)) {
            setAvailableAlphaNames([])
            return null
        }

        const model = activeUserGateways.find(item => item.id === currentUserGatewayId)
        if (model && Array.isArray(model.from)) {
            setAvailableAlphaNames(model.from)
        } else {
            setAvailableAlphaNames([])
        }

        return model || null
    }, [activeUserGateways, currentUserGatewayId])

    const setCursorPosition = e => {
        setMessageCursorPosition(e.target.selectionStart)
    }

    const insertToken = token => {
        let value = watch('message')

        let caretPosition
        try {
            const messageInput = messageRef.current.children[1].children[0]
            caretPosition = messageInput.selectionDirection ==='backward' ? messageInput.selectionStart : messageInput.selectionEnd;
            if (caretPosition === undefined) {
                caretPosition = value.length
            }
        } catch (e) {
            caretPosition = messageCursorPosition === undefined ? value.length : messageCursorPosition
        }

        let textToInsert = `{{${token}}}`
        let textBeforeCursorPosition = value.substring(0, caretPosition)
        let textAfterCursorPosition = value.substring(caretPosition, value.length)
        let newValue = textBeforeCursorPosition + textToInsert + textAfterCursorPosition
        setValue('message', newValue, { shouldDirty: false })
        setMessageCursorPosition(undefined)
    }

    const messageHasLink = useMemo(() => {
        if (!message) return false;
        return message.includes('{{link}}')
    }, [message])

    const handleSentSms = async () => {
        try {
            let params = { phone, message }
            if (/^\d+$/.test(phone)) params = { ...params, phone: `+${phone}` }
            if (messageHasLink) params = { ...params, redirect_to: url }
            if (Boolean(watchCurrentUserGatewayId) && watchCurrentUserGatewayId > 0) params = { ...params, gateway_id: watchCurrentUserGatewayId }
            if (Boolean(watchAlphaNamed) && watchAlphaNamed.length > 0) params = { ...params, from: watchAlphaNamed }

            const data = await mutation.mutateAsync(params)
            AlertToastr.showSuccessResponseAlert(data.data)
            handleClose()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (message)
            setMessageCursorPosition(message.length)
    }, [message, setMessageCursorPosition])

    useEffect(() => {
        if (mutation.error) {
            AlertToastr.showErrorResponseAlert(mutation.error)
        }
    }, [mutation.error])

    return (
        <Dialog open={open} onClose={handleClose} className='modal'>
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{t("Auth.Send SMS")}</h2>
                </div>

                {/*<TextField*/}
                {/*    value={phone}*/}
                {/*    onChange={e => setPhone(e.target.value)}*/}
                {/*    id="ssms_phone"*/}
                {/*    label={t("New Profile.ProfilePage.Phone")}*/}
                {/*    variant="outlined"*/}
                {/*    margin='normal'*/}
                {/*    placeholder="+380501234567"*/}
                {/*/>*/}

                <Autocomplete
                    id="ssms_phone"
                    freeSolo
                    // className="SingleSmsModalAutocomplete"
                    options={foundLeads}
                    getOptionLabel={option => option.phone}
                    filterOptions={x => x}
                    renderOption={(option, state) => {
                        return <li>{option.phone}   <span className="autocompleteList">{option.value}</span></li>
                    }}
                    onInputChange={(event, newInputValue) => {
                        if(!Boolean(newInputValue)) {
                            setPhone('')
                            onSearchChange('')
                        }
                    }}
                    renderInput={params => {
                        return <TextField {...params}
                                  value={phone}
                                  variant="outlined"
                                  margin='normal'
                                  style={{padding: 0}}
                                  className="SingleSmsModalAutocomplete"
                                  placeholder="+380501234567"
                                  onChange={e => {
                                      setPhone(e.target.value)
                                      onSearchChange(e.target.value)
                                  }}
                        />
                    }}
                />

                <ReactHookFormSelect
                    disabled={userGatewaysRequest.isLoading}
                    variant="outlined"
                    margin="normal"
                    id="gateway-select"
                    name="gateway_id"
                    label={t("Modals.Gateway")}
                    control={control}
                    defaultValue={activeUserGateways.length > 0 ? activeUserGateways[0].id : null}
                >
                    {activeUserGateways.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>

                {activeUserGateways.length > 0 && currentUserGatewayModel && Array.isArray(availableAlphaNames) && availableAlphaNames.length > 0 &&
                    <ReactHookFormSelect
                        key={`alpha_name_sms_${availableAlphaNames.length}`}
                        variant="outlined"
                        margin="normal"
                        id="alpha_name"
                        name="alpha_name"
                        label={t("Modals.Alpha Name")}
                        control={control}
                        defaultValue={availableAlphaNames.length > 0 ? availableAlphaNames[0].key : ''}
                    >
                        {availableAlphaNames.map((option) => {
                            return (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            )
                        })}
                    </ReactHookFormSelect>
                }

                <Controller
                    name="message"
                    control={control}
                    defaultValue=''
                    render={({ onChange, onBlur, value }) => (
                        <div style={{
                            position: 'relative'
                        }}>
                            <TextField
                                ref={messageRef}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                label={t("Modals.Message")}
                                multiline
                                rows={4}
                                variant="outlined"
                                margin='normal'
                                onClick={setCursorPosition}
                            />

                            <SmsCounter text={value} />
                            <div style={{
                                opacity: .5,
                                position: 'absolute',
                                bottom: 25,
                                right: 0,
                                display: 'flex',
                                gap: '5px',
                                marginRight: '5px'
                            }}>
                                <SelectSmsTemplateButton
                                    onSelect={(message) => setValue('message', message)}
                                />

                                {user && !user.isTeamOperator() &&
                                    <SaveSmsTemplateButton
                                        message={message}
                                        disabled={!message || message.length < 5}
                                    />
                                }
                            </div>

                        </div>
                    )}
                />

                {availableAttributes.map((attr) => {
                    return (
                        <Chip
                            key={attr.id}
                            label={attr.name}
                            onClick={() => insertToken(attr.slug || attr.name)}
                            className='tagLikeChip'
                        />
                    )
                })}

                {messageHasLink && (
                    <TextField
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        name="redirect_to"
                        label={t("Modals.Redirect Link")}
                        variant="outlined"
                        margin='normal'
                    />
                )}

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="outlined" color="primary" fullWidth onClick={handleClose}>
                            {t("Modals.Cancel")}
                        </Button>
                    </div>
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="contained" color="primary" fullWidth onClick={handleSentSms}>
                            {mutation.isLoading ? <CircularProgress size={24} color="inherit" /> : t("Auth.Send")}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default SingleSmsModal
