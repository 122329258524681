import React, {useMemo} from "react";
import InlineLoader from "./loaders/InlineLoader";
import {useTranslation} from "react-i18next";

const CampaignStatisticsHeader = ({ loading, onFilter, campaignStat }) => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    return [
      {
        label: t("Campaign Statistics Header.Clicked"),
        count: campaignStat?.amount_eng,
        action: () => onFilter("amount_eng"),
      },
      {
        label: t("Campaign Statistics Header.Clicked last 90 days"),
        count: campaignStat?.amount_eng_last,
        action: () => onFilter("amount_eng_last"),
      },
      {
        label: t("Campaign Statistics Header.Unsubscribe"),
        count: campaignStat?.amount_unsubscribe,
        action: () => onFilter("amount_unsubscribe"),
      },
      {
        label: t("Campaign Statistics Header.Non-clicked"),
        count: campaignStat?.amount_not_eng,
        action: () => onFilter("amount_not_eng"),
      },
    ];
  }, [campaignStat, onFilter, t]);

  return (
    <>
      <div className="pageHeadingBlockWrapper">
        {items.map((item, i) => {
          return (
            <div className="part" key={item.label}>
              <div className="partContent cursor-pointer" onClick={item.action}>
                <div className="mb10">{item.label}</div>
                <h2>{loading ? <InlineLoader /> : item.count}</h2>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CampaignStatisticsHeader;
