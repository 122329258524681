import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useAppDispatch} from '../../Contexts/AppContext'
import DeleteIcon from '../../assets/img-new/delete.svg'
import EventEmitter from '../../Utils/EventEmitter'
import {useMutation, useQueryClient} from 'react-query'
import {removeLeads, updateLeadsStatus} from '../../api'
import DeleteModal from '../../components/modals/DeleteModal'
import Settings from '../../assets/img/settings.svg'
import {statusContextMenu} from '../../helpers'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const LeadPageActions = ({lead, loading, onDelete}) => {
    const { t } = useTranslation();
    const {setContextMenu} = useAppDispatch()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const deleteLeadsMutation = useMutation(removeLeads)
    const updateLeadsStatusMutation = useMutation(updateLeadsStatus)
    const queryClient = useQueryClient()

    useEffect(() => {
        setContextMenu([
            {
                label: t('Sources.Remove Lead'),
                icon: DeleteIcon,
                action: () => EventEmitter.emit('LeadDelete'),
                disabled: loading
            },
            {
                label: updateLeadsStatusMutation.isLoading ? t('Sources.Updating') : t('Sources.Set status'),
                icon: Settings,
                disabled: updateLeadsStatusMutation.isLoading || !lead || loading,
                items: statusContextMenu().filter(item => lead &&  item.status !== lead.status)
            }
        ])
    }, [setContextMenu, loading, updateLeadsStatusMutation.isLoading, lead, t])

    const updateItemsStatus = useCallback(async status => {
        if (!lead) return
        const response = await updateLeadsStatusMutation.mutateAsync({ids: [lead.id], status})
        const {info, errors} = response
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message))
        }
        if (errors && errors.length) {
            errors.forEach(message => AlertToastr.showErrorAlert(message))
        }
        queryClient.invalidateQueries('leadView').then()
    }, [queryClient, updateLeadsStatusMutation, lead])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('LeadDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status)

        const listener = EventEmitter.addListener('LeadsBulkChangeStatus', onEvent)
        return () => { listener.remove() }
    }, [updateItemsStatus])

    const deleteItems = async () => {
        setShowDeleteModal(false)
        if (lead) {
            await deleteLeadsMutation.mutateAsync([lead.id])
            queryClient.invalidateQueries('leadsList').then(() => {
                if (onDelete) onDelete()
            })
        }

    }

    return (
        <Fragment>
            <DeleteModal
                loading={deleteLeadsMutation.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the lead'}
            />
        </Fragment>
    )
}

export default LeadPageActions
