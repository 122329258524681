import React from 'react'
import Route from './route'
import {Redirect, useLocation} from 'react-router-dom'
import {useAuth} from '../../Contexts/AuthProvider'

const PrivateRoute = ({ ...rest }) => {
    const location = useLocation()
    const { isLoggedIn } = useAuth()

    return isLoggedIn
        ? location.pathname === '/' ? <Redirect to={{ pathname: '/audiences'}} /> : <Route {...rest} />
        : <Redirect to={{ pathname: '/signin', state: { referer: rest.location } }} />
}

export default PrivateRoute
