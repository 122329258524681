import React, {Fragment, useEffect, useState} from 'react'
import SegmentLeads from '../../components/SegmentLeads'
import {useAppDispatch} from '../../Contexts/AppContext'
import SegmentLeadsPageActions from './SegmentLeadsPageActions'
import {useSegmentState} from '../../Contexts/SegmentContext'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import AudienceHeader from '../../components/AudienceHeader'
import SegmentFilter from '../../components/SegmentFilter/SegmentFilter'
import {useTranslation} from "react-i18next";

const SegmentLeadsPage = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient()
    const {segmentId} = useParams()
    const {segment, segmentRequest} = useSegmentState()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [visibleColumns, setVisibleColumns] = useState([])

    const onSegmentUpdated = async () => {
        await queryClient.invalidateQueries(['segmentLeads', segmentId])
    }

    useEffect(() => {
        setBreadcrumbs([
            {label: t('main menu.audiences'), link: '/'},
            {label: segment && segment.name, loading: segmentRequest.isLoading}
        ])
        setSection('segments')
    }, [setBreadcrumbs, setSection, segment, segmentRequest.isLoading, t])
    const renderHeader = () => {
        return ( <AudienceHeader /> )
    }
    const renderSegmentFilter = () => {
        return segment && (
            <SegmentFilter
                segment={segment}
                loading={segmentRequest.isLoading}
                onSegmentUpdated={onSegmentUpdated}
            />
        )
    }

    return (
        <Fragment>
            {/*<SegmentFilterComponent*/}
            {/*    segment={segment}*/}
            {/*    onSegmentUpdated={onSegmentUpdated}*/}
            {/*/>*/}
            <SegmentLeads
                renderSegmentFilter={renderSegmentFilter}
                renderHeader={renderHeader}
                segmentId={segmentId}
                setSelectedRows={setSelectedRows}
                onSearch={setSearch}
                onSorting={setSorting}
                setVisibleColumns={setVisibleColumns}
            />
            <SegmentLeadsPageActions
                segment={segment}
                loading={segmentRequest.isLoading}
                search={search}
                sorting={sorting}
                selectedRows={selectedRows}
                visibleColumns={visibleColumns}
            />
        </Fragment>
    )
}

export default SegmentLeadsPage
