import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getTeam, updateUserStatus} from '../../../api';
import ListTable from '../../../components/ListTable/ListTable';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ShareBalanceModal from '../../../components/modals/ShareBalanceModal';
import {useAppState} from '../../../Contexts/AppContext';
import TeamItemContextMenu from '../../../components/TeamItemContextMenu';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import AlertToastr from "../../../Utils/alert";
import UserBalance from "../../../components/balance/UserBalance";

const TeamMembers = () => {
    const { t } = useTranslation();
    const { balance } = useAppState();
    const [, setSorting] = useState([]);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [member, setMember] = useState(null);
    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
    const [currentModel, setCurrentModel] = useState(null)

    const isDisabledSharing = useMemo(() => {
        return Boolean(balance) && balance <= 0;
    }, [balance]);

    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Team.Fields.Name'),
                accessor: 'name',
            },
            {
                Header: t('Team.Fields.Email'),
                accessor: 'email',
            },
            {
                Header: t('Team.Fields.Role'),
                accessor: 'role',
                Cell: ({ value }) => t(`Team.Roles.${value}`),
            },
            {
                Header: t('Transaction.Balance'),
                accessor: 'balance',
                Cell: ({ value, row }) => {
                    return (
                        <Button size="small" variant="text"
                                disabled={isDisabledSharing}
                                startIcon={<AddCircleIcon />}
                                onClick={e => handleShareBalance(row.original)}>

                            <UserBalance value={value} />
                        </Button>
                    );
                },
            },
            {
                Header: t('Transaction.Auto Refill'),
                accessor: 'balance_auto_refill',
                Cell: ({ value }) => <b>{value ? <UserBalance value={value} /> : '-'}</b>,
            },
            {
                Header: t('Team.Fields.Status'),
                accessor: 'blocked',
                Cell: ({ value }) => (
                    value ? (
                        <div className={`chipLike inactive`}>
                            <span className="chipLikeLabel">{t(`Team.Fields.Blocked`)}</span>
                        </div>
                    ) : (
                        (
                            <div className={`chipLike active`}>
                                <span className="chipLikeLabel">{t(`Team.Fields.Active`)}</span>
                            </div>
                        )
                    )
                ),
            },
            {
                Header: '',
                id: 'actions',
                className: 'text-right',
                Cell: ({ value, row }) => {
                    return (
                        <TeamItemContextMenu
                            item={row.original}
                            onToggleBlock={showUpdateStatusConfirmation}
                        />
                    );
                },
            },
        ];
    }, [t, isDisabledSharing]);

    const queryClient = useQueryClient();

    const teamRequest = useQuery(
        ['team'],
        () => getTeam(),
        { keepPreviousData: true },
    );

    const updateStatusRequest = useMutation(updateUserStatus)

    useEffect(() => {
        if (teamRequest.isSuccess) {
            setData(teamRequest.data.data);
        }
    }, [teamRequest.isSuccess, teamRequest.data]);

    const handleShareBalance = (member = null) => {
        setMember(member);
        setOpen(true);
    };

    const showUpdateStatusConfirmation = (user) => {
        setCurrentModel(user);
        setShowChangeStatusModal(true);
    }

    const handleToggleBlock = async () => {
        if (!currentModel) return;

        await updateStatusRequest.mutateAsync({id: currentModel.id, blocked: !currentModel.blocked});
        setShowChangeStatusModal(false);
        await queryClient.invalidateQueries('team')
        setCurrentModel(null);
    };

    const handleSuccessShare = () => {
        teamRequest.refetch();
        AlertToastr.showAlert(t('Transaction.Share balance Successful'))
    };

    const confirmPromptQuestion = currentModel
        ? ( currentModel.blocked ? t('Team.Prompts.Unblock') : t('Team.Prompts.Block'))
        : ''

    return (
        <Fragment>
            <ListTable
                columns={tableColumns}
                data={data}
                loading={teamRequest.isLoading}
                fetching={teamRequest.isFetching}
                onSort={setSorting}
                emptyDataText={t('System Attributes.No Attributes Found')}
                disableSearchBlock={true}
            />

            <ShareBalanceModal
                open={open}
                member={member}
                handleSuccess={handleSuccessShare}
                handleClose={() => setOpen(false)} />

            <ConfirmModal
                loading={updateStatusRequest.isLoading}
                message={confirmPromptQuestion}
                open={showChangeStatusModal}
                handleClose={e => setShowChangeStatusModal(false)}
                handleConfirm={handleToggleBlock}
            />
        </Fragment>
    );
};

export default TeamMembers;
