import React from 'react'
import {useAuth} from '../../Contexts/AuthProvider'
import {useTranslation} from "react-i18next";
import Logo from "../../assets/img-new/logotext.svg"
import AuthImg from "../../assets/img-new/auth-img.png"

const AuthLayout = ({ children }) => {
    const { isLoggedIn } = useAuth()
    const { t } = useTranslation();
    return (
        <div className="authLayout">
          <div className="formPart">
            <div className="formPartContent">
              <div className="logoHolder">
                <img src={Logo} alt=""/>
              </div>
              {/*{!isLoggedIn && <ul className="authFormNav mb30">*/}
              {/*  <li className="authFormNavItem"><NavLink exact to="/signin" activeClassName='active'>{t('Auth Layout.Login')}</NavLink></li>*/}
              {/*  <li className="authFormNavItem"><NavLink to="/register" activeClassName='active'>{t('Auth Layout.Register')}</NavLink></li>*/}
              {/*</ul>}*/}
              {children}
            </div>
          </div>
          <div className="imgPart">
            <div className="imgPartContent">
              <div className="imgHolder">
                <img src={AuthImg} alt=""/>
              </div>
              <div className="textHolder">
                <h2 className="text-center">Placeholder Text Here</h2>
                <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
              </div>
            </div>
          </div>
        </div>

    )
}

export default AuthLayout
