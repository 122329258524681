import React, {Fragment, useState} from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import {ReactComponent as TextsmsOutlined } from '../assets/img-new/getTemplate.svg';
import {useTranslation} from 'react-i18next';
import SelectSMSTemplateModal from './modals/SelectSMSTemplateModal';
import {useAppState} from '../Contexts/AppContext';

const SelectSmsTemplateButton = ({onSelect}) => {

    const [showModal, setShowModal] = useState(false)
    const {t} = useTranslation()
    const {availableSMSTemplates} = useAppState()

    return (
        <Fragment>
            <Tooltip title={t('SMS Templates.Load Template')}>
                <IconButton
                    size="small"
                    onClick={() => setShowModal(true)}
                >
                    <TextsmsOutlined />
                </IconButton>
            </Tooltip>
            <SelectSMSTemplateModal
                options={availableSMSTemplates}
                open={showModal}
                onSelect={(message) => {
                    onSelect(message);
                    setShowModal(false);
                }}
                handleClose={() => {
                    setShowModal(false);
                }}
            />
        </Fragment>

    );
};

export default SelectSmsTemplateButton;
