const theme = {
    breakpoints: {
        values: {
            xs: '0',
            sm: '576',
            md: '768',
            lg: '992',
            xl: '1200'
        }
    },
    palette: {
        primary: {
            main: '#2E49D1'
        },
        secondary: {
            main: '#5975FF'
        },
        error: {
            main: '#E05263'
        },
        success: {
            main: '#72C07F'
        },
        text: {
            primary: '#1F2123',
            secondary: '#AFB1B3'
        },
        divider: '#E4E6E7'
    },
    typography: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
    // spacing: 5,
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '4px',
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '400',
                padding: '8px 32px'
            },
            text: {
                padding: '8px 16px'
            },
            outlined: {
                padding: '7px 16px'
            }
        },
        MuiAlert: {
            root: {
                borderRadius: '10px',
                background: 'rgba(89, 117, 255, 0.18)!important',
                color: '#1F2123!important',
                '& .MuiAlert-icon': {
                    color: '#2E49D1!important'
                },
                '& a': {
                    color: '#2E49D1!important'
                }
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: "16px",
                color: "#B8BDC2",
                lineHeight: "14px"
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '16px',
                fontWeight: '300',
                lineHeight: '22px',
                color: '1F2123'
            },
            input: {
                height: 'auto',
                fontSize: '16px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 15px) scale(1)'
            }
        },
        MuiLinearProgress: {
            root: {
                height: '5px',
                borderRadius: '3px'
            }
        },
        MuiOutlinedInput: {
            root: {
                background: '#ffffff',
                borderRadius: '4px',
                '& .MuiInputAdornment-root': {
                    color: '#B8BDC2'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E5E5'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E5E5',
                    borderWidth: '1px'
                }
            },

            input: {
                padding: '9px 15px',
                background: 'transparent'
            },
            notchedOutline: {
                borderColor: '#E5E5E5'
            }
        },
        MuiPaper: {
          rounded: {
              borderRadius: '10px',
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)!important"
          }
        },
        MuiFormControl: {
            root: {
                display: 'flex'
            },
            marginNormal: {
                marginTop: '15px',
                marginBottom: '10px'
            }
        },
        MuiFormControlLabel: {
            root: {
                '& > .MuiTypography-body1': {
                    fontSize: '14px'
                }
            }
        },
        MuiDialog: {
            paper: {
                overflowY: "visible"
            },
            paperWidthSm: {
                maxWidth: '496px',
                width: '100%'
            }
        }
    },
    props: {
        MuiButton: {
            disableElevation: true
        },
        MuiTextField: {
            fullWidth: true
        }
    }
}

export default theme
