import React from 'react'
import InlineLoader from './loaders/InlineLoader'
import {useSegmentState} from '../Contexts/SegmentContext'
import {useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const AudienceHeader = () => {

    const {
        segment,
        segmentRequest,
        leadsCount
    } = useSegmentState()

    const history = useHistory()
    const { t } = useTranslation();


    const items = [
        {
            label: t('Sources.Total Leads'),
            count: leadsCount,
            action: () => {
                if (segment) history.push(`/audiences/${segment?.id}`)
            },
            loading: leadsCount === undefined
        },
        {
            label: t("Sources.Segments"),
            count: segment?.segments?.length,
            action: () => {
                if (segment) history.push(`/audiences/${segment?.id}/segments`)
            },
            loading: segmentRequest.isLoading
        }
    ]

    return (
        <div className="pageHeadingBlock">
            <div className="pageHeadingBlockWrapper mb15">
                    {
                        items.map(item => (
                            <div className="staticCard cursor-pointer" key={item.label} onClick={item.action}>
                                {/* <div className="partContent cursor-pointer" onClick={item.action}> */}
                                    <span  className="fs12">{item.label}: </span>
                                    <div>{item.loading ? <InlineLoader /> : item.count}</div>
                                {/* </div> */}
                            </div>
                        ))
                    }
            </div>
        </div>
    )
}

export default AudienceHeader
