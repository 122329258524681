import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import PlusCircle from '../../assets/img/plus_circle.svg'
import EventEmitter from '../../Utils/EventEmitter'
import Delete from '../../assets/img-new/delete.svg'
import Run from '../../assets/img/caret.svg'
import LookupSaveModal from '../../components/modals/LookupSaveModal'
import DeleteModal from '../../components/modals/DeleteModal'
import {useMutation, useQueryClient} from 'react-query'
import {removeLookup, updateLookup} from '../../api'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const LookupListActions = props => {
    const { t } = useTranslation();
    const {
        selectedRows,
        selectedModels,
        userGateways,
        typeOptions = [],
        statusOptions = []
    } = props
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [bulkDeleteIsLoading, setBulkDeleteIsLoading] = useState(false)
    const [bulkRunIsLoading, setBulkRunIsLoading] = useState(false)

    const {availableSources} = useAppState()
    const {setContextMenu} = useAppDispatch()
    const queryClient = useQueryClient()

    const deleteMutation = useMutation(removeLookup)
    const updateMutation = useMutation(updateLookup)

    const disableRunLookup = useMemo(() => {
        if (!selectedModels || !selectedModels.length) return true

        return !selectedModels.filter(item => item.status !== 1).length
    }, [selectedModels])

    useEffect(() => {
        const items = [
            {
                label: t('Lookup.Add new Source'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('LookupAddNewSource'),
            },
            {
                label: t('Lookup.Delete Selection'),
                icon: Delete,
                disabled: !selectedRows || !selectedRows.length,
                action: () => EventEmitter.emit('LookupBulkDeletion')
            },
            {
                label: t('Lookup.Run Lookup'),
                icon: Run,
                disabled: disableRunLookup || bulkRunIsLoading,
                action: () => EventEmitter.emit('LookupBulkRun'),
                iconStyle: {transform: 'rotate(-90deg)'}
            },
        ]
        setContextMenu(items)
    }, [
        selectedRows,
        setContextMenu,
        disableRunLookup,
        bulkRunIsLoading,
        t
    ])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('LookupAddNewSource', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('LookupBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])


    const runLookups = useCallback(async () => {
        setBulkRunIsLoading(true)
        await Promise.all(selectedModels.filter(item => item.status !== 1).map(item => {
            return updateMutation.mutateAsync({
                ...item, status: 1
            })
        }))
        AlertToastr.showAlert(t('Lookup.Selected lookups have been started'))
        queryClient.invalidateQueries('lookupList').then()
        setBulkRunIsLoading(false)
    }, [selectedModels, updateMutation, setBulkRunIsLoading, queryClient, t])

    useEffect(() => {
        const onEvent = () => runLookups()

        const listener = EventEmitter.addListener('LookupBulkRun', onEvent)
        return () => { listener.remove() }
    }, [runLookups])

    const deleteItems = async () => {
        setBulkDeleteIsLoading(true)
        await Promise.all(selectedRows.map((id) => {
            return deleteMutation.mutateAsync(id)
        }))

        setBulkDeleteIsLoading(false)
        setShowDeleteModal(false)
        queryClient.invalidateQueries('lookupList').then()
    }

    return (
        <Fragment>
            <LookupSaveModal
                open={showSaveModal}
                handleClose={() => setShowSaveModal(false)}
                sources={availableSources}
                userGateways={userGateways}
                typeOptions={typeOptions}
                statusOptions={statusOptions}
            />
            <DeleteModal
                loading={bulkDeleteIsLoading}
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteModal(false)}
                instance={t('Lookup.selected lookups')}
            />
        </Fragment>
    )
}

export default LookupListActions
