import React, {Fragment, useEffect} from 'react'
import {useRowSelect, useSortBy, useTable} from 'react-table'
import ListLoader from './loaders/ListLoader'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import TableEmptyListMessage from './TableEmptyListMessage'
import ListPagination from './ListPagination'

const BasicTable = props => {

    const {
        columns,
        data,
        loading,
        isSelectionColumn,
        onSelectedRows,
        onRowClick,
        onSort,
        emptyDataText,
        pagination,
        pageLimit,
        onPageChange,
        onLimitChange,
        initSort,
    } = props

    const tableInstance = useTable({
        columns,
        data,
        manualSortBy: true,
        initialState: {
            sortBy: initSort ? initSort : []
        }
    },
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push((columns) => {
                return isSelectionColumn ? [
                    {
                        id: 'selection',
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({row}) => (
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                        className: 'tableId'
                    },
                    ...columns
                ] : columns
            })
        }
    )



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { sortBy }
    } = tableInstance


    useEffect(() => {
        if (onSelectedRows)
            onSelectedRows(selectedFlatRows.map(row => row.original.id))
    }, [selectedFlatRows, onSelectedRows])

    useEffect(() => {
        return onSort(sortBy.map(item => (
            {
                key: item.id,
                direction: item.desc ? 'desc' : 'asc'
            }
        )))
    }, [sortBy, onSort])

    // const renderSelectedRows = () => {
    //     return (
    //         <pre>
    //             <code>
    //                 {
    //                     JSON.stringify({
    //                         selectedFlatRows: selectedFlatRows.map(row => row.original.id)
    //                     }, null, 2)
    //                 }
    //             </code>
    //         </pre>
    //     )
    // }

    const renderEmptyList = () => {
        if (!pagination || pagination['total'] > 0) return null
        return <TableEmptyListMessage>{emptyDataText}</TableEmptyListMessage>
    }

    const renderPagination = () => {
        if (!pagination) return null

        if (pagination && pagination['total'] > 0) {
            return (
                <ListPagination
                    limit={pageLimit}
                    pagination={pagination}
                    onChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            )
        }
        return null
    }

    return (
        <Fragment>
            <div className="tableScrollHolder">
                <table {...getTableProps()}>
                    <thead>
                    {
                        headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                              {
                                  headerGroup.headers.map(column => {
                                      const sortClass = column.isSorted ? (column.isSortedDesc ? 'hasArrow' : 'hasArrow up') : ''
                                      const columnClass = `${column.className} ${sortClass}`
                                      return (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={columnClass}
                                        >
                                            {column.render('Header')}
                                        </th>
                                      )
                                  })
                              }
                          </tr>
                        ))
                    }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {loading && <ListLoader/>}
                    {renderEmptyList()}
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                  {
                                      row.cells.map(cell => {
                                          return (
                                            <td {...cell.getCellProps({
                                                className: cell.column.className
                                            })} onClick={() => {
                                                if (cell.value !== undefined && onRowClick) {
                                                    onRowClick(cell.row.original)
                                                }
                                            }}>
                                                {cell.render('Cell')}
                                            </td>
                                          )
                                      })
                                  }
                              </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            {renderPagination()}
            {/*{renderSelectedRows()}*/}
        </Fragment>
    )
}

export default BasicTable
