import React from 'react'
import {SegmentProvider} from '../../Contexts/SegmentContext'
import SegmentPage from './SegmentPage'

const SegmentWrapper = () => {
    return (
        <SegmentProvider>
            <SegmentPage />
        </SegmentProvider>
    )
}

export default SegmentWrapper
