import React from 'react'
import checkBoxIcon from '../assets/img-new/checkboxIcon.svg';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, disabled, isColumn, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
        return (
            <div ref={resolvedRef} className={disabled ? "checkbox disabled" : rest?.checked ? "checkbox active" : "checkbox"} onClick={disabled ? null : isColumn ? (() => {rest?.onChange({target:{checked: !rest.checked}})}) : (rest.onChange)}>
                {rest && rest.checked && <img src={checkBoxIcon} alt=" " />}
              {/*<Checkbox*/}
              {/*  color="primary"*/}
              {/*  ref={resolvedRef}*/}
              {/*  {...rest}*/}
              {/*/>*/}
                {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
            </div>
        )
    }
)

export default IndeterminateCheckbox
