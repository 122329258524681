import React, {useState} from 'react'
import HeaderComponent from './Header.component'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {useAppState} from '../../Contexts/AppContext'
import MainMenuItem from '../menus/MenuItem'
import {useAuth} from '../../Contexts/AuthProvider'
import Logo from '../../assets/img-new/logo-symbol.svg'
import {ClickAwayListener} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import {CSSTransition} from 'react-transition-group';


const MainLayout = props => {
    const {
        children,
        source,
        list,
        lead,
        segment,
        segmentLead
    } = props
    const { accessDenied } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)
    const [activeMenu, setActiveMenu] = useState(null)
    const location = useLocation()

    const {mainMenu, contextMenu, contextLinks} = useAppState()
    const handleMobileAsideMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const open = Boolean(anchorEl)
    const handleMobileAsideMenuClose = () => {
        setAnchorEl(null)
    }


    const renderMainMenu = () => {
        return (
            <div className="menuList height85">
                {mainMenu.filter(i => !i.hide).map((item, index) => <MainMenuItem item={item} setActiveMenu={setActiveMenu} key={index} disabled={accessDenied}/>)}
            </div>
        )
    }

    const renderSubMenu = () => {
        // if(!activeMenu || !activeMenu.hasOwnProperty('submenu')) {
        //     return null
        // }
        const onClickAway = () => {
          setActiveMenu(null)
          document.getElementsByTagName('body')[0].classList.remove('subMenuOpened');
        }
        const onClickLink = () => {
            setActiveMenu(null)
            document.getElementsByTagName('body')[0].classList.remove('subMenuOpened');
        }
        return (
              <CSSTransition
                in={Boolean(activeMenu)}
                timeout={300}
                unmountOnExit
                classNames="sub-menu"
              >
                <ClickAwayListener onClickAway={onClickAway}>
                <div className="secondMenuList">
                    {activeMenu && activeMenu.hasOwnProperty('submenu') && activeMenu.submenu.map(m => {
                        return (
                            <div className='secondMenuListItem' key={`sub_${m.label}`}>
                                <Link to={m.link} onClick={onClickLink}>{m.label}</Link>
                            </div>
                        )
                    })}
                </div>
              </ClickAwayListener>
            </CSSTransition>
        )
    }

    return (
        <div className="mainLayout">
            <HeaderComponent
                source={source}
                list={list}
                lead={lead}
                segment={segment}
                segmentLead={segmentLead} />

            <aside className="mainMenu">
              <div className="logoHolder">
                <a href="#"><img src={Logo} alt=""/></a>
              </div>
                {renderMainMenu()}
            </aside>

            <aside>
                {renderSubMenu()}
            </aside>
            <main className="mainLayoutContent">
                <div className="mobileSubMenu">
                    <h3>Sources</h3>
                    <div className="mobileAsideMenu">
                        <IconButton onClick={handleMobileAsideMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleMobileAsideMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            {/*{renderAsideMenu()}*/}
                            {/*<List component="nav" aria-label="main mailbox folders" className='popoverMenu'>*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <SettingsIcon/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Import File"/>*/}
                            {/*    </ListItem>*/}
                            {/*    <ListItem button>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <ExitToAppIcon/>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText primary="Add New Source"/>*/}
                            {/*    </ListItem>*/}
                            {/*</List>*/}
                        </Popover>
                    </div>
                </div>
                <div className="mainContainer">
                    {children}
                </div>
            </main>
        </div>
    )
}

export default MainLayout
