import React, {useEffect, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AlertToastr from '../../Utils/alert'
import {useAuth} from '../../Contexts/AuthProvider';
import {useMutation} from 'react-query';
import {changePassword} from '../../api';
import {useTranslation} from "react-i18next";

const ChangePasswordPage = () => {
    const { t } = useTranslation();
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const {login} = useAuth()

    const changePasswordMutation = useMutation(changePassword)

    const onChangeClicked = async (e) => {
        e.preventDefault()

        try {
            const response = await changePasswordMutation.mutateAsync({
                current_password: oldPassword,
                password: password,
                password_confirmation: confirmPassword
            })

            const { access_token } = response.data
            login(access_token)
            setOldPassword('');
            setPassword('');
            setConfirmPassword('')
            AlertToastr.showAlert(t('Auth.Password updated successfully'))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (changePasswordMutation.error) {
            AlertToastr.showErrorResponseAlert(changePasswordMutation.error)
        }
    }, [changePasswordMutation.error])

    return (
        <div className='pageHolder'>
            {/*<p className='mb20'>Change password</p>*/}
            <form noValidate autoComplete="off">
                <TextField
                    id="oldPassword"
                    label={t('Auth.Old password')}
                    variant="outlined"
                    type="password"
                    value={oldPassword}
                    onChange={e => setOldPassword(e.target.value)}
                    margin='normal'
                    autoComplete="current-password"
                />
                <TextField
                    id="password"
                    label={t("Auth.Password")}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    margin='normal'
                    autoComplete="new-password"
                />
                <TextField
                    id="confirmPassword"
                    label={t("Auth.Confirm password")}
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    margin='normal'
                    autoComplete="new-password"
                />
                <div className="btnHolder mt20 mb15">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={onChangeClicked}
                    >
                        {t("Auth.Change")}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordPage
