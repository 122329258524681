import React from 'react'
import Button from '@material-ui/core/Button'
import {ReactComponent as EditIcon} from '../assets/img-new/editIcon.svg';
import {ReactComponent as DisabledEditIcon} from '../assets/img-new/disabledEditIcon.svg';
import {ReactComponent as DeleteIcon} from '../assets/img-new/deleteIcon.svg';
import {ReactComponent as DisabledDeleteIcon} from '../assets/img-new/disabledDeleteIcon.svg';
import {useTranslation} from "react-i18next";

const ShortUrlDomainItemContextMenu = ({item, onEdit, onDelete, disabled}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose =  () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        setAnchorEl(null)
        onEdit()
    }

    const handleDelete = () => {
        setAnchorEl(null)
        onDelete()
    }

    return (
        <div>
            <button className="shortUrlButton" style={{padding:'0 12px 0 0'}} onClick={!disabled ? handleEdit : null}>
                {!disabled ? <EditIcon className="shortUrlButtonIcon"/> : <DisabledEditIcon/>}
            </button>
            <button className="shortUrlButton" style={{padding:'0 0 0 0'}} onClick={!disabled ? handleDelete : null}>
                {!disabled ? <DeleteIcon className="shortUrlButtonIcon"/> : <DisabledDeleteIcon/>}
                
            </button>
            {/* <IconButton
                onClick={handleClick}
                size='small'>
                <MoreVertIcon/>
            </IconButton>
            {
                item && (
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleEdit}>{t('Short Url Domain Item Context Menu.Edit')}</MenuItem>
                        <MenuItem onClick={handleDelete}>{t('Short Url Domain Item Context Menu.Remove')}</MenuItem>
                    </Menu>
                )
            } */}
        </div>
    )
}

export default ShortUrlDomainItemContextMenu
