import React from 'react'
import {useTranslation} from "react-i18next";
import LimitCounter from "./LimitCounter";

const HeaderFreeSmsCounter = () => {
    const { t } = useTranslation()

    return (
        <div className='headerSmsStat'>
            {t('FreeSmsCounter.Free SMS')}:&nbsp;<LimitCounter limitName={'free-sms-limit'} />
        </div>
    )
}

export default HeaderFreeSmsCounter

