import React, {Fragment, useEffect, useState} from 'react'
import {useCampaignState} from '../../Contexts/CampaignContext'
import SaveCampaignStepModal from '../../components/modals/SaveCampaignStepModal'
import CampaignStepItem from '../../components/CampaignStepItem'
import DeleteModal from '../../components/modals/DeleteModal'
import {useMutation, useQueryClient} from 'react-query'
import {removeCampaignStep} from '../../api'
import Alert from '@material-ui/lab/Alert'
import {campaignStepDeleteDisabled, campaignStepEditDisabled} from '../../helpers'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";
import StartCampaign from "../../assets/img-new/start-campaign.svg"

const CampaignStart = () => {
    const { t } = useTranslation();
    const [showSaveCampaignStepModal, setShowCampaignStepModal] = useState(false)
    const [showUpdateCampaignStepModal, setShowUpdateCampaignStepModal] = useState(false)
    const [showDeleteCampaignStepModal, setShowDeleteCampaignStepModal] = useState(false)
    const [currentStep, setCurrentStep] = useState()

    const {
        campaign,
        steps
    } = useCampaignState()

    const queryClient = useQueryClient()
    const deleteStepRequest = useMutation(removeCampaignStep)

    useEffect(() => {
        if (deleteStepRequest.error) {
            AlertToastr.showErrorResponseAlert(deleteStepRequest.error)
        }
    }, [deleteStepRequest.error])

    const renderAttribute = (label, value, show = true) => {
        if (!show) return null

        return (
            <div className="tr">
                <div className="td">{label}</div>
                <div className="td">{value || '-'}</div>
            </div>
        )
    }
    const handleOpenModal = () => {
        setShowCampaignStepModal(true)
    }
    const handleEditStep = (item) => {
        setCurrentStep(item)
        setShowUpdateCampaignStepModal(true)
    }

    const handleDeleteStep = (item) => {
        setCurrentStep(item)
        setShowDeleteCampaignStepModal(true)
    }

    const deleteStep = async () => {
        if (!currentStep) return

        await deleteStepRequest.mutateAsync(currentStep.id)
        setShowDeleteCampaignStepModal(false)
        await queryClient.invalidateQueries('campaignStepsView')
    }

    const renderSmsAlert = () => {
        if (!campaign || campaign.type !== 'sms') return null

        return (
            <Alert severity="error" className="mb20">
                {t("Campaigns.Note")}
            </Alert>
        )
    }

    const disableAddStep = !campaign || campaign.status !== 'draft'

    return (
        <div className='campaignPageHolder'>
            <div className="campStepsHolder">
                {/* {renderSmsAlert()} */}
                <div className="startCampaignHolder" onClick={handleOpenModal}>
                    <div className="startCampaign">
                        <div className="icoHolder">
                            <img src={StartCampaign} className="ico"/>
                        </div>
                        <div className="textHolder">
                            Click Here to Add Start Event
                        </div>
                    </div>
                </div>
                <ul className="stepsList">
                    {
                        steps.map((item, index) => {
                            const isLast = (steps.length === 1) || (index === steps.length - 1)
                            return (
                                <CampaignStepItem
                                    key={item.id}
                                    item={item}
                                    onEdit={() => handleEditStep(item)}
                                    onDelete={() => handleDeleteStep(item)}
                                    disableEdit={campaignStepEditDisabled(campaign, item, isLast)}
                                    disableDelete={campaignStepDeleteDisabled(campaign, item, isLast)}
                                    handleOpenModal={handleOpenModal}
                                />
                            )
                        })
                    }
                    {/*<li className="stepsListItem btnHolder">*/}
                    {/*    <Button*/}
                    {/*        variant="outlined"*/}
                    {/*        startIcon={<AddCircleIcon/>}*/}
                    {/*        onClick={() => setShowCampaignStepModal(true)}*/}
                    {/*        disabled={disableAddStep}*/}
                    {/*    >*/}
                    {/*        {t("Campaigns.Add Step")}*/}
                    {/*    </Button>*/}
                    {/*</li>*/}
                </ul>
            </div>
            {/*<div className="campSettingsHolder">*/}
                {/*<div className="leadDetails">*/}
                    {/*<div className="leadDetailsHeading mb30">*/}
                        {/*<div className="heading">*/}
                        {/*    Settings*/}
                        {/*</div>*/}
                        {/*<div className='moreIcon'>*/}
                        {/*    <IconButton>*/}
                        {/*        <MoreVertIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="leadDetailsTableLike campSettingsTable">*/}
                    {/*    <div className="tableLike">*/}
                    {/*        {renderAttribute(t('Campaigns.Campaign Name'), campaign?.name)}*/}
                    {/*        {renderAttribute(t('Campaigns.Start Time'), toServerFormat(campaign?.started_at))}*/}
                    {/*        {renderAttribute(t('Campaigns.Timezone'), campaign?.started_at_tz_label)}*/}
                    {/*        {renderAttribute('ID', campaign?.id)}*/}
                    {/*        {renderAttribute(t('Campaigns.Type'), campaign?.type_label)}*/}
                    {/*        {renderAttribute(t('Campaigns.Gateway'), campaign?.setting_gateway_message_label)}*/}
                    {/*        {renderAttribute(t('Campaigns.SID'), campaign?.ms_name)}*/}
                    {/*        /!*{renderAttribute('Gateway', campaign?.gateway_label)}*!/*/}
                    {/*        {renderAttribute(t('Campaigns.Alpha Name'), campaign?.alpha_name)}*/}
                    {/*        {renderAttribute(t('Campaigns.Frequency'), campaign?.frequency_count + ' per ' + campaign?.frequency_period_label, !!campaign)}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*</div>*/}

            {
                campaign && (
                    <Fragment>
                        <SaveCampaignStepModal
                            campaign={campaign}
                            open={showSaveCampaignStepModal}
                            disableDelay={!steps || !steps.length}
                            handleClose={() => {
                                setShowCampaignStepModal(false)
                            }}
                        />
                        <DeleteModal
                            loading={deleteStepRequest.isLoading}
                            open={showDeleteCampaignStepModal}
                            handleDelete={deleteStep}
                            handleClose={() => setShowDeleteCampaignStepModal(false)}
                            instance={'step'}
                        />
                    </Fragment>

                )
            }

            {
                currentStep && (
                    <SaveCampaignStepModal
                        defaultValues={currentStep}
                        campaign={campaign}
                        open={showUpdateCampaignStepModal}
                        disableDelay={!steps || !steps.length || steps[0]?.id === currentStep?.id}
                        handleClose={() => {
                            setCurrentStep(null)
                            setShowUpdateCampaignStepModal(false)
                        }}
                    />
                )
            }


        </div>
    )
}

export default CampaignStart
