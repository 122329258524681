import React, {useState} from 'react';
import LeadAttributeEditValue from './LeadAttributeEditValue';
import LeadAttributeValue from './LeadAttributeValue';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

const LeadAttributeRow = ({attr, onSuccessUpdate}) => {

    const [edit, setEdit] = useState(false)

    const switchToEditMode = () => setEdit(true)
    const switchToViewMode = () => setEdit(false)

    const handleEdit = (updatedAttribute) => {
        if (updatedAttribute) {
            if (onSuccessUpdate) onSuccessUpdate(updatedAttribute)
        }
        switchToViewMode()
    }

    return (
        <div className="tr">
            <div className="td">{attr.name}</div>
            <div className="td">
                <div className="editTextOnClick">
                    <div className="captionPart">
                        {
                            edit
                                ? <LeadAttributeEditValue onEditDone={handleEdit} attr={attr} />
                                : <LeadAttributeValue onDoubleClick={switchToEditMode} value={attr.value} />
                        }
                    </div>
                    {
                        attr.type === 'phone' && (
                            <div className="captionPart">
                                <span className='caption'>
                                    {/*{ attr.ban && <Fragment><span style={{background: '#3c3c3c', color: '#fff', borderRadius: '4px', padding: '1px 4px'}}>blacklisted</span><br /></Fragment>}*/}
                                    { attr.status_label && <>Status: <span>{attr.status_label}</span><br /></>}
                                    { attr.carrier_name && <>Carrier: <span>{attr.carrier_name}</span><br /></>}
                                    { attr.carrier_type && <>Carrier type: <span>{attr.carrier_type}</span><br /></>}
                                </span>
                            </div>
                        )
                    }
                    {
                        !edit && <IconButton onClick={() => setEdit(true) } size="small"><EditIcon /></IconButton>
                    }
                </div>
            </div>
        </div>
    );
};

export default LeadAttributeRow;
