import React, {Fragment, useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {Controller, useForm} from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Collapse} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import {makeStyles} from '@material-ui/core/styles'
import {useMutation, useQueryClient} from 'react-query'
import {storeLead} from '../../api'
import AlertToastr from '../../Utils/alert'
import MenuItem from '@material-ui/core/MenuItem'
import AddAttributeButton from '../AddAttributeButton';
import {useTranslation} from "react-i18next";
import DataService from "../../Services/DataService";

const useStyles = makeStyles((theme) =>
    ({
        collapseWrapper: {
            display: 'block'
        },
        selectAdornment: {
            marginRight: theme.spacing(3),
        },
    })
)

const LeadSaveModal = props => {

    const classes = useStyles()
    const queryClient = useQueryClient()

    const {
        sources,
        attributes,
        open,
        handleClose,
        defaultValues
    } = props

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const [availableLists, setAvailableLists] = useState([])
    const [showAddSourceModal, setShowAddSourceModal] = useState(false);
    const [showAddListModal, setShowAddListModal] = useState(false);
    const [createdListId, setCreatedListId] = useState(null)
    const [isDropdownSourceMode, setIsDropdownSourceMode] = useState(true);
    const [isDropdownListMode, setIsDropdownListMode] = useState(true);
    const [newSourceName, setNewSourceName] = useState("");
    const [newListName, setNewListName] = useState("");
    const sourceId = watch('source_id')
    const { t } = useTranslation();
    useEffect(() => {
        setValue('list_id', '')
        if (!sourceId) return setAvailableLists([])
        const sourceModel = sources.find(item => item.id === sourceId)
        setAvailableLists(sourceModel ? sourceModel.lists : [])

    }, [sourceId, setValue, sources])

    useEffect(() => {
        if (!createdListId) return;
        if (availableLists.find(item => item.id === createdListId)) {
            setValue('list_id', createdListId)
        }
    }, [
        createdListId, availableLists, setValue
    ])

    const [showCollapsed, setShowCollapsed] = useState(false)

    const storeLeadMutation = useMutation(storeLead)

    const onSubmit = handleSubmit(async data => {
        const {source_id, list_id, ...attributes} = data
        let storeData = {}
        let sourceId = ""
        let listId = ""
        if (!isDropdownSourceMode || !isDropdownListMode) {
            if(!isDropdownSourceMode && isDropdownListMode){
                console.log("Source")
                try {
                    let source = await DataService.createSource(newSourceName);
                    sourceId = source.data.data.id
                    storeData = {
                        source_id: source.data.data.id,
                        list_id,
                        attributes: Object
                            .keys(attributes)
                            .map(attrId => ({attr_id: parseInt(attrId), value: attributes[attrId]}))
                            .filter(attr => !!attr.value)
                    }
                }catch (error) {
                    AlertToastr.showErrorResponseAlert(error);
                    return
                }
            }
            else if(!isDropdownListMode && isDropdownSourceMode){
                console.log("List")
                try {
                    let list = await DataService.createList(
                        source_id,
                        newListName,
                        "active"
                    );
                    listId = list.data.data.id;
                    storeData = {
                        source_id,
                        list_id: list.data.data.id,
                        attributes: Object
                            .keys(attributes)
                            .map(attrId => ({attr_id: parseInt(attrId), value: attributes[attrId]}))
                            .filter(attr => !!attr.value)
                    }
                } catch (error) {
                    AlertToastr.showErrorResponseAlert(error);
                    return
                }
            } 
            else if (!isDropdownListMode && !isDropdownSourceMode){
                console.log("2")
                try {
                    let source = await DataService.createSource(newSourceName);
                    let list = await DataService.createList(
                        source.data.data.id,
                        newListName,
                        "active"
                        );
                    sourceId = source.data.data.id
                    listId = list.data.data.id;
                    storeData = {
                        source_id: source.data.data.id,
                        list_id: list.data.data.id,
                        attributes: Object
                            .keys(attributes)
                            .map(attrId => ({attr_id: parseInt(attrId), value: attributes[attrId]}))
                            .filter(attr => !!attr.value)
                    }
                } catch (error) {
                    AlertToastr.showErrorResponseAlert(error);
                    return
                }
            }
            try {
                console.log(storeData)
                await storeLeadMutation.mutateAsync(storeData)
                reset()
                handleClose()
                queryClient.invalidateQueries('leadsList').then()
                queryClient.invalidateQueries(['listLeads']).then()
                AlertToastr.showAlert('Lead has been successfully added')
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.errors){
                    AlertToastr.showErrorAlert(error.response.data.errors)
                    if(!isDropdownListMode && !isDropdownSourceMode){
                        console.log("Delete 2")
                        DataService.deleteSource(storeData.source_id)
                        DataService.deleteList(storeData.list_id)
                    }
                    else if(!isDropdownSourceMode && isDropdownListMode){
                        console.log("Delete Source")
                        DataService.deleteSource(storeData.source_id)
                    }
                    else if(isDropdownSourceMode && !isDropdownListMode){
                        console.log("Delete List")
                        DataService.deleteList(storeData.list_id)
                    }
                }
            }
        } else {
            storeData = {
                source_id,
                list_id,
                attributes: Object
                    .keys(attributes)
                    .map(attrId => ({attr_id: parseInt(attrId), value: attributes[attrId]}))
                    .filter(attr => !!attr.value)
            }
            try {
                await storeLeadMutation.mutateAsync(storeData)
                reset()
                handleClose()
                queryClient.invalidateQueries('leadsList').then()
                queryClient.invalidateQueries(['listLeads']).then()
                AlertToastr.showAlert('Lead has been successfully added')
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            }
        }
    })

    const renderField = (key, label, type = null) => {
        return (
            <Controller
                key={key}
                name={key}
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            type={type || 'text'}
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label={label}
                        />
                    )
                }}
            />
        )
    }


    // const renderPhoneField = (key, label) => {
    //     return renderField(`${key}__phone`, label)
    // }
    //
    // const renderEmailField = (key, label) => {
    //     return renderField(`${key}__email`, label)
    // }

    const renderExpandButton = () => {
        return (
            <Button
                onClick={() => setShowCollapsed(prev => !prev)}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={showCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                {showCollapsed ? t('Modals.Show main fields') : t('Modals.Show more fields')}
            </Button>)
    }

    const renderSourceField = () => {
        return (
            <Fragment>
                <Controller
                    name={'source_id'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return isDropdownSourceMode ? (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="dense"
                                className="mb0"
                                label={t("Modals.Source")}
                            >
                                <MenuItem value="" disabled>
                                    Select Source
                                </MenuItem>
                                {sources.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ):(
                            <TextField
                                value={newSourceName}
                                onChange={(e) => setNewSourceName(e.target.value)}
                                id="newSourceName"
                                className="mb0"
                                label={t("Modals.Source")}
                                variant="outlined"
                                margin="dense"
                            />
                        )
                    }}
                />
                {/* <SaveSourceModal
                    onSuccessCreate={(item) => {
                        setValue('source_id', item.id)
                    }}
                    open={showAddSourceModal}
                    handleClose={(event) => {
                        setShowAddSourceModal(false)
                    }}
                /> */}
                <span
                    onClick={(e) => {
                        setIsDropdownSourceMode(!isDropdownSourceMode);
                        setIsDropdownListMode(!isDropdownSourceMode);
                    }}
                    className="linkPrimary text-mute text-bold cursor-pointer"
                >
                    {isDropdownSourceMode
                        ? t("Modals.Create new source")
                        : t("Modals.Select existing source")}
                </span>
            </Fragment>
        )
    }

    const renderListField = () => {
        return (
            <Fragment>
                <Controller
                    name='list_id'
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return isDropdownListMode ? (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="dense"
                                className="mt15 mb0"
                                label={t("Modals.List")}
                            >
                                <MenuItem value="" disabled>
                                    Select List
                                </MenuItem>
                                {availableLists.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <TextField
                                value={newListName}
                                onChange={(e) => setNewListName(e.target.value)}
                                label={t("Modals.List")}
                                variant="outlined"
                                className="mb0"
                                margin="normal"
                            />
                        )
                    }}
                />
                {/* <SaveListModal
                    sourceId={sourceId}
                    onSuccessCreate={(item) => {
                        setCreatedListId(item.id)
                    }}
                    open={showAddListModal}
                    handleClose={(event) => {
                        setShowAddListModal(false)
                    }}
                /> */}
                {isDropdownSourceMode && <span
                    onClick={(e) => setIsDropdownListMode(!isDropdownListMode)}
                    className="linkPrimary text-mute text-bold cursor-pointer"
                >
                    {isDropdownListMode
                        ? t("Modals.Create new list")
                        : t("Modals.Select existing list")}
                </span>}
            </Fragment>
        )
    }

    const title = t('Modals.Add Lead')
    const buttonTitle = t('Modals.Save')

    const mainFields = attributes.filter(item => item.type === 'email' || item.type === 'phone')
    const restFields = attributes.filter(item => item.type === 'string')

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="modal"
        >
            <div className="modalBody modalOverflow">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{title}</h2>
                </div>
                <form onSubmit={onSubmit}>

                            <div style={{display: !defaultValues || !defaultValues.list_id ? 'block' : 'none'}}>
                                {renderSourceField()}
                                {renderListField()}
                            </div>
                    {mainFields.map(attr => renderField(attr.id.toString(), attr.name))}
                    <Collapse in={showCollapsed} classes={{
                        wrapper: classes.collapseWrapper
                    }}>
                        { restFields.map(attr => renderField(attr.id.toString(), attr.name))}
                    </Collapse>
                    <div className="attributeButtonWrapper">
                        {renderExpandButton()}
                        <AddAttributeButton />
                    </div>


                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={storeLeadMutation.isLoading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={storeLeadMutation.isLoading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                onClick={(e) => {
                                }}
                            >
                                {buttonTitle}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default LeadSaveModal
