import React, {useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChangePassword from './ChangePassword';
import PersonalInformation from './PersonalInformation';
import SubscriptionPlan from './SubscriptionPlan';
import ProfileDetails from './ProfileDetails';
import {PrivateRoute} from '../../components/routes';
import {Link, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Transactions from './Transactions';
import Team from './team/Team';
import {useAuth} from '../../Contexts/AuthProvider';
import profileTabIcon from '../../assets/img-new/profileTabIcon.svg';
import tabSecurityIcon from '../../assets/img-new/tabSecurityIcon.svg';
import tabSubscriptionsIcon from '../../assets/img-new/tabSubscriptionsIcon.svg';

const Profile = () => {
    const [value, setValue] = useState(0)
    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const { user } = useAuth();
    const location = useLocation()

    const renderLink = (to, title, description, img, hide = false) => {
        if (hide) return null;
        return (
                <Link to={to} className="pnItemContent">
                    <div className="profileItemContent">
                        <div>
                            <img src={img} alt=" " />
                        </div>
                        <div className="profileItemContent profileItemContent_title_description">
                            <div className="profileItemContent profileItemContent_title_description title">{title}</div>
                            <div className="profileItemContent profileItemContent_title_description description">{description}</div>
                        </div>
                    </div>
                </Link>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const hide = user?.isTeamOperator() || !user?.canManageTeam()

    return (
        <div className="profilePageHolder">
            <div className="navPart">
                <div className="navPartContent">
                    <ProfileDetails handleChange={handleChange} />
                    <div className="profileNavHolder">
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label={renderLink(url, t('New Profile.Profile'), t('New Profile.Change Personal Information'), profileTabIcon)}/>
                            <Tab label={renderLink(`${url}/security`, t('New Profile.Security'), t('New Profile.Change Password'), tabSecurityIcon)}/>
                            <Tab label={renderLink(`${url}/subscriptions`, t('New Profile.Subscriptions'), t('New Profile.Billing, Plans, Limits'), tabSubscriptionsIcon)}/>
                            <Tab label={renderLink(`${url}/transactions`, t('New Profile.Billing'), t('New Profile.Billing Flow'), tabSecurityIcon)}/>
                            <Tab label={renderLink(`${url}/team`, t('Team.Team'), t('Team.Team Management'),tabSubscriptionsIcon)}/>
                        </Tabs>
                        {/* <ul className="profileNav">
                            {renderLink(url, t('New Profile.Profile'), t('New Profile.Change Personal Information'))}
                            {renderLink(`${url}/security`, t('New Profile.Security'), t('New Profile.Change Password'))}
                            {renderLink(`${url}/subscriptions`, t('New Profile.Subscriptions'), t('New Profile.Billing, Plans, Limits'))}
                            {renderLink(`${url}/transactions`, t('New Profile.Billing'), t('New Profile.Billing Flow'))}
                            {renderLink(`${url}/team`, t('Team.Team'), t('Team.Team Management'), hide)}
                        </ul> */}
                    </div>
                </div>
            </div>
            <div className="contentPart">
                <div className="contentPartContent">
                    <Switch>
                        <PrivateRoute path={`${path}`} component={PersonalInformation} exact />
                        <PrivateRoute path={`${path}/security`} component={ChangePassword} />
                        <PrivateRoute path={`${path}/subscriptions`} component={SubscriptionPlan} />
                        <PrivateRoute path={`${path}/transactions`} component={Transactions} />
                        <PrivateRoute path={`${path}/team`} component={Team} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default Profile;

