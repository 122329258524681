import React, {Fragment, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DataService from '../../Services/DataService'
import {useParams} from 'react-router-dom'
import {useAppDispatch} from '../../Contexts/AppContext'
import {useTranslation} from "react-i18next";

const LeadsExportModal = props => {
    const { t } = useTranslation();
    const {open, handleClose, visibleColumns, search, sorting} = props
    const [name, setName] = useState('')
    const {segmentId, subsegmentId} = useParams()
    const {addExportFile} = useAppDispatch()

    const handleExport = async () => {

        const segId = subsegmentId || segmentId

        const requestData = {
            segmentId: segId,
            attributes: visibleColumns,
            name,
            search: search || ''
        }

        if (sorting && sorting[0]) {
            const sortingFirst = sorting[0]
            if (sortingFirst.key && sortingFirst.direction) {
                requestData.order = `${sortingFirst.key},${sortingFirst.direction}`
            }
        }

        let response = await DataService.exportToCSV(requestData)
        addExportFile(response.data)
        setName('')
        handleClose()
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{t('Modals.Export as CSV')}</h2>
                    </div>
                    <TextField
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="name"
                        label={t('Modals.Name')}
                        variant="outlined"
                        margin='normal'
                    />
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={!name}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleExport}
                            >
                                {t('Modals.Export')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default LeadsExportModal
