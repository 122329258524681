import React, {Fragment, useEffect, useRef} from 'react'
import {useForm} from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {useMutation, useQueryClient} from 'react-query'
import {storeAlphaName} from '../../api'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const SaveAlphaNameModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
    } = props

    const queryClient = useQueryClient()

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onTouched',
    })


    const nameRef = useRef()

    const storeMutation = useMutation(storeAlphaName)

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error)
        }
    }, [storeMutation.error])


    const onSubmit = handleSubmit(async data => {
        try {
            await storeMutation.mutateAsync(data)
            reset()
            handleClose()
            queryClient.invalidateQueries('alphaNames').then()
            queryClient.invalidateQueries('activeAlphaNames').then()
        } catch (e) {
            console.log(e)
        }

    })

    const title = t('Modals.Add Alpha Name')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                onEntered={() => {
                    nameRef.current.focus()
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                nameRef.current = e
                            }}
                            name="alpha_name"
                            label={t("Modals.Alpha Name")}
                            variant="outlined"
                            margin='normal'
                        />
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {title}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveAlphaNameModal
