import React, {useEffect} from 'react';
import FormPersonalInformation from '../../components/Formik/form/PersonalInformation.form';
import {useAuth} from '../../Contexts/AuthProvider';
import AlertToastr from '../../Utils/alert';
import {useMutation} from 'react-query';
import {updateUserInfo} from '../../api';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../Contexts/AppContext';

const PersonalInformation = () => {
    const { t } = useTranslation();
    const { user, userName, userPhone, userId, setUserName, setUserPhone } = useAuth();
    const updateUserInfoMutation = useMutation(updateUserInfo);
    const { setBreadcrumbs, setContextMenu } = useAppDispatch();

    useEffect(() => {
        setBreadcrumbs([{label: t('New Profile.Profile')}])
        setContextMenu([]);
    }, [setBreadcrumbs, setContextMenu, t]);

    const handleOnSubmit = async (values, resetForm) => {
        try {
            const response = await updateUserInfoMutation.mutateAsync({
                id: userId, ...values,
            });
            setUserName(response.data.name);
            setUserPhone(response.data.phone);
            resetForm();
            AlertToastr.showAlert(t('New Profile.ProfilePage.Success updated User Personal Information'));
        } catch (e) {
            AlertToastr.showErrorAlert(e.response.data.message);
        }
    };

    return (
        <>
            <div className="contentPartTitleHolder">
                <div className="contentPartTitle">{t('New Profile.Profile')}</div>
            </div>
            <FormPersonalInformation
                onSubmit={handleOnSubmit}
                initialValues={{
                    name: userName,
                    phone: userPhone,
                    email: user ? user.email : ''
                }} />
            {/*<div className="cpSubTitle">Personal photo</div>*/}
            {/*<div className="text mb30">*/}
            {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.*/}
            {/*</div>*/}
            {/*<div className="avaFormHolder mb30">*/}
            {/*    <div className="imgHolder">*/}
            {/*        <img src={Ava1} alt=""/>*/}
            {/*    </div>*/}
            {/*    <div className="btnHolder">*/}
            {/*        <Button*/}
            {/*            color="primary"*/}
            {/*            variant="contained"*/}
            {/*        >*/}
            {/*            Upload image*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default PersonalInformation;
