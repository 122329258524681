import React from 'react'
import {statusClassMap} from '../helpers'
import {useTranslation} from "react-i18next";

const LeadStatus = ({ status }) => {
    const { t } = useTranslation();

    const statusMap = statusClassMap()

    return (
        <div className={`chipLike ${statusMap[status]}`}>
            <span className='chipLikeLabel'>{t(`LeadStatuses.${status}`)}</span>
        </div>
    )
}

export default LeadStatus
