import React, {Fragment, useEffect, useState} from 'react'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import EditIcon from '../../assets/img-new/edit.svg'
import CopyIcon from '../../assets/img/copy.svg'
import LeadsExportModal from '../../components/modals/LeadsExportModal'
import SaveSegmentModal from '../../components/modals/SaveSegmentModal'
import {useHistory} from 'react-router-dom'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import CreateSegmentIco from '../../assets/img/layers_alt.svg'
import Delete from '../../assets/img-new/delete.svg'
import {useQueryClient} from 'react-query'
import {useTranslation} from "react-i18next";

const SubSegmentsPageActions = ({segment, loading, selectedRows, visibleColumns, search, sorting}) => {
    const { t } = useTranslation();
    const {exportFiles} = useAppState()
    const {setContextMenu, setContextLinks} = useAppDispatch()
    const history = useHistory()
    const [showExportModal, setShowExportModal] = useState(false)
    const [showSaveSegmentModal, setShowSaveSegmentModal] = useState(false)
    const [showUpdateSegmentModal, setShowUpdateSegmentModal] = useState(false)
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteSegmentsModal, setShowDeleteSegmentsModal] = useState(false)
    const queryClient = useQueryClient()

    useEffect(() => {
        if (!segment) return

        if (exportFiles && exportFiles[segment.id]) {
            const fileData = exportFiles[segment.id]
            const enabled = fileData.type === 'export-completed'
            setContextLinks([
                {
                    label: enabled ? t('segment leads page actions.Download') : t('segment leads page actions.Generating file'),
                    url: fileData.url,
                    disabled: !enabled
                }
            ])
        }
    }, [segment, exportFiles, setContextLinks, t])

    useEffect(() => {

        const items = [
            {
                label: t('sub segments page actions.Edit Audience'),
                icon: EditIcon,
                action: () => EventEmitter.emit('SegmentEdit'),
                disabled: loading
            },
            {
                label: t('sub segments page actions.Duplicate Audience'),
                icon: CopyIcon,
                action: () => EventEmitter.emit('SegmentDuplicate'),
                disabled: loading
            },
            {
                label: t('sub segments page actions.Delete Audience'),
                icon: Delete,
                action: () => EventEmitter.emit('SegmentDelete'),
                disabled: loading
            },
            {
                label: t('sub segments page actions.Create Segment'),
                icon: CreateSegmentIco,
                action: () => EventEmitter.emit('SegmentCreate'),
                disabled: loading
            }
        ]
        if (selectedRows.length) {
            items.push(                {
                label: t('sub segments page actions.Delete Selection'),
                icon: Delete,
                action: () => EventEmitter.emit('SegmentBulkDeletion')
            })
        }
        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows, t])


    useEffect(() => {
        const onEvent = () => setShowExportModal(true)

        const listener = EventEmitter.addListener('SegmentExport', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowUpdateSegmentModal(true)

        const listener = EventEmitter.addListener('SegmentEdit', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDuplicateModal(true)

        const listener = EventEmitter.addListener('SegmentDuplicate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('SegmentDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveSegmentModal(true)

        const listener = EventEmitter.addListener('SegmentCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteSegmentsModal(true)

        const listener = EventEmitter.addListener('SegmentBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    const deleteSegment = () => {
        setShowDeleteModal(false)
        DataService.deleteSegment(segment.id)
            .then(response => {
                history.push(`/`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const deleteItems = () => {
        setShowDeleteSegmentsModal(false)
        DataService.deleteSegments(selectedRows)
            .then(() => {
                queryClient.invalidateQueries('subsegmentsList').then()
                queryClient.invalidateQueries('segmentView').then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    return (
        <Fragment>
            <LeadsExportModal
                search={search}
                sorting={sorting}
                visibleColumns={visibleColumns}
                open={showExportModal}
                handleClose={() => setShowExportModal(false)}
            />
            <SaveSegmentModal
                open={showSaveSegmentModal}
                handleClose={() => setShowSaveSegmentModal(false)}
                parentSegment={segment}
                isDuplicateSegmentMode={false}
                entityTitle={t('segments page.create segment')}
            />
            <SaveSegmentModal
                open={showUpdateSegmentModal}
                handleClose={() => setShowUpdateSegmentModal(false)}
                segment={segment}
                isDuplicateSegmentMode={false}
                entityTitle={t('segments page.edit segment')}
            />
            <SaveSegmentModal
                open={showDuplicateModal}
                handleClose={() => setShowDuplicateModal(false)}
                segment={segment}
                isDuplicateSegmentMode={true}
                onDuplicated={newSegment => {
                    setShowDuplicateModal(false)
                    history.push(`/audiences/${newSegment.id}`)
                }}
                entityTitle={t('segments page.duplicate segment')}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteSegment}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='audience'/>

            <DeleteModal
                open={showDeleteSegmentsModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='selected segments'/>
        </Fragment>
    )
}

export default SubSegmentsPageActions
