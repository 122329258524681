import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Folder from "../../assets/img/folder.svg";
import File from "../../assets/img/file_blank.svg";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useMutation} from 'react-query'
import ReactDropzone from "react-dropzone";
import DataService from "../../Services/DataService";
import {connect} from "react-redux";
import AlertToastr from "../../Utils/alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setNewImport} from "../../Store/modules/sources/actions";
import {useTranslation} from "react-i18next";
import {updateImportFileBlackList} from '../../api';
import Switch from '@material-ui/core/Switch';
import InformationIcon from '../../assets/img-new/Informationicon.svg';
// import IOSSwitch from '../IOSSwitch'
// import { useMutation } from 'react-query'
// import { updateImportFileBlackList } from '../../api'
const ignoreOption = {
    id: 'ignore',
    name: 'Ignore'
}

const newAttrOption = {
    id: 'new',
    name: 'New'
}

const ImportFileModal = ({ open, handleClose, source, list, setNewImport }) => {
    const [openMatchModal, setOpenMatchModal] = useState(false);
    const [selectedSource, setSelectedSource] = useState("");
    const [availableLists, setAvailableLists] = useState([]);
    const [availableSources, setAvailableSources] = useState([]);
    const [selectedList, setSelectedList] = useState("");
    const [isDropdownListMode, setIsDropdownListMode] = useState(true);
    const [isDropdownSourceMode, setIsDropdownSourceMode] = useState(true);
    const [newListName, setNewListName] = useState("");
    const [newSourceName, setNewSourceName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [importInfo, setImportInfo] = useState(null);
    const [mappingInfo, setMappingInfo] = useState(null);
    const [filename, setFileName] = useState("");
    const [blackList, setBlackList] = useState(false);
    const [uploadClicked, setUploadClicked] = useState(false);
    const [valueTabs, setValueTabs] = useState(0);
    const [attributeList, setAttributeList] = useState([])
    const [importModelList, setImportModelList] = useState([])
    const [attributesCount, setAttributesCount] = useState(0)
    const [importProgressOpen, setImportProgressOpen] = useState(false)
    const [isImportStarted, setIsImportStarted] = useState(false)
    const [checkMobileStatus, setCheckMobileStatus] = useState(false)
    const { t } = useTranslation();
    const updateImportFileBlackListMutation = useMutation(updateImportFileBlackList)
    // const updateImportFileBlackListMutation = useMutation(updateImportFileBlackList)
    useEffect(() => {
        async function fetchAttributes() {
            let response = await DataService.getLeadsAttributes()
            if (!response || !response.data || !response.data.data)
                return
            const defaultAttributes = [ignoreOption, newAttrOption]
            const attributes = defaultAttributes.concat(response.data.data.map((attr) => ({
                id: attr.id,
                name: attr.name
            })))
            setAttributesCount(response.data.data.length)
            if (!importInfo || !importInfo.attributes)
                return
            let sortedAttributes = [...importInfo.attributes]
            sortedAttributes.sort((a, b) => {
                if (a.col_position < b.col_position) {
                    return -1
                }
                if (a.col_position > b.col_position) {
                    return 1
                }
                return 0
            })
            const model = sortedAttributes.map((attr) => {
                const mappingAttr = mappingInfo.find(mapInfo => mapInfo.attribute_id === attr.id)
                let boundAttr = null
                if (mappingAttr)
                    boundAttr = attributes.find(attribute => attribute.id === mappingAttr.lead_attrbiute.id)
                return {
                    fileHeader: attr,
                    boundAttribute: boundAttr ? boundAttr : ignoreOption,
                    newAttributeName: attr.name
                }
            })
            setImportModelList(model)
            setAttributeList(attributes)
        }

        if (importInfo && mappingInfo)
            fetchAttributes().then()
    }, [importInfo, mappingInfo])

    const handleImport = async () => {
        setIsImportStarted(true)
        const newAttributesList = []
        let syncAttrList = []
        let position = attributesCount
        importModelList.forEach((importModel) => {
            if (importModel.boundAttribute.id === 'ignore') {
                return
            }
            const attribute = attributeList.find(a => a.name === importModel.newAttributeName)
            if (importModel.boundAttribute.id === 'new' && !attribute) {
                newAttributesList.push({sort_order: position, name: importModel.newAttributeName})
                position = position + 1
                syncAttrList.push({
                    processing_document_attribute_id: importModel.fileHeader.id,
                    lead_attribute_id: null,
                    leadName: importModel.newAttributeName
                })
            } else {
                syncAttrList.push({
                    processing_document_attribute_id: importModel.fileHeader.id,
                    lead_attribute_id: importModel.boundAttribute.id === 'new' ? attribute.id : importModel.boundAttribute.id
                })
            }
        })
        try {
            if (newAttributesList.length > 0) {
                let response = await DataService.createLeadsAttributes(newAttributesList)
                syncAttrList = syncAttrList.map((attr) => {
                    if (!attr.lead_attribute_id) {
                        const id = response.data.data.find(x => x.name === attr.leadName).id
                        return {
                            processing_document_attribute_id: attr.processing_document_attribute_id,
                            lead_attribute_id: id
                        }
                    } else {
                        return attr
                    }
                })
            }
            DataService.syncImportAttributes(importInfo.id, syncAttrList, checkMobileStatus).then(res => {
                DataService.runImport(importInfo.id).then(response => {
                    setNewImport({
                        fileId: importInfo.id,
                        status: 'Running',
                        filename: filename,
                        parsedLeadsCount: 0
                    })
                    AlertToastr.showAlert(`File ${filename} import started!`)
                    closeModal()
                })
                    .catch((error) => {
                        setIsImportStarted(false)
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    })
            }).catch((error) => {
                setIsImportStarted(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })

        } catch (error) {
            setIsImportStarted(false)
            if (error && error.response && error.response.data && error.response.data.errors)
                AlertToastr.showErrorAlert(error.response.data.errors)
        }
        setImportModelList([])
        setIsImportStarted(false)
    }

    // const onClose = () => {
    //     const model = importModelList.map((attr) => {
    //         const mappingAttr = mappingInfo.find(mapInfo => mapInfo.attribute_id === attr.fileHeader.id)
    //         let boundAttr = null
    //         if (mappingAttr)
    //             boundAttr = attributeList.find(attribute => attribute.id === mappingAttr.lead_attrbiute.id)
    //         return {
    //             ...attr,
    //             boundAttribute: boundAttr ? boundAttr : ignoreOption
    //         }
    //     })
    //     setImportModelList(model)
    //     setIsImportStarted(false)
    //     handleBack()
    // }

    const closeModal = () => {
        setImportModelList([])
        setIsImportStarted(false)
        handleClose()
    }

    const changeBlackListStatus = async () => {
        setBlackList(s => !s)
        if (importInfo) {
            await updateImportFileBlackListMutation.mutateAsync({
                id: importInfo.id,
                black_list: !blackList
            })
        }
    }
    const isListOrSourceEmpty = useMemo(() => {
        return (!selectedSource && isDropdownSourceMode) ||
            (!newSourceName && !isDropdownSourceMode) ||
            (!selectedList && isDropdownListMode) ||
            (!newListName && !isDropdownListMode);
    }, [
        selectedSource,
        isDropdownSourceMode,
        newSourceName,
        selectedList,
        isDropdownListMode,
        newListName,
    ]);
    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
      };
    const onSourceChanged = useCallback(
        (source) => {
            if (!source || !source.id) {
                setIsDropdownListMode(false);
                return;
            }
            setSelectedSource(source);
            setIsDropdownListMode(true);
            setAvailableLists(source.lists);
            if (list) {
                setSelectedList(source.lists.find((l) => l.id === list.id));
                return;
            }
            if (source && source.lists[0]) {
                setSelectedList(source.lists[0]);
            } else setIsDropdownListMode(false);
        },
        [list]
    );

    useEffect(() => {
        async function fetchSources() {
            let response = await DataService.getSourcesWithList();
            if (!response || !response.data) return;
            if (response.data.data) setAvailableSources(response.data.data);
            if (source) {
                onSourceChanged(response.data.data.find((s) => s.id === source.id));
                return;
            }
            if (!response.data.data || !response.data.data[0]) {
                setIsDropdownListMode(false);
                setIsDropdownSourceMode(false);
                return;
            } else {
                setIsDropdownSourceMode(true);
                onSourceChanged(response.data.data[0]);
                return;
            }
        }

        if (!open) {
            return;
        }
        if (isUploading) return;
        fetchSources().then();
    }, [source, open, isUploading, onSourceChanged]);

    const handleClickOpenMatchModal = () => {
        setOpenMatchModal(true);
    };

    const onDrop = async (files) => {
        if (isListOrSourceEmpty) {
            setUploadClicked(true);
            AlertToastr.showErrorAlert(t("Modals.Please, select a Source first"));
            return;
        }
        setIsUploading(true);
        setFileName(files[0].name);
        let currentList = selectedList;
        let currentSource = selectedSource;
        if (!isDropdownSourceMode) {
            try {
                let response = await DataService.createSource(newSourceName);
                currentSource = response.data.data;
                setSelectedSource(response.data.data);
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error);
            }
        }
        if (!isDropdownListMode) {
            try {
                let response = await DataService.createList(
                    currentSource.id,
                    newListName,
                    "active"
                );
                currentList = response.data.data;
                setSelectedList(response.data.data);
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error);
            }
        }
        DataService.uploadFile(currentList.id, files[0], blackList)
            .then((response) => {
                setImportInfo(response.data.data);
                setMappingInfo(response.data.mapping);

                setIsFileUploaded(true);
            })
            .catch((error) => {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                )
                    AlertToastr.showErrorAlert(error.response.data.errors);
            });
    };

    const onClose = () => {
        setIsUploading(false);
        setIsDropdownListMode(true);
        setIsDropdownSourceMode(true);
        setFileName("");
        setImportModelList([])
        setIsFileUploaded(false);
        setImportInfo(null);
        setMappingInfo(null);
        setNewListName("");
        setNewSourceName("");
        setBlackList(false);
        setValueTabs(0);
        handleClose();
    };

    // const changeBlackListStatus = async () => {
    //     setBlackList(s => !s)
    //     if (importInfo) {
    //         await updateImportFileBlackListMutation.mutateAsync({
    //             id: importInfo.id,
    //             black_list: !blackList
    //         })
    //     }
    // }

    return (
        <Fragment>
            <Dialog open={open} onClose={onClose} className="modal hasWizard">
                <div className="indicatorHolder">
                <div
                    className={
                    valueTabs >= 0 ? "indicatorItem active" : "indicatorItem"
                    }
                />
                <div
                    className={
                    valueTabs >= 1 ? "indicatorItem active" : "indicatorItem"
                    }
                />
                </div>
                <div className="modalBody modalOverflow">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{t("Modals.Import File")}</h2>
                    </div>
                    <div className="wizardStepsHolder">
                        <Tabs value={valueTabs} onChange={handleChangeTabs}>
                            <Tab label="Step 1" className="MuiTab-root" />
                            <Tab label="Step 2" className="MuiTab-root" />
                        </Tabs>
                    </div>
                    <form noValidate autoComplete="off">
                        {/*<div className="align-center wrapper content-justified mt15">*/}
                        {/*    <div style={{fontWeight: '600', fontSize: '14px'}}>Add to black list</div>*/}
                        {/*    <IOSSwitch*/}
                        {/*        disabled={updateImportFileBlackListMutation.isLoading}*/}
                        {/*        color="primary"*/}
                        {/*        checked={blackList}*/}
                        {/*        onChange={changeBlackListStatus}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div style={valueTabs !== 0 ? { display: "none" } : {}}>
                        {isDropdownSourceMode && (
                            <TextField
                                value={selectedSource}
                                onChange={(e) => onSourceChanged(e.target.value)}
                                disabled={isUploading}
                                id="source"
                                label={t("Modals.Source")}
                                variant="outlined"
                                margin="dense"
                                className="mb0"
                                select
                            >
                                {availableSources?.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {!isDropdownSourceMode && (
                            <TextField
                                value={newSourceName}
                                onChange={(e) => setNewSourceName(e.target.value)}
                                disabled={isUploading}
                                error={
                                    ((!selectedSource && isDropdownSourceMode) ||
                                        (!newSourceName && !isDropdownSourceMode)) &&
                                    uploadClicked
                                }
                                id="newSourceName"
                                label={t("Modals.Source")}
                                variant="outlined"
                                margin="dense"
                                className="mb0"
                            />
                        )}
                        {availableSources && availableSources.length > 0 && !isUploading && (
                            <span
                                onClick={(e) => {
                                    setIsDropdownSourceMode(!isDropdownSourceMode);
                                    setIsDropdownListMode(!isDropdownSourceMode);
                                }}
                                className="linkPrimary text-mute text-bold cursor-pointer"
                            >
                                {isDropdownSourceMode
                                    ? t("Modals.Create new source")
                                    : t("Modals.Select existing source")}
                            </span>
                        )}
                        {isDropdownListMode && (
                            <TextField
                                value={selectedList}
                                onChange={(e) => setSelectedList(e.target.value)}
                                disabled={isUploading}
                                id="list"
                                label={t("Modals.List")}
                                variant="outlined"
                                margin="normal"
                                className="mb0"
                                select
                            >
                                {availableLists.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {!isDropdownListMode && (
                            <TextField
                                value={newListName}
                                onChange={(e) => setNewListName(e.target.value)}
                                disabled={isUploading}
                                id="newListName"
                                error={
                                    ((!selectedList && isDropdownListMode) ||
                                        (!newListName && !isDropdownListMode)) &&
                                    uploadClicked
                                }
                                label={t("Modals.List")}
                                variant="outlined"
                                margin="normal"
                                className="mb0"
                            />
                        )}
                        <div className="linkHolder mb10">
                            {availableLists.length > 0 &&
                                isDropdownSourceMode &&
                                !isUploading && (
                                    <span
                                        onClick={(e) => setIsDropdownListMode(!isDropdownListMode)}
                                        className="linkPrimary text-mute text-bold cursor-pointer"
                                    >
                                        {isDropdownListMode
                                            ? t("Modals.Create new list")
                                            : t("Modals.Select existing list")}
                                    </span>
                                )}
                        </div>
                        {!isUploading && (<div className="fs14">{t("Modals.Add a file to download")}</div>)}
                        {!isUploading && (
                            <Fragment>
                                <div className="fileRequirements">
                                    <img src={InformationIcon} alt=" " style={{width: '20px', height: '20px'}}/>
                                    <p className="textPlaceholderColor fs14">
                                        {t("Modals.File requirements")}
                                    </p>
                                </div>
                                <ReactDropzone accept=".csv, .xml, .xlsx, .tsv" onDrop={onDrop}>
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragReject,
                                        rejectedFiles,
                                    }) => {
                                        return (
                                            <div
                                                className="fileDropHolder"
                                                {...getRootProps({
                                                    onClick: (e) => {
                                                        if (isListOrSourceEmpty) {
                                                            setUploadClicked(true);
                                                            AlertToastr.showErrorAlert(
                                                                t("Modals.Please, select a Source first")
                                                            );
                                                            e.stopPropagation();
                                                        }
                                                    },
                                                })}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="icoHolder mb15">
                                                    <img src={Folder} alt="" />
                                                </div>
                                                <div className="captionHolder text-center text-light text-mute">
                                                    {t("Modals.Drag and drop, or click to use the browser")} <span className="textPrimary">{t("Modals.Select a file")}</span>
                                                </div>
                                                    <div className="captionHolder text-center text-light text-mute textPlaceholderColor">
                                                        {t("Modals.XLSX CSV supported")}
                                                    </div>
                                            </div>
                                        );
                                    }}
                                </ReactDropzone>
                            </Fragment>
                        )}
                        {isUploading && !isFileUploaded && (
                            <div className="loaderHolder text-center">
                                <CircularProgress />
                            </div>
                        )}
                        {isFileUploaded && (
                            <div className="fileProgressHolder mt10">
                                <ul className="fileProgress">
                                    <li className="fileProgressItem">
                                        <div className="icoHolder">
                                            <img src={File} alt="" />
                                        </div>
                                        <div className="progress">
                                            <div className="progressStatus">
                                                <div className="name text-mute">
                                                    {filename}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                        </div>
                        <div style={valueTabs !== 1 ? { display: "none" } : {}}>
                        <div className="modalTitleHolder mb30">
                    </div>
                    <form noValidate autoComplete="off">
                        <ul className="fileList mt-15">
                            <li className="fileListItem">
                                <div className="icoHolder">
                                    <img src={File} alt=""/>
                                </div>
                                <div className="captionHolder">
                                    {/* <div className="name text-mute text-bold">File uploaded</div> */}
                                    <div className="qnt qnt text-light text-bold">{filename}</div>
                                    {/* <div className="qnt qnt text-light text-mute">380 items</div> */}
                                </div>
                            </li>
                        </ul>
                        <div className="align-center wrapper content-justified">
                            <div className="fs14">{t('Modals.Lookup phone numbers')}</div>

                            <Switch
                                color="primary"
                                checked={!!checkMobileStatus}
                                onChange={() => {
                                    setCheckMobileStatus(s => !s)
                                }}
                            />
                        </div>

                        <div className="align-center wrapper content-justified">
                            <div className="fs14">{t('Modals.Add to black list')}</div>
                            <Switch
                                disabled={updateImportFileBlackListMutation.isLoading}
                                color="primary"
                                checked={blackList}
                                onChange={changeBlackListStatus}
                            />
                        </div>
                        <div className="divider mt10 mb10"/>
                        <div className="formatTableHolder">
                            <table className="formatTable">
                                <thead>
                                {/* <tr><div className="importInfoText">{t('Modals.Review and customize the fields')}</div></tr> */}
                                <tr>
                                    <th>{t('Modals.CSV Headings')}</th>
                                    <th>{t('Modals.Fields')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {importModelList.map((attribute) => {
                                    return (
                                        <tr key={attribute.fileHeader.id}>
                                            <td>{attribute.fileHeader.name}</td>
                                            <td>
                                                {attribute.boundAttribute.id !== 'new' &&
                                                <TextField
                                                    disabled={isImportStarted}
                                                    value={attribute.boundAttribute}
                                                    onChange={(e) => setImportModelList(models => {
                                                        models.find(value => value.fileHeader.id === attribute.fileHeader.id).boundAttribute = e.target.value
                                                        return [...models]
                                                    })}
                                                    id="field"
                                                    variant="outlined"
                                                    select
                                                    SelectProps={{
                                                        MenuProps: {
                                                          style: { height: '400px'},
                                                          MenuListProps: {
                                                          },
                                                          anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                          },
                                                          transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                          },
                                                          getContentAnchorEl: null
                                                        }
                                                      }}
                                                >
                                                    {attributeList.map((option) => {
                                                        return <MenuItem className="TextFieldMenuItem"  key={option.id} value={option}>
                                                            {option.name}
                                                        </MenuItem>
                                                    })}
                                                </TextField>}
                                                {attribute.boundAttribute.id === 'new' &&
                                                <TextField
                                                    disabled={isImportStarted}
                                                    value={attribute.newAttributeName}
                                                    onChange={(e) => setImportModelList(models => {
                                                        models.find(value => value.fileHeader.id === attribute.fileHeader.id).newAttributeName = e.target.value
                                                        return [...models]
                                                    })}
                                                    id="field"
                                                    variant="outlined"
                                                />}
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                        {/* {isImportStarted &&
                            <div className="loaderHolder text-center">
                                <CircularProgress />
                            </div>
                        } */}
                        {/* <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={isImportStarted}
                                    onClick={closeModal}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={isImportStarted}
                                    onClick={handleImport}
                                >
                                    {t('Modals.Import')}
                                </Button>
                            </div>
                        </div> */}
                    </form>
                        </div>

                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    className="cancelModal"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={!isFileUploaded}
                                    onClick={onClose}
                                >
                                    {t("Modals.Cancel")}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                className="continueModal"
                                id="continueBtn"
                                disabled={!isFileUploaded}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={(e) => {
                                    if (valueTabs < 1) {
                                    setValueTabs(valueTabs + 1);
                                    } else {
                                        handleImport()
                                    }
                                }}
                                >
                                {valueTabs < 1 ? "Continue" : "Import"}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* <MatchFormatModal
                open={openMatchModal}
                importInfo={importInfo}
                mappingInfo={mappingInfo}
                handleClose={(e) => {
                    setOpenMatchModal(false);
                    onClose();
                }}
                handleBack={(e) => {
                    setOpenMatchModal(false);
                }}
                filename={filename}
                setNewImport={setNewImport}
            /> */}
        </Fragment>
    );
};

const mapDispatchToProps = {
    setNewImport,
};

export default connect(null, mapDispatchToProps)(ImportFileModal);
