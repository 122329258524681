import React from 'react'
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import {useCampaignState} from '../../Contexts/CampaignContext'
import CampaignLogsBlock from '../../components/CampaignLogsBlock'
import {useQueryClient} from 'react-query'
import {useTranslation} from "react-i18next";
// import { useAppDispatch } from '../../Contexts/AppContext'

const CampaignLogs = () => {
    const { t } = useTranslation();

    // const {setBreadcrumbs, setSection} = useAppDispatch()
    const {logs, campaignLogsRequest} = useCampaignState()
    const queryClient = useQueryClient()

    // useEffect(() => {
    //     setBreadcrumbs([{label: 'Settings'}])
    //     setSection('settings')
    // }, [setBreadcrumbs, setSection])

    return (
        <div className='campaignsPageHolder'>
            <div className="timeLine">
                <div className="timeLineHeading mb15">
                    <h2>{t("Campaigns.Logs")}</h2>
                    <Button
                        disabled={campaignLogsRequest.isFetching}
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon/>}
                        size='small'
                        onClick={() => queryClient.invalidateQueries('campaignLogsView')}
                    >
                        {t("Campaigns.Refresh")}
                    </Button>
                </div>
                <CampaignLogsBlock logInfo={logs} />
            </div>
        </div>
    )
}

export default CampaignLogs
