import React from 'react'
import {Redirect, Switch, useRouteMatch} from 'react-router-dom'
import {PrivateRoute} from '../../components/routes'
import SegmentLeadsPage from './SegmentLeadsPage'
import SubSegmentsPage from './SubSegmentsPage'
import SubsSegmentViewPage from './SubsSegmentViewPage'

const SegmentPage = () => {

    const {path} = useRouteMatch()

    return (
        <div className="pageHolder">
            <Switch>
                <PrivateRoute path={`${path}/leads`} component={SegmentLeadsPage} exact />
                <PrivateRoute path={`${path}/segments`} component={SubSegmentsPage} exact />
                <PrivateRoute path={`${path}/segments/:subsegmentId/leads`} component={SubsSegmentViewPage} exact />
                <Redirect from={`${path}/segments/:subsegmentId`} to={`${path}/segments/:subsegmentId/leads`} exact />
                <Redirect from={path} to={`${path}/leads`} exact/>
            </Switch>
        </div>
    )
}

export default SegmentPage
