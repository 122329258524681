import React, {Fragment} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from '@material-ui/core/Tooltip';

const ContextMenuItem = ({item}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose =  () => {
    setAnchorEl(null)
  }

  return !item.items ? (
    <Tooltip title={item.label} placement="bottom" arrow enterDelay={0}>
      <div
        className="menuListItem"
        onClick={!item.disabled ? item.action : () => {}}
        style={item.disabled ? {opacity: '.3', background: '#6d7ed4'} : {}}
      >
        <img
          src={item.icon}
          className="ico"
          alt={item.label}
          style={item.iconStyle || {}}
        />
        {/*<span className="caption">{item.label}</span>*/}
      </div>
    </Tooltip>
  ) : (
    <Fragment>
      <Tooltip title={item.label} placement="bottom" arrow enterDelay={0}>
        <div
          className="menuListItem"
          onClick={!item.disabled ? handleClick : () => {}}
          style={item.disabled ? {opacity: '.3', background: '#6d7ed4'} : {}}
        >
          <img
            src={item.icon}
            className="ico"
            alt={item.label}
            style={item.iconStyle || {}}
          />
          {/*<span className="caption">{item.label}</span>*/}
        </div>
      </Tooltip>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          item.items.map(menuItem => {
            return (
              <MenuItem
                key={menuItem.label}
                onClick={() => {
                  if (menuItem.action) {
                    menuItem.action()
                  }
                  setAnchorEl(null)
                }}
              >
                {menuItem.label}
              </MenuItem>
            )
          })
        }

      </Menu>
    </Fragment>

  )
}

export default ContextMenuItem