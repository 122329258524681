import React, {Fragment} from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const ConfirmModal = (props) => {
    const { t } = useTranslation();
    const {
        open,
        handleConfirm,
        handleClose,
        loading = false,
        approveTitle = t('Modals.Yes'),
        message = ''
    } = props

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{message}</h2>
                    </div>
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleConfirm}
                            >
                                {approveTitle}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default ConfirmModal
