import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const useStyles2 = makeStyles({
    selectDropdown: { color: "grey" },
    menuItem: {
      "&:hover": {
        backgroundColor: "#3b3f58"
      }
    }
  });

const ListPagination = props => {
    const { t } = useTranslation();
    const classes = useStyles2()
    const {
        pagination,
        limit,
        onChange,
        onLimitChange
    } = props

    const {current_page, total} = pagination

    return (
        <TablePagination
            rowsPerPageOptions={[15, 50, 100]}
            component="div"
            className="paginationMenuItem"
            count={total}
            page={current_page-1}
            labelRowsPerPage={t('Rows per page')}
            SelectProps={{
                inputProps: { 'aria-label': t('Rows per page') },
                MenuProps: { classes: { paper: classes.selectDropdown } }
            }}
            opened={true}
            onChangePage={(event, page) => {
                onChange(page+1)
            }}
            rowsPerPage={limit}
            onChangeRowsPerPage={event => {
                onLimitChange(parseInt(event.target.value))
            }}
            style={{paddingRight: '30px'}}
        />
    )
}

export default ListPagination
