import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppState} from '../../Contexts/AppContext';
import PlusCircle from '../../assets/img/plus_circle.svg';
import EventEmitter from '../../Utils/EventEmitter';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getAttributes, getSMSTemplates, removeSMSTemplate} from '../../api';
import SaveSMSTemplateModal from '../../components/modals/SaveSMSTemplateModal';
import ListTable from '../../components/ListTable/ListTable';
import DeleteModal from '../../components/modals/DeleteModal';
import ShortUrlDomainItemContextMenu from '../../components/ShortUrlDomainItemContextMenu';


const SmsTemplates = () => {
    const { t } = useTranslation();
    const { config, configIsLoading } = useAppState();
    const { setBreadcrumbs, setSection, setContextMenu } = useAppDispatch();

    const [availableAttributes, setAvailableAttributes] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [sorting, setSorting] = useState([]);
    const [, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [list, setList] = useState([]);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentModel, setCurrentModel] = useState(false);
    const queryClient = useQueryClient();

    const deleteMutation = useMutation(removeSMSTemplate)

    const tableColumns = useMemo(() => {

        return [
            {
                Header: t('SMS Templates.Fields.Name'),
                accessor: 'name',
                className: 'width100',
            },
            {
                Header: t('SMS Templates.Fields.Type'),
                accessor: 'type',
            },
            {
                Header: t('SMS Templates.Fields.Message'),
                accessor: 'message',
                Cell: ({value}) => {
                    return <div>{value.length > 80 ? value.slice(0,80)+'...' : value}</div>
                }
            },
            {
                Header: t('SMS Templates.Fields.Publicity'),
                accessor: 'public',
                className: 'w-fit-content',
                Cell: ({value}) => (
                    <div className={value ? "chipLike success" : "chipLike"}>
                        <span className="chipLikeLabel">
                            {
                                value
                                    ? t('SMS Templates.Fields.Public')
                                    : t('SMS Templates.Fields.Not Public')
                            }
                        </span>
                    </div>
                )
            },
            {
                Header: '',
                id: 'actions',
                className: 'text-right',
                Cell: ({value, row}) => {
                    if (row.original.default) return null;
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditItem(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            }
        ];
    }, [t]);

    const typesOptions = useMemo(() => config['sms_templates_types'] || [], [config]);
    const availableAttributesWithLink = useMemo(() => {
        // let attrs = []
        let attrs = [{ id: 'link', name: 'Link', slug: 'link' }];
        if (availableAttributes) {
            attrs = [...availableAttributes, ...attrs];
            return attrs;
        }
    }, [availableAttributes]);

    const attributesRequest = useQuery(
        ['attributes'],
        () => getAttributes(),
        { keepPreviousData: true },
    );

    const listRequest = useQuery(
        ['smsTemplates', sorting],
        () => getSMSTemplates(sorting),
        { keepPreviousData: true },
    );

    useEffect(() => {
        if (attributesRequest.isSuccess) setAvailableAttributes(attributesRequest.data.data);
    }, [attributesRequest.isSuccess, attributesRequest.data]);

    useEffect(() => {
        if (listRequest.isSuccess) {
            setList(listRequest.data.data);
            setPagination(listRequest.data.meta);
        }
    }, [listRequest.isSuccess, listRequest.data]);

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            // {label: t('SMS Gateways.Settings'), link: '/settings'},
            { label: t('SMS Templates.List') },
        ]);
        setSection('templates');
    }, [setBreadcrumbs, setSection, t]);

    //context menu
    useEffect(() => {
        setContextMenu([
            {
                label: t('SMS Templates.Add SMS Template'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('SMSTemplateCreate'),
                disabled: configIsLoading && attributesRequest.isLoading,
            },
        ]);
    }, [setContextMenu, configIsLoading, t, attributesRequest.isLoading]);

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true);

        const listener = EventEmitter.addListener('SMSTemplateCreate', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    const handleLimitChange = useCallback((l) => {
        setPage(1);
        setLimit(l);
    }, [setPage, setLimit]);

    const handleEditItem = (item) => {
        if (item.default) return;
        setCurrentModel({
            ...item,
            ...item.params,
        });
        setShowUpdateModal(true);
    };

    const showDeleteConfirmation = (item) => {
        setCurrentModel(item)
        setShowDeleteModal(true)
    }

    const deleteItem = async () => {
        if (!currentModel) return;

        await deleteMutation.mutateAsync(currentModel.id);
        setShowDeleteModal(false);
        await queryClient.invalidateQueries('smsTemplates');
        setCurrentModel(null);
    };

    const modelLabel = currentModel
        ? t('SMS Templates.Delete Instance', { name: currentModel.name })
        : t('SMS Templates.Instance')

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={list}
                loading={listRequest.isLoading}
                fetching={listRequest.isFetching}
                onSort={setSorting}
                emptyDataText={t('SMS Templates.No SMS Templates')}
                pagination={pagination}
                disablePagination
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
                onRowClick={handleEditItem}
            />
            <SaveSMSTemplateModal
                typeOptions={typesOptions}
                availableAttributes={availableAttributesWithLink}
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false);
                }}
            />
            <DeleteModal
                loading={deleteMutation.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItem}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />
            {
                currentModel && (
                    <SaveSMSTemplateModal
                        typeOptions={typesOptions}
                        availableAttributes={availableAttributesWithLink}
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setShowUpdateModal(false);
                        }}
                    />
                )
            }
        </div>
    );
};

export default SmsTemplates;
