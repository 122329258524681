import React, {Fragment} from 'react'
import Chip from '@material-ui/core/Chip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Divider, ListSubheader} from '@material-ui/core'

const ButtonMenu = props => {
    const {label, options, disabled, onSelect, required, icon} = props
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelect = item => {
        if (onSelect) onSelect(item)
    }

    const renderMenu = () => {
        if (!options) return null

        return (
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{height: "400px"}}
                anchorOrigin= {{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                getContentAnchorEl={null}
            >
                {
                    options.map(option => {

                        if (option.divider) {
                            return <Divider key={option.id} />
                        }
                        if (option.header) {
                            return <ListSubheader disableSticky style={{fontWeight: '400', lineHeight: '30px'}} key={option.id}>{option.header.toUpperCase()}</ListSubheader>
                        }

                        return (
                            <MenuItem
                                disabled={option.disabled}
                                dense={true}
                                key={option.id}
                                onClick={() => {
                                    handleClose()
                                    handleSelect(option)
                                }}
                            >
                                {option.name}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        )
    }

    return (
        <Fragment>
            <Chip
                label={`${label}${required ? '*' : ''}`}
                onClick={handleClick}
                disabled={disabled}
                icon={icon}
            />
            {renderMenu()}
        </Fragment>
    )
}

export default ButtonMenu
