import React, {useEffect, useMemo, useState} from 'react'
import InlineLoader from '../loaders/InlineLoader'
import AddAttributeButton from '../AudienceFilter/AddAttributeButton'
import SegmentFilterItem from './SegmentFilterItem'
import {defaultAttributeFilter, filterAttributeMap, filterHasEmptyValuesItems, snakeToTitle} from '../../helpers'
import useSegmentFilterAvailableOptions from './useSegmentFilterAvailableOptions'
import {useMutation} from 'react-query'
import {storeSegmentFilters} from '../../api'
import {useTranslation} from "react-i18next";

const SegmentFilter = ({loading, segment, onSegmentUpdated}) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState(() => segment.filters || [])

    const {
        sourceOptions,
        campaignOptions,
        attributeOptions,
        campaignEngagementOptions,
        campaignSmsStatusOptions,
        leadStatusesOptions,
        engagementPeriodOptions,
    } = useSegmentFilterAvailableOptions()

    // list options calculated from previous added source filter
    const listOptions = useMemo(() => {
        let listArray = []
        const addedSourceFilter = filters.find(filter => filter.id === 'source')
        if (addedSourceFilter && sourceOptions) {

            const selectedSourcesIds = addedSourceFilter.values
            const selectedSourcesModels = addedSourceFilter.operator === 'not_eq'
                ? sourceOptions.filter(source => !selectedSourcesIds.includes(source.id))
                : sourceOptions.filter(source => selectedSourcesIds.includes(source.id))

            selectedSourcesModels.forEach(source => {
                if (source.lists) {
                    listArray = [...listArray, ...source.lists]
                }
            })
        }

        return listArray
    }, [filters, sourceOptions])

    const attributeIdNameMap = useMemo(() => {

        if (!attributeOptions) return null

        const map = {
            'source': t('Segment Filter.Source'),
            'list': t('Segment Filter.List'),
            'duplicates': t('Segment Filter.Duplicates'),
            'status': t('Segment Filter.Status'),
            'carrier_name': t('Segment Filter.Carrier'),
            'carrier_type': t('Segment Filter.Carrier Type'),
            'engagement': t('Segment Filter.Engagement'),
            'engagement_period': t('Segment Filter.Engagement Period'),
            'sms_status': t('Segment Filter.Sms Status'),
            'campaigns': t('Segment Filter.Campaign'),
        }

        if (attributeOptions) {
            attributeOptions.forEach(attr => {
                map[attr.id] = attr.name
            })
        }
        return map
    }, [attributeOptions, t])

    const normalizedFilters = useMemo(() => {
        if (!filters || !attributeIdNameMap) return []
        return filters.map(filter => {
            // console.log(filter)
            const newFilter = {
                ...filter
            }

            if (newFilter.id === 'list' && newFilter.operator === 'first_appeared_in_list') {
                newFilter.singleValue = true
            }

            if (newFilter.id) {
                if (newFilter.id === 'engagement_period') {
                    newFilter.singleValue = true
                }
                newFilter.name = attributeIdNameMap[filter.id] ||  snakeToTitle(filter.id)
            }
            return newFilter
        })
    }, [filters, attributeIdNameMap])

    const attributeMenuOptions = useMemo(() => {

        const normalizedAttributeOptions = attributeOptions.map(attr => ({
            id: attr.id,
            name: attr.name,
            type: 'lead_attribute'
        }))

        const sourceFilter = filters.find(f => f.id === 'source')
        const listFilter = filters.find(f => f.id === 'list')
        const disableSourceItem = !!sourceFilter || !!segment.parent
        const disableListItem = !!listFilter || !sourceFilter || !sourceFilter.values.length || !!segment.parent

        const engagementClickedFilter = filters.find(f => {
            return f.id === 'engagement' && f.values && f.values.includes('engaged')
        })

        const engagementPeriodFilter = filters.find(f => {
            return f.id === 'engagement_period'
        })

        const disableEngagementPeriod = !engagementClickedFilter || engagementPeriodFilter

        return [
            { id: 'source', 'name': 'Source', disabled: disableSourceItem },
            { id: 'list', 'name': 'List', disabled: disableListItem },
            { id: 'divider0', divider: true },
            { id: 'header10', header: 'Contact attributes' },
            ...normalizedAttributeOptions,
            { id: 'divider1', divider: true },
            { id: 'header1', header: 'System Attributes' },
            { id: 'duplicates', name: 'Duplicates' },
            // { id: 'status', name: 'Status' },
            { id: 'carrier_name', name: 'Carrier' },
            { id: 'carrier_type', name: 'Carrier Type' },
            { id: 'divider2', divider: true },
            { id: 'header2', header: 'Campaign Attributes' },
            { id: 'engagement', name: 'Engagement' },
            { id: 'engagement_period', name: 'Engagement Period', disabled: disableEngagementPeriod},
            { id: 'sms_status', name: 'Sms Status' },
            { id: 'campaigns', name: 'Campaign' },
        ]
    }, [
        attributeOptions, filters, segment
    ])

    const valueMenuOptions = useMemo(() => {
        return {
            'source': { label: t('CampaignLog.Add Source'), items: sourceOptions },
            'list': { label: t('CampaignLog.Add List'), items: listOptions },
            'status': { label: t('CampaignLog.Add Status'), items: leadStatusesOptions} ,
            'engagement': { label: t('CampaignLog.Add Engagement'), items: campaignEngagementOptions },
            'engagement_period': { label: t('CampaignLog.Add Engagement Period'), items: engagementPeriodOptions },
            'sms_status': { label: t('CampaignLog.Add Sms Status'), items: campaignSmsStatusOptions },
            'campaigns': { label: t('CampaignLog.Add Campaign'), items: campaignOptions }
        }
    }, [
        sourceOptions,
        listOptions,
        leadStatusesOptions,
        campaignEngagementOptions,
        campaignSmsStatusOptions,
        campaignOptions,
        engagementPeriodOptions,
    ])

    const storeFiltersMutation = useMutation(storeSegmentFilters)

    useEffect(() => {
        if (storeFiltersMutation.isSuccess) {
            onSegmentUpdated()
        }
    }, [storeFiltersMutation.isSuccess, storeFiltersMutation.data, onSegmentUpdated])

    const storeFilters = (filters, segment) => {

        if (!segment) return

        const notEmptyFilters = filters.map(filter => ({
            ...filter,
            values: filter.values.map(v => v.id ? v.id : v)
        }))
            .filter(f => {
                if (f.id && ['empty', 'not_empty'].includes(f.operator)) {
                    return true
                }
                return f.id && f.operator && f.values && f.values.length
            })

        storeFiltersMutation.mutateAsync({id: segment.id, filters: notEmptyFilters}).then()
    }

    const addEmptyFilterItem = () => {
        setFilters(prev => {
            // add new item if prev state has no any items with empty values
            return filterHasEmptyValuesItems(prev)
                ? prev
                : [...prev, {id: null, 'operator': null, values: [], type: null}]
        })
    }

    const updateFilterRowAttribute = (index, itemAttribute) => {
        setFilters(prevFilters => {
            if (prevFilters[index].id === itemAttribute.id) return prevFilters

            const updatedFilters = [...prevFilters]
            updatedFilters[index] = {
                ...updatedFilters[index],
                id: itemAttribute.id,
                name: itemAttribute.name,
                type: itemAttribute.type,
                values: [],
                operator: defaultAttributeFilter(itemAttribute) //TODO: or set default operator for selected attribute
            }
            return updatedFilters
        })
    }

    const updateFilterRowOperator = (index, itemOperator) => {
        setFilters(prevFilters => {
            if (prevFilters[index].operator === itemOperator.id) return prevFilters

            let updatedFilters = [...prevFilters]
            updatedFilters[index] = {
                ...updatedFilters[index],
                operator: itemOperator.id //TODO: or set default operator for selected attribute
            }

            if (updatedFilters[index].id === 'source') {
                const listFilter = updatedFilters.find(f => f.id === 'list')
                if (listFilter) { listFilter.values = [] }
            }

            //update on server
            if (['empty', 'not_empty'].includes(itemOperator.id)) {
                updatedFilters[index].values = []
                storeFilters(updatedFilters, segment)
            } else {
                if (updatedFilters[index].values && updatedFilters[index].values.length) {
                    storeFilters(updatedFilters, segment)
                }
            }
            //update on server end

            return updatedFilters
        })
    }

    const updateFilterRowValues = (index, itemValues) => {
        setFilters(prevFilters => {
            let updatedFilters = [...prevFilters]
            updatedFilters[index] = {
                ...updatedFilters[index],
                values: itemValues
            }

            if (updatedFilters[index].id === 'source') {
                const listFilter = updatedFilters.find(f => f.id === 'list')
                if (listFilter) { listFilter.values = [] }
            }

            //update on server
            storeFilters(updatedFilters, segment)
            //update on server end

            return updatedFilters
        })
    }

    const removeFilterRow = index => {
        setFilters(prevFilters => {
            let updatedFilters = [...prevFilters]

            const deletingItem = updatedFilters[index]

            updatedFilters.splice(index, 1)


            if (deletingItem.id === 'source') {
                updatedFilters = updatedFilters.filter(filter => filter.id !== 'list')
            }

            //update on server
            if (deletingItem.id && deletingItem.operator) {
                if (
                    ['empty', 'not_empty'].includes(deletingItem.operator)
                    || (deletingItem.values && deletingItem.values.length)
                ) {
                    storeFilters(updatedFilters, segment)
                }
            }
            //update on server end

            return updatedFilters
        })
    }

    const renderAddFilterButton = () => {

        const disabled = loading || filterHasEmptyValuesItems(filters)

        return (
            <AddAttributeButton
                disabled={disabled}
                onClick={addEmptyFilterItem}
            />
        )
    }

    return (
        <div className="segmentsFilterHolder" style={{position: 'relative'}}>
            <div className="segmentsFilter">
                {loading && <InlineLoader />}
                {
                    normalizedFilters.map((item, index) => {
                        return (
                            <SegmentFilterItem
                                item={item}
                                key={index}
                                attributeOptions={attributeMenuOptions}
                                operatorOptions={filterAttributeMap(item)}
                                valueOptions={valueMenuOptions[item.id]}
                                onSelectAttribute={attr => updateFilterRowAttribute(index, attr)}
                                onSelectOperator={operator => updateFilterRowOperator(index, operator)}
                                onUpdateValues={values => updateFilterRowValues(index, values)}
                                onRemove={() => removeFilterRow(index)}
                                disabled={!!item.disabled}
                                singleValue={!!item.singleValue}
                            />
                        )
                    })
                }
                {renderAddFilterButton()}
            </div>
        </div>
    )
}

export default SegmentFilter
