import React, {Fragment, useEffect, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import SaveAttributeWithValueModal from "../modals/SaveAttributeWithValueModal";
import {useQuery} from "react-query";
import {getAttributes} from "../../api";

const LeadAddAttributeButton = ({ leadAttributes, leadId }) => {
  const [showModal, setShowModal] = useState(false);
  const [availableAttributes, setAvailableAttributes] = useState([]);

  const visibleAttributes = useMemo(() => {
    const leadAttributesNames = leadAttributes.map((a) => a.name);
    return availableAttributes.filter(
      (attr) => !leadAttributesNames.includes(attr.name)
    );
  }, [leadAttributes, availableAttributes]);

  const attributesRequest = useQuery(["attributes"], () => getAttributes(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (attributesRequest.isSuccess)
      setAvailableAttributes(attributesRequest.data.data);
  }, [attributesRequest.isSuccess, attributesRequest.data]);

  return (
    <Fragment>
      {/* <IconButton onClick={() => setShowModal(true) } size="small"><AddCircle /></IconButton> */}
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setShowModal(true)}
      >
        Add Inputs
      </Button>
      <SaveAttributeWithValueModal
        leadId={leadId}
        attributes={visibleAttributes}
        open={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </Fragment>
  );
};

export default LeadAddAttributeButton;
