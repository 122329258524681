import React, {Fragment} from 'react'
import {Switch} from 'react-router-dom'
import {GuestRoute, PrivateRoute} from '../routes'
import AlertContainer from '../layouts/AlertContainer.component'
import AuthLayout from '../layouts/auth.layout'
import LoginPage from '../../pages/auth/login.page'
import RegisterPage from '../../pages/auth/register.page'
import ForgotPasswordPage from '../../pages/auth/ForgotPasswordPage';
import PasswordResetPage from '../../pages/auth/PasswordResetPage';
import MainLayout from '../layouts/main.layout';
import SourcesPage from '../../pages/sources/SourcesPage';
import SourcePage from '../../pages/sources/SourcePage';
import ListPage from '../../pages/sources/ListPage';
import LeadPage from '../../pages/sources/LeadPage';
import SegmentsPage from '../../pages/segments/SegmentsPage';
import SegmentWrapper from '../../pages/segments/SegmentWrapper';
import LeadsList from '../../pages/LeadsList';
import Settings from '../../pages/Settings';
import Analytics from '../../pages/Analytics'
import Campaigns from '../../pages/campaigns/Campaigns'
import CampaignWrapper from '../../pages/campaigns/CampaignWrapper'
import LookupManagement from '../../pages/lookup/LookupManagement'
import Profile from '../../pages/profile/Profile';
import ProfileNew from "../../pages/profileNew/Profile";
import InviteRegisterPage from '../../pages/auth/InviteRegister.page';
import SmsTemplates from '../../pages/sms-templates/SmsTemplates';

const App = () => {

    return (
        <Fragment>
            <Switch>
                <PrivateRoute path='/' exact
                              component={SegmentsPage}
                              layout={MainLayout}/>
                <PrivateRoute path='/campaigns' exact
                              component={Campaigns}
                              layout={MainLayout}/>
                <PrivateRoute path='/campaigns/:campaignId'
                              component={CampaignWrapper}
                              layout={MainLayout}/>
                <PrivateRoute path='/analytics' exact
                              component={Analytics}
                              layout={MainLayout}/>
                <PrivateRoute path='/lookup-management'
                              component={LookupManagement}
                              layout={MainLayout}/>
                <PrivateRoute path='/settings'
                              component={Settings}
                              layout={MainLayout}/>
                <PrivateRoute path='/sms-templates'
                              component={SmsTemplates}
                              layout={MainLayout}/>
                <PrivateRoute path='/old-profile'
                              component={Profile}
                              layout={MainLayout}/>
                <PrivateRoute path='/leads/sources' exact
                              component={SourcesPage}
                              layout={MainLayout}/>
                <PrivateRoute path='/profile'
                              component={ProfileNew}
                              layout={MainLayout}/>
                <PrivateRoute path='/leads' exact
                              component={LeadsList}
                              layout={MainLayout}/>
                <PrivateRoute path='/leads/:leadId' exact
                              component={LeadPage}
                              layout={MainLayout}/>
                <PrivateRoute path='/audiences/:segmentId'
                              component={SegmentWrapper}
                              layout={MainLayout}
                />
                <PrivateRoute path='/audiences'
                              component={SegmentsPage}
                              layout={MainLayout}
                />
                <PrivateRoute path='/leads/sources/:sourceId' exact
                              component={SourcePage}
                              layout={MainLayout}/>
                <PrivateRoute path='/leads/sources/:sourceId/:listId' exact
                              component={ListPage}
                              layout={MainLayout}/>
                <PrivateRoute path='/leads/sources/:sourceId/:listId/:leadId' exact
                              component={LeadPage}
                              layout={MainLayout}/>
                <GuestRoute path='/signin'
                            component={LoginPage}
                            layout={AuthLayout}/>
                <GuestRoute path='/register'
                            component={RegisterPage}
                            layout={AuthLayout}/>
                <GuestRoute path='/invite/:token'
                            component={InviteRegisterPage}
                            layout={AuthLayout}/>
                <GuestRoute path='/forgot-password'
                            component={ForgotPasswordPage}
                            layout={AuthLayout}/>
                <GuestRoute path='/reset-password'
                            component={PasswordResetPage}
                            layout={AuthLayout}/>
            </Switch>
            <AlertContainer/>
        </Fragment>
    )
}

export default App
