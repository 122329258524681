import React from 'react'
import {Route as ReactRoute} from 'react-router-dom'
import {useAuth} from '../../Contexts/AuthProvider'
import AccessDenied from '../AccessDenied'

const Route = ({ component: Component, layout: Layout = null, ...rest }) => {

    const { accessDenied } = useAuth()

    if (accessDenied) {
        return (
            <ReactRoute {...rest} render={matchProps => (
                Layout
                    ? <Layout><AccessDenied /></Layout>
                    : <AccessDenied />
            )} />
        )
    }

    return (
        <ReactRoute {...rest} render={matchProps => (
            Layout
                ? <Layout><Component {...matchProps} /></Layout>
                : <Component {...matchProps} />
        )} />
    )
}

export default Route
