import React from 'react';
import {Field, Form, Formik} from 'formik';
import {Button} from "@material-ui/core";
import * as Yup from 'yup';
import TextInputField from "../fields/Input.form";
import {useTranslation} from "react-i18next";

const FormChangePasswordSchema = (t) => Yup.object().shape({
    current_password: Yup
        .string()
        .required(t("New Profile.SecurityPage.Old Password is required"))
        .test('passwords-match', t("New Profile.SecurityPage.Current password error"), function(value){
            return this.parent.password !== value
        }),
    password: Yup.string()
        .min(8)
        .required(t("New Profile.SecurityPage.Password is required"))
        .matches(
            //eslint-disable-next-line
            /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/,
            t("New Profile.SecurityPage.Special character")
        ),
    password_confirmation: Yup.string()
        .test('passwords-match', t("New Profile.SecurityPage.Passwords must match"), function(value){
            return this.parent.password === value
        })
});

export default function FormChangePassword({ onSubmit }) {
    const { t } = useTranslation();
    return (
        <Formik
            initialValues={{
                current_password: '',
                password: '',
                password_confirmation: ''
            }}
            validationSchema={FormChangePasswordSchema(t)}
            onSubmit={async (values, { resetForm }) => {
                await new Promise((r) => setTimeout(r, 500));
                onSubmit(values, resetForm);
            }}
        >
            <Form className="profileFormHolder">
                <div className="formHolder mb45">
                    <div className="cpSubTitle">{t("New Profile.SecurityPage.Password")}</div>
                    <Field
                        type="password"
                        name="current_password"
                        id="current_password"
                        label={t("New Profile.SecurityPage.Old password")}
                        component={TextInputField}
                    />
                    <Field
                        type="password"
                        name="password"
                        id="password"
                        label={t("New Profile.SecurityPage.New Password")}
                        component={TextInputField}
                    />
                    <Field
                        type="password"
                        name="password_confirmation"
                        id="password_confirmation"
                        label={t("New Profile.SecurityPage.Confirm Password")}
                        component={TextInputField}
                    />
                    <div className="btnHolder mt20">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >{t("New Profile.SecurityPage.Submit")}</Button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
}
