import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import List from '@material-ui/core/List';
import {ListItem, ListItemText} from '@material-ui/core';

const SelectSMSTemplateModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        options,
        onSelect,
    } = props;

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal messages"
            >
                <div className="modalBody" style={{overflow: 'scroll'}}>
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{'Select template'}</h2>
                    </div>
                    <div>
                    
                        {options.length ? <List disablePadding>
                            {
                                options.map(item => {
                                    const {name, message} = item
                                    return (
                                        <ListItem
                                            button
                                            key={item.id}
                                            onClick={() => onSelect(item.message)}
                                            style={{
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            }}
                                        >
                                            <ListItemText
                                                primary={name}
                                                secondary={<div style={{maxHeight: '42px', overflow: 'hidden'}}>{message.length > 114 ? message.slice(0,114)+'...' : message}</div>}
                                            />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        :
                        <div className="fontSize14 text-grey text-center">
                            {t("Modals.You have no SMS templates yet")}<br/><Link className="linkPrimary mt5" style={{display: 'inline-block'}} to="/sms-templates">{t('Modals.Create your first template')}</Link>
                        </div>
                        }
                        <div className="btnHolder hasPadTop text-center">
                            {/*<div className="part">*/}
                                {/*<Button*/}
                                {/*    variant="contained"*/}
                                {/*    color="primary"*/}
                                {/*    fullWidth*/}
                                {/*    type="submit"*/}
                                {/*    onClick={(e) => {*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {t('Modals.Save')}*/}
                                {/*</Button>*/}
                            {/*</div>*/}
                            {/*<div className="part">*/}
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    // fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default SelectSMSTemplateModal;
