import React, {Fragment, useEffect, useState} from 'react'
import {useAppDispatch} from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import Exit from '../../assets/img/exit.svg'
import PlusCircle from '../../assets/img/plus_circle.svg'
import ImportFileModal from '../../components/modals/ImportFileModal'
import SaveSourceModal from '../../components/modals/SaveSourceModal'
import Delete from '../../assets/img-new/delete.svg'
import Chevron from '../../assets/img/chevron_big_right.svg'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import {useMutation, useQueryClient} from 'react-query'
import {sourcesLookup} from '../../api'
import ConfirmModal from '../../components/modals/ConfirmModal'
import {useTranslation} from "react-i18next";

const SourcesPageActions = ({selectedRows, bulkDone}) => {
    const { t } = useTranslation();
    const {setContextMenu} = useAppDispatch()
    const [showImportModal, setShowImportModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showLookupModal, setShowLookupModal] = useState(false)
    const queryClient = useQueryClient()
    const lookupMutation = useMutation(sourcesLookup)

    useEffect(() => {
        const items = [
            {
                label: t('Sources.Import File'),
                icon: Exit,
                action: () => EventEmitter.emit('ImportFile'),
            },
            {
                label: t('Sources.Add New Source'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('SourceCreate'),
            },
            {
                label: t('Sources.Run Lookup'),
                icon: Chevron,
                action: () => EventEmitter.emit('SourcesRunLookup'),
                disabled: !selectedRows.length,
            },
            {
                label: t('Sources.Delete Selection'),
                icon: Delete,
                action: () => EventEmitter.emit('SourceBulkDeletion'),
                disabled: !selectedRows.length,
            },
        ]
        setContextMenu(items)
    }, [setContextMenu, selectedRows, t])

    useEffect(() => {
        const onEvent = () => {
            setShowImportModal(true)
        }

        const listener = EventEmitter.addListener('ImportFile', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('SourceCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('SourceBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowLookupModal(true)

        const listener = EventEmitter.addListener('SourcesRunLookup', onEvent)
        return () => { listener.remove() }
    }, [lookupMutation, selectedRows])

    const deleteItems = () => {
        setShowDeleteModal(false)
        DataService.deleteSources(selectedRows)
            .then(() => {
                queryClient.invalidateQueries('sourcesList').then()
                queryClient.invalidateQueries('availableSources').then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const lookupItems = async () => {
        await lookupMutation.mutateAsync(selectedRows)
        setShowLookupModal(false)
        AlertToastr.showAlert(t('Sources.Lookup has been started'))
        if (bulkDone) bulkDone()

    }

    return (
        <Fragment>
            <ImportFileModal
                open={showImportModal}
                handleClose={() => setShowImportModal(false)}/>
            <SaveSourceModal
                open={showSaveModal}
                handleClose={(event) => {
                    setShowSaveModal(false)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='selected sources'/>

            <ConfirmModal
                loading={lookupMutation.isLoading}
                message={t('Sources.Do you want to lookup leads in selected sources')}
                open={showLookupModal}
                handleClose={(e) => setShowLookupModal(false)}
                handleConfirm={lookupItems}
            />
        </Fragment>
    )
}

export default SourcesPageActions
