import React, {useEffect, useState} from 'react'
import InlineLoader from './loaders/InlineLoader'
import {useQuery} from 'react-query'
import {getSourcesTotal} from '../api'
import {useTranslation} from "react-i18next";
import Ico1 from '../assets/img-new/u_list-ui-alt.svg'
import Ico2 from '../assets/img-new/u_copy.svg'
import Ico3 from '../assets/img-new/u_calender.svg'

const SourcesHeader = () => {
    const { t } = useTranslation();
    const [aggregations, setAggregations] = useState({
        total_leads: '-',
        total_today: '-',
        duplicates_today: '-'
    })

    const {isLoading, data} = useQuery('sourcesTotal', getSourcesTotal, { keepPreviousData : true })

    useEffect(() => {
        if (!data) return
        setAggregations(data.data)
    }, [data])

    const items = [
        {
            label: t('Sources Header.Total Leads'),
            count: aggregations['total_leads'],
            icon: Ico1
        },
        {
            label: t('Sources Header.Today'),
            count: aggregations['total_today'],
            icon: Ico2
        },
        {
            label: t('Sources Header.Duplicate today'),
            count: aggregations['duplicates_today'],
            icon: Ico3
        }
    ]

    return (
        <div className="pageHeadingBlockWrapper">
            {
                items.map(item => (
                    <div className="staticCard" key={item.label}>
                            <span className="fs12">{item.label}: </span>
                            <div>{isLoading ? <InlineLoader /> : item.count}</div>
                    </div>
                ))
            }
        </div>
    )
}

export default SourcesHeader
