import React, {useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {CircularProgress} from "@material-ui/core";
import {useMutation} from "react-query";
import {balanceTopUp} from "../../api";
import {useTranslation} from "react-i18next";
import Afl1 from "../../assets/img-new/afl1.svg"
import Afl2 from "../../assets/img-new/afl2.svg"
import Afl3 from "../../assets/img-new/afl3.svg"

export const LiqPayForm = React.forwardRef(({ data = '', signature = '' }, ref) => {
    return (
        <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8" ref={ref}>
            <input type="hidden" name="data" value={data} />
            <input type="hidden" name="signature" value={signature} />
        </form>
    )
})

const AddBalanceModal = ({ open, handleClose = () => { }, onSuccessCreate = () => { } }) => {
    const formLiqPayRef = React.createRef()
    const [amount, setAmount] = useState('')
    const [liqpay, setForm] = useState({ data: '', signature: '' })
    const [errorMessage, setError] = useState('')
    const mutation = useMutation(balanceTopUp)
    const { t } = useTranslation();
    const handlePay = async () => {
        try {
            if (!/^\d+(\.\d{1,2})?$/.test(amount.toString()) || parseFloat(amount.toString()) === 0) {
                setError(t("Transaction.Correct number"))
                return
            }

            const form = formLiqPayRef.current
            const data = await mutation.mutateAsync({ amount, redirectUrl: window.location.href })
            setForm(data)

            if (form) {
                form.submit()
                handleClose()
                onSuccessCreate()
            }
        } catch (error) {
            setError(t("Transaction.Correct number"))
        }
    }

    useEffect(() => setError(''), [amount])

    return (
        <Dialog open={open} onClose={handleClose} className='modal'>
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{t("Modals.Top Up Balance")}</h2>
                </div>

                <LiqPayForm
                    data={liqpay.data}
                    signature={liqpay.signature}
                    ref={formLiqPayRef}
                />

                <div className="addFundsRadioHolder">
                    <ul className="afrList">
                        <li className="afrListItem">
                            <div className="radioHolder">
                                <input type="radio" name='qnt' id='qnt50'/>
                                <label htmlFor="qnt50">₴50</label>
                            </div>
                        </li>
                        <li className="afrListItem">
                            <div className="radioHolder">
                                <input type="radio" name='qnt' id='qnt100'/>
                                <label htmlFor="qnt100">₴100</label>
                            </div>
                        </li>
                        <li className="afrListItem">
                            <div className="radioHolder">
                                <input type="radio" name='qnt' id='qnt200'/>
                                <label htmlFor="qnt200">₴200</label>
                            </div>
                        </li>
                    </ul>
                </div>

                <TextField
                    error={Boolean(errorMessage)}
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    id="top_up_balance"
                    label={t("Modals.Amount")}
                    variant="outlined"
                    margin='normal'
                    helperText={errorMessage}
                    style={{maxWidth: '200px', marginLeft: "auto", marginRight: "auto"}}
                />

                <div className="paymentSystemHolder mt30">
                    <ul className="psList">
                        <li className="psListItem">
                            <a href="#" className='psItemContent'>
                                <img src={Afl1} alt="" style={{width: '33px'}}/>
                            </a>
                        </li>
                        <li className="psListItem">
                            <a href="#" className='psItemContent'>
                                <img src={Afl2} alt="" style={{width: '44px'}}/>
                            </a>
                        </li>
                        <li className="psListItem">
                            <a href="#" className='psItemContent'>
                                <img src={Afl3} alt="" style={{width: '51px'}}/>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="outlined" color="primary" fullWidth onClick={handleClose}>
                            {t("Modals.Cancel")}
                        </Button>
                    </div>
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="contained" color="primary" fullWidth onClick={handlePay}>
                            {mutation.isLoading ? <CircularProgress size={24} color="inherit" /> : t("Modals.Pay")}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AddBalanceModal
