import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useApiCall from '../../Hooks/useApiCall';
import authEndpoints from '../../Endpoints/auth.endpoints';
import {useAuth} from '../../Contexts/AuthProvider';
import AlertToastr from '../../Utils/alert';
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';

const LoginPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [request, signin] = useApiCall();
    const [keepLogged, setKeepLogged] = useState(false);
    const {login, isLoggedIn} = useAuth();

    const onLoginClicked = (e) => {
        e.preventDefault();
        signin({
            ...authEndpoints.login(),
            data: {
                email: email,
                password: password,
            },
        });
    };

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message);
        }
    }, [request.error]);

    useEffect(() => {
        if (!request.response)
            return;
        login(request.response.data.access_token);

    }, [request.response, login]);

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }
    const rest = {
        onChange: () => {setKeepLogged(!keepLogged)},
        checked: keepLogged
    }
    return (
        <div className="formHolder">
            <p className="mb20 text-fontSecondary">{t('Auth.Login to your account')}</p>
            <form noValidate autoComplete="off">
                <TextField
                    id="email"
                    label={t("Auth.Email")}
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    margin="normal"
                    autoComplete="username"
                />
                <TextField
                    id="pass"
                    label={t("Auth.Password")}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    margin="normal"
                    autoComplete="current-password"
                />
                <div className="authCheckHolder">
                    {/* <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={<span className="text-fontSecondary">{t("Auth.Keep me logged in")}</span>}
                    /> */}
                    <div className="keppLoggedContainer">
                        <IndeterminateCheckbox {...rest} ref={null}/>
                        <span className="text-fontSecondary fs14">{t("Auth.Keep me logged in")}</span>
                    </div>
                    <a href="/forgot-password" className="text-mute text-bold linkPrimary cursor-pointer text-fontSecondary">
                        {t("Auth.Forgot your password")}
                    </a>
                </div>
                <div className="btnHolder mt20 mb15">
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={onLoginClicked}
                    >
                        {t("Auth.Log in")}
                    </Button>
                </div>
                <div className="linkHolder">
                 {t("Auth.Don`t have an account")}? <NavLink to="/register" activeClassName='active'>{t('Auth Layout.Register')}</NavLink>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;
