import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppState} from '../../Contexts/AppContext'
import SaveCampaignModal from '../../components/modals/SaveCampaignModal'
import EditIcon from '../../assets/img-new/edit.svg'
import EventEmitter from '../../Utils/EventEmitter'
import DeleteIcon from '../../assets/img-new/delete.svg'
import TestIcon from '../../assets/img-new/test.svg'
import DeleteModal from '../../components/modals/DeleteModal'
import {useMutation, useQueryClient} from 'react-query'
import {removeCampaign, updateCampaign} from '../../api'
import {useHistory} from 'react-router-dom'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Settings from '../../assets/img/settings.svg'
import AlertToastr from '../../Utils/alert'
import CreateSegmentIco from '../../assets/img/layers_alt.svg'
import SaveCampaignSegmentModal from '../../components/modals/SaveCampaignSegmentModal'
import {useCampaignState} from '../../Contexts/CampaignContext'
import CampaignTestModal from '../../components/modals/CampaignTestModal';
import {useTranslation} from "react-i18next";

const CampaignActions = ({campaign, loading}) => {
    const { t } = useTranslation();
    const history = useHistory()
    const queryClient = useQueryClient()
    const {setContextMenu} = useAppDispatch()
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showTestModal, setShowTestModal] = useState(false)
    const [showCampaignCreateSegmentModal, setShowCampaignCreateSegmentModal] = useState(false)
    const campaignRemoveRequest = useMutation(removeCampaign)
    const {smsStatuses, engagements} = useCampaignState()

    const {config}=useAppState()
    const campaignStatuses = useMemo(() => {
        return config['campaign_manual_status_list'] || []
    }, [config])

    const campaignVisibleStatuses = useMemo(() => {
        if (!campaign) return []
        const list = campaignStatuses.map((item) => {
            return {
                ...item,
                status: item.key,
                action: () => EventEmitter.emit('CampaignChangeStatus', item.key),
            }
        })

        let statusOptions = []

        if (!campaign.status) statusOptions = list;

        if (campaign.status === 'rejected') {
            statusOptions = list.filter(item => item.key === 'draft');
        } else {
            statusOptions = list.filter(item => item.key !== campaign.status);
        }

        return statusOptions
    }, [campaignStatuses, campaign])
    const updateCampaignMutation = useMutation(updateCampaign)

    const updateItemStatus = useCallback(async status => {
        if (campaign) {
            try {
                await updateCampaignMutation.mutateAsync({
                    id: campaign.id,
                    status
                })
                queryClient.invalidateQueries('campaignView').then()
                queryClient.invalidateQueries('campaignsList').then()
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error)
            }
        }
    }, [queryClient, updateCampaignMutation, campaign])

    useEffect(() => {
        const items = [
            {
                label: t("Campaigns.Edit Campaign"),
                icon: EditIcon,
                action: () => EventEmitter.emit('CampaignEdit'),
                disabled: loading || ['active', 'completed', 'failed'].includes(campaign?.status)
            },
            {
                label: t('Campaigns.Remove Campaign'),
                icon: DeleteIcon,
                action: () => EventEmitter.emit('CampaignDelete'),
                disabled: loading
            },
            {
                label: updateCampaignMutation.isLoading ? t('Campaigns.Updating') : t('Campaigns.Set status'),
                icon: Settings,
                disabled: !campaign || updateCampaignMutation.isLoading,
                items: campaignVisibleStatuses
            },
            {
                label: t('Campaigns.Test Campaign'),
                icon: TestIcon,
                action: () => EventEmitter.emit('CampaignTest'),
                disabled: loading || ['active', 'completed', 'failed'].includes(campaign?.status)
            },
        ]
        if (campaign && (['completed', 'failed', 'paused'].includes(campaign.status))) {
            const filtersDisabled = !smsStatuses.length && !engagements.length
            items.push({
                label: t('Campaigns.Create Segment'),
                icon: CreateSegmentIco,
                disabled: !campaign || updateCampaignMutation.isLoading || filtersDisabled,
                action: () => EventEmitter.emit('CampaignSegmentCreate')
            })
        }
        setContextMenu(items)
    }, [
        setContextMenu,
        loading,
        campaign,
        updateCampaignMutation.isLoading,
        campaignVisibleStatuses,
        smsStatuses,
        engagements,
        t
    ])

    //listener: campaign edit
    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('CampaignEdit', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: campaign delete
    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('CampaignDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowTestModal(true)

        const listener = EventEmitter.addListener('CampaignTest', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowCampaignCreateSegmentModal(true)

        const listener = EventEmitter.addListener('CampaignSegmentCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = (status) => updateItemStatus(status)

        const listener = EventEmitter.addListener('CampaignChangeStatus', onEvent)
        return () => { listener.remove() }
    }, [updateItemStatus])



    const deleteCampaign = async () => {
        await campaignRemoveRequest.mutateAsync(campaign.id)
        await queryClient.invalidateQueries('campaignsList')
        history.push('/campaigns')
    }

    return (
        <Fragment>
            {
                campaign && (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <SaveCampaignModal
                            defaultValues={campaign}
                            open={showSaveModal}
                            handleClose={(event) => {
                                setShowSaveModal(false)
                            }}
                        />
                        <SaveCampaignSegmentModal
                            campaign={campaign}
                            open={showCampaignCreateSegmentModal}
                            handleClose={(event) => {
                                setShowCampaignCreateSegmentModal(false)
                            }}
                        />
                        <CampaignTestModal
                            campaign={campaign}
                            open={showTestModal}
                            handleClose={(event) => {
                                setShowTestModal(false)
                            }}
                        />
                    </MuiPickersUtilsProvider>
                )
            }

            <DeleteModal
                loading={campaignRemoveRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteCampaign}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the campaign'}
            />
        </Fragment>
    )
}

export default CampaignActions
