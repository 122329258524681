import React, {Fragment, useEffect, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import {useHistory} from 'react-router-dom'
import Switch from '@material-ui/core/Switch'
import {useQueryClient} from 'react-query'
import {useTranslation} from "react-i18next";

const SaveSegmentModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        isDuplicateSegmentMode,
        segment,
        parentSegment,
        onDuplicated,
        entityTitle,
    } = props

    const [name, setName] = useState('')
    const [disableButtons, setDisabledButtons] = useState(false)
    const [isUpdateDynamically, setIsUpdateDynamically] = useState(true)
    const history = useHistory()
    const queryClient = useQueryClient()

    useEffect(() => {
        if (!open)
            return
        if (segment && !isDuplicateSegmentMode) {
            setName(segment.name)
            setIsUpdateDynamically(!segment.ignore_new_leads)
        } else {
            setName('')
            setIsUpdateDynamically(true)
        }
    }, [segment, isDuplicateSegmentMode, open])

    const handleCreate = () => {
        setDisabledButtons(true)
        if (isDuplicateSegmentMode && segment) {
            DataService.cloneSegment(segment.id, name, isUpdateDynamically).then(response => {
                setDisabledButtons(false)
                onDuplicated(response.data.data)
                queryClient.invalidateQueries('authUser').then()
            })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors)
                        AlertToastr.showErrorAlert(error.response.data.errors)
                })
        } else if (segment) {
            DataService.updateSegment(segment.id, name, isUpdateDynamically).then(response => {
                queryClient.invalidateQueries('segmentView').then()
                queryClient.invalidateQueries('subSegmentView').then()
                queryClient.invalidateQueries('segmentLeads').then()
                setDisabledButtons(false)
                handleClose()
            })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors)
                        AlertToastr.showErrorAlert(error.response.data.errors)
                })
        } else
            if (parentSegment) {
                DataService.createChildSegment(parentSegment.id, name, isUpdateDynamically).then(async response => {
                    queryClient.invalidateQueries('authUser').then()
                    queryClient.invalidateQueries('segmentView').then()
                    history.push(`/audiences/${parentSegment.id}/segments/${response.data.data.id}`)
                })
                    .catch((error) => {
                        setDisabledButtons(false)
                        if (error && error.response && error.response.data && error.response.data.errors)
                            AlertToastr.showErrorAlert(error.response.data.errors)
                    })
            } else {
                DataService.createSegment(name, isUpdateDynamically).then(async response => {
                    queryClient.invalidateQueries('authUser').then()
                    history.push(`/audiences/${response.data.data.id}`)
                })
                .catch((error) => {
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.errors)
                        AlertToastr.showErrorAlert(error.response.data.errors)
                })
            }

    }

    const getActionName = () => {
        const {entityTitle} = props

        if(Boolean(entityTitle)) return entityTitle

        return ""
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{getActionName()}</h2>
                    </div>
                    <TextField
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="source"
                        label={t("Modals.Name")}
                        variant="outlined"
                        margin='normal'
                    />
                    <div className="align-center wrapper content-justified">
                        {t("Modals.Update dynamically")}
                        <Switch
                            color="primary"
                            checked={!!isUpdateDynamically}
                            onChange={() => {
                                setIsUpdateDynamically(!isUpdateDynamically)
                            }}
                        />
                    </div>
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleCreate}
                            >
                                {segment && !isDuplicateSegmentMode ? t('Modals.Save') : t('Modals.Create')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveSegmentModal
