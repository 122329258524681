import React, {useEffect, useMemo, useRef, useState} from 'react';
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";
import {useAppDispatch} from '../../Contexts/AppContext';
import {useMutation, useQuery} from 'react-query';
import {balanceSubscribe, getSubscriptions} from '../../api';
import PlanItemCard from './PlanItemCard';
import {useAuth} from "../../Contexts/AuthProvider";
import moment from "moment";
import {LiqPayForm} from "../../components/modals/AddBalanceModal";

const SubscriptionPlan = () => {
    const { t } = useTranslation();
    const { isLoggedIn, subscriptions } = useAuth()
    const [availablePlans, setAvailablePlans] = useState([]);
    const [, setActiveSubscriptions] = useState([]);
    const formLiqPayRef = useRef(null)
    const [liqpay, setLiqPayForm] = useState({ data: '', signature: '' })
    const { setBreadcrumbs, setSection, setContextMenu } = useAppDispatch();

    const subscriptionsRequest = useQuery(
        ['subscriptions'],
        () => getSubscriptions(),
        { keepPreviousData: true },
    );

    const subscribeMutation = useMutation(balanceSubscribe)

    useEffect(() => {
        setContextMenu([]);
    }, [setContextMenu]);

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            // {label: 'Profile', link: '/change-password'},
            { label: t('Profile.Subscriptions') },
        ]);
        setSection('settings');
    }, [setBreadcrumbs, setSection, t]);

    useEffect(() => {
        if (subscriptionsRequest.isSuccess) {
            const { available_plans, active_subscriptions } = subscriptionsRequest.data
            setAvailablePlans(available_plans);
            setActiveSubscriptions(active_subscriptions);
        }
    }, [
        subscriptionsRequest.isSuccess,
        subscriptionsRequest.data
    ])


    const planSelect = async plan => {
        const data = await subscribeMutation.mutateAsync(plan.id)

        // if subscribe success
        if (data && data.hasOwnProperty('active_subscriptions')) {
            // setActiveSubscriptions(data.active_subscriptions);
            AlertToastr.showAlert(t('Subscriptions.Successfully subscribed to new plan'))
            // тимчасово, треба якесь правильне рішення
            setTimeout(() => window.location.reload(), 2000)
            // if need top up balance first
        } else if (data && data.hasOwnProperty('data')) {
            setLiqPayForm(data.data)
            formLiqPayRef.current.submit()
        }
    }

    const activeSubscription = useMemo(() => {
        return Array.isArray(subscriptions) && subscriptions.length > 0 ? subscriptions[0] : null
    }, [subscriptions])

    const isDisabledSubscription = useMemo(() => {
        if (Array.isArray(subscriptions) && subscriptions.length > 0) {
            const isTrial = subscriptions[0] && subscriptions[0].name.toLowerCase() === 'trial'
            const isEnded = subscriptions[0] && moment().isAfter(moment(subscriptions[0].ends_at, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS))

            return !isTrial || !isLoggedIn || isEnded
        }

        return !isLoggedIn
    }, [isLoggedIn, subscriptions])

    const visiblePlans = useMemo(() => {
        return (
          availablePlans.filter(item => item.name !=='Trial')
        )
    }, [availablePlans])
    const planTable = useMemo(() => {
        const features = [
            {
                slug: 'slug1',
                name: 'name1',
                sort_order: '1',
                values: visiblePlans.map(() => '100')
            },
            {
                slug: 'slug2',
                name: 'name2',
                sort_order: '2',
                values: visiblePlans.map(() => '100')
            },
            {
                slug: 'slug3',
                name: 'name3',
                sort_order: '3',
                values: visiblePlans.map(() => '100')
            }
        ];
        visiblePlans.forEach((plan) => {
            plan.features.forEach(() => {
            //TODO: fix this
            })
        })

        return features;

    }, [visiblePlans])

    return (
        <div className="subscriptionPlan">
            <div className="contentPartTitleHolder">
                <div className="contentPartTitle">{t("New Profile.Subscriptions")}</div>
            </div>
            <div className="subscriptionPlanListHolder">
                {/* <table className="subscriptionPlanTable mb45">
                    <tr>
                        <th/>
                        {visiblePlans.map((item) => <th>{item.name}</th>)}
                    </tr>
                    {planTable.map((row) => {
                        return (
                          <tr>
                              <td>{row.name}</td>
                              {visiblePlans.map((item) => <td>100</td>)}
                              {row.values && row.values.map((feature) => <td>{feature}</td>)}
                          </tr>
                        )
                    })}
                    <tr>
                        <td/>
                        {visiblePlans.map((item) => <td>
                            <PlanItemButton key={item.id}
                                item={item}
                                disabled={isDisabledSubscription || (activeSubscription && activeSubscription.name.toLowerCase() === item.name.toLowerCase())}
                                onSelect={planSelect}
                        /></td>)}
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                    {availablePlans.map(item => (
                        item.name !== 'Trial' &&
                        <tr>
                            {item.name}
                            <PlanItemCard
                              key={item.id}
                              item={item}
                              disabled={isDisabledSubscription || (activeSubscription && activeSubscription.name.toLowerCase() === item.name.toLowerCase())}
                              onSelect={planSelect}
                            />
                        </tr>

                    ))}

                </table> */}
                {availablePlans.map(item => (
                        item.name !== 'Trial' &&
                        <div className="mb20">
                            <span className="planName">{item.name}</span>
                            <PlanItemCard
                              key={item.id}
                              item={item}
                              disabled={isDisabledSubscription || (activeSubscription && activeSubscription.name.toLowerCase() === item.name.toLowerCase())}
                              onSelect={planSelect}
                            />
                        </div>

                    ))}
            </div>
            {/*<div className="cpSubTitle">Payment Method</div>*/}
            {/*<div className="paymentMethodListHolder mb45">*/}
            {/*    <ul className="paymentMethodList">*/}
            {/*        <li className="pmlItem">*/}
            {/*            <div className="pmlItemContent">*/}
            {/*                <div className="title">Credit Card</div>*/}
            {/*                <div className="cardInfo">*/}
            {/*                    <div className="imgHolder">*/}
            {/*                        <img src={Cards} alt=""/>*/}
            {/*                    </div>*/}
            {/*                    <div className="numberHolder">*/}
            {/*                        **** **** **** 1839*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </li>*/}
            {/*        <li className="pmlItem default">*/}
            {/*            <div className="pmlItemContent">*/}
            {/*                <div className="imgHolder">*/}
            {/*                    <img src={Plus} alt=""/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}

            <LiqPayForm data={liqpay.data} signature={liqpay.signature} ref={formLiqPayRef} />
        </div>
    )
}

export default SubscriptionPlan
