import {apiAuthBaseUrl, apiDataBaseUrl} from "./config"
import axios from 'axios'

export const fetchData = (url, params) => {
    const queryParams = {...params}
    const {page, search, limit, order} = queryParams
    if (page !== undefined) {
        if (!page || page === 1) delete queryParams.page
    }
    if (search !== undefined) {
        if (!search) delete queryParams.search
    }
    if (limit !== undefined) {
        if (!limit) delete queryParams.limit
    }
    if (order !== undefined) {
        if (!order) {
            delete queryParams.order
        } else {
            let orderArray = Array.isArray(order) ? order : [order]
            queryParams.order = orderArray.map(orderItem => {
                const {key, direction} = orderItem
                return `${key},${direction}`
            })
        }
    }

    return axios(`${apiDataBaseUrl}${url}`, { params: queryParams });
}

export const getSourcesTotal = async () => {
    const { data } = await fetchData('/sources/total')
    return data
}

export const getSources = async (page, limit, search, order) => {
    const { data } = await fetchData('/sources', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSourcesWithAggregations = async (page, limit, search, order) => {
    const { data } = await fetchData('/sources/aggregations', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSource = async (id) => {
    const { data } = await fetchData(`/sources/${id}`)
    return data
}

export const getSourceAggregations = async (id) => {
    const { data } = await fetchData(`/sources/${id}/aggregations`)
    return data
}

export const getList = async (id) => {
    const { data } = await fetchData(`/sources/lists/${id}`)
    return data
}

export const getListAggregations = async (id) => {
    const { data } = await fetchData(`/sources/lists/${id}/aggregations`)
    return data
}

export const getSourceLists = async (sourceId, page, limit, search, order) => {
    const { data } = await fetchData(`/sources/${sourceId}/lists`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSourceListsWithAggregations = async (sourceId, page, limit, search, order) => {
    const { data } = await fetchData(`/sources/${sourceId}/lists/aggregations`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegments = async (page, limit, search, order) => {
    const { data } = await fetchData('/segments', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegmentsWithAggregations = async (page, limit, search, order) => {
    const { data } = await fetchData('/segments/aggregations', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSubsegments = async (segmentId, page, limit, search, order) => {
    const { data } = await fetchData(`/segments/${segmentId}/segments`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSubsegmentsWithAggregations = async (segmentId, page, limit, search, order) => {
    const { data } = await fetchData(`/segments/${segmentId}/segments/aggregations`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegmentLeads = async (segmentId, page, limit, search, order) => {
    const { data } = await fetchData(`/segments/${segmentId}/leads`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const storeLead = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/leads`, body)
    return data
}

export const getListLeads = async (listId, page, limit, search, order) => {

    const { data } = await fetchData(`/lists/${listId}/leads`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getLeadsList = async (page, limit, search, order) => {

    const { data } = await fetchData('/leads', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getImports = async (page, limit, order, search) => {
    const { data } = await fetchData('/imports', {
        page,
        limit,
        order,
        search
    })
    return data
}

export const getImportFile = async (id) => {
    const { data } = await fetchData(`/import-files/${id}`)
    return data
}

export const getImportFileFailedLeads = async (fileId, page, limit, search, order) => {
    const { data } = await fetchData(`/import-files/${fileId}/leads-failed`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getSegment = async (id) => {
    const { data } = await fetchData(`/segments/${id}`)
    return data
}

export const getSegmentAggregations = async (id) => {
    const { data } = await fetchData(`/segments/${id}/aggregations`)
    return data
}

export const getSegmentLeadsCount = async (id) => {
    const { data } = await fetchData(`/segments/${id}/leads-count`)
    return data
}

export const storeSegmentFilters = async ({id, filters}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/segments/${id}/filters`, {
        filters
    })
    return data
}

export const getLead = async (id) => {
    const { data } = await fetchData(`/leads/${id}`)
    return data
}

export const removeLeads = async ids => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/leads/batch`, {
        data: { ids }
    })
    return data
}

export const updateLeadsStatus = async ({ids, status}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/leads/batch-status`, {
        ids, status
    })
    return data
}

export const getLeadSegments = async (id) => {
    const { data } = await fetchData(`/leads/${id}/segments`)
    return data
}

export const getLeadSources = async (id) => {
    const { data } = await fetchData(`/leads/${id}/sources`)
    return data
}

export const getLeadCampaigns = async (id) => {
    const { data } = await fetchData(`/leads/${id}/campaigns`)
    return data
}

export const getLeadLogs = async (id, page, limit) => {
    const { data } = await fetchData(`/leads/${id}/log`, {
        page, limit
    })
    return data
}

export const getConfig = async () => {
    const { data } = await fetchData('/config/general')
    return data
}

export const storeCampaign = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/campaigns`, body)
    return data
}

export const getCampaigns = async (page, limit, search, order) => {
    const { data } = await fetchData('/campaigns', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getCampaignsWithAggregations = async (page, limit, search, order) => {
    const { data } = await fetchData('/campaigns/aggregations', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const getCompletedCampaigns = async () => {
    const { data } = await fetchData('/completed-campaigns')
    return data
}

export const getCampaign = async (id) => {
    const { data } = await fetchData(`/campaigns/${id}`)
    return data
}

export const getCampaignStatistics = async (id) => {
    const { data } = await fetchData(`/campaigns/${id}/statistic`)
    return data
}

export const getCampaignStatisticsLeads = async (campaignId, page, limit, search, order, filterType, filters) => {
    const params = {
        page,
        limit,
        search,
        order,
        ...filters
    }
    if (filterType) {
        params['filter_type'] = filterType
    }

    const { data } = await fetchData(`/campaigns/${campaignId}/statistic-leads`, params)
    return data
}

export const getCampaignSegments = async (id) => {
    const { data } = await fetchData(`/campaigns/${id}/segments`)
    return data
}

export const getCampaignSources = async (id) => {
    const { data } = await fetchData(`/campaigns/${id}/sources`)
    return data
}

export const getCampaignLogs = async (id) => {
    const { data } = await fetchData(`/campaigns/${id}/campaign-logs`)
    return data
}

export const getCampaignSteps = async (id) => {
    const { data } = await fetchData(`/campaigns/${id}/campaign-steps`)
    return data
}

export const storeCampaignSegments = async ({id, ...body}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/segments`, body)
    return data
}

export const storeSegmentFromCampaign = async ({id, ...body}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/create-segment`, body)
    return data
}

export const storeCampaignSources = async ({id, ...body}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/sources`, body)
    return data
}

export const storeCampaignStep = async ({id, ...body}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/campaign-steps`, body)
    return data
}


export const getCampaignLeads = async (campaignId, page, limit, search, order) => {
    const { data } = await fetchData(`/campaigns/${campaignId}/leads`, {
        page,
        limit,
        search,
        order
    })
    return data
}

export const updateCampaign = async ({id, ...body}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/campaigns/${id}`, body)
    return data
}

export const testCampaign = async ({id, ...body}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/campaigns/${id}/test`, body)
    return data
}

export const removeCampaign = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/campaigns/${id}`)
    return data
}

export const updateCampaignStep = async ({id, ...body}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/campaign-steps/${id}`, body)
    return data
}

export const removeCampaignStep = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/campaign-steps/${id}`)
    return data
}

export const removeCampaigns = async ids => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/campaigns/batch`, {
        data: { ids }
    })
    return data
}

export const updateCampaignsStatus = async ({ids, status}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/campaigns/batch-status`, {
        ids, status
    })
    return data
}

export const updateImportFileBlackList = async ({id, ...body}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/imports/${id}/black-list`, body)
    return data
}

export const getAttributes = async (order) => {
    const { data } = await fetchData('/lead-attributes', {
        order
    })
    return data
}

export const storeAttribute = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/lead-attributes`, body)
    return data
}

export const updateAttribute = async ({id, ...body}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/lead-attributes/${id}`, body)
    return data
}

export const removeAttribute = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/lead-attributes/${id}`)
    return data
}

export const getPhoneValidationTemplate = async () => {
    const { data } = await fetchData('/validation-template/phone')
    return data
}

export const updatePhoneValidationTemplate = async values => {
    const { data } = await axios.put(`${apiDataBaseUrl}/validation-template/phone`, {values})
    return data
}

export const getSourcesWithLists = async () => {
    const { data } = await fetchData(`/all-sources-with-lists`, {
        limit: 100,
    })
    return data
}

export const getShortUrlDomains = async (order) => {
    const { data } = await fetchData('/short-url-domains', {
        order
    })
    return data
}

export const getShortUrlDomainsWithAccess = async () => {
    const { data } = await fetchData('/short-url-domains/has-access', )
    return data
}

export const storeShortUrlDomain = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/short-url-domains`, body)
    return data
}

export const updateShortUrlDomain = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/short-url-domains/${id}`, body)
    return data
}

export const removeShortUrlDomain = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/short-url-domains/${id}`)
    return data
}

export const getAlphaNames = async () => {
    const { data } = await fetchData('/sms-fly/alpha-names', )
    return data
}

export const getActiveAlphaNames = async () => {
    const { data } = await fetchData('/sms-fly/active-alpha-names', )
    return data
}

export const storeAlphaName = async body => {
    const {data} = await axios.post(`${apiDataBaseUrl}/sms-fly/alpha-names`, body)
    return data
}

export const sourcesLookup = async ids => {
    const { data } = await axios.post(`${apiDataBaseUrl}/sources/lookup`, {ids})
    return data
}

export const listsLookup = async ids => {
    const { data } = await axios.post(`${apiDataBaseUrl}/sources/lists/lookup`, {ids})
    return data
}

export const lookupStatus = async () => {
    const { data } = await fetchData('/lookup/status', )
    return data
}

export const lookupStart = async () => {
    const { data } = await axios.post(`${apiDataBaseUrl}/lookup-start`)
    return data
}

export const lookupStop = async () => {
    const { data } = await axios.post(`${apiDataBaseUrl}/lookup-stop`)
    return data
}

export const getSMSGateways = async (order) => {
    const { data } = await fetchData('/config/gateway/messaging', {
        order
    })
    return data
}

export const storeSMSGateway  = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/config/gateway/messaging`, body)
    return data
}

export const updateSMSGateway = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/config/gateway/messaging/${id}`, body)
    return data
}

export const removeSMSGateway = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/config/gateway/messaging/${id}`)
    return data
}

export const getSMSTemplates = async (order) => {
    const { data } = await fetchData('/config/template/sms', {
        order
    })
    return data
}

export const storeSMSTemplate  = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/config/template/sms`, body)
    return data
}

export const updateSMSTemplate = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/config/template/sms/${id}`, body)
    return data
}

export const removeSMSTemplate = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/config/template/sms/${id}`)
    return data
}

export const getSmsFlyGatewayAlphaNames = async (gatewayId) => {
    const { data } = await fetchData(`/config/gateway/messaging/${gatewayId}/services/sms-fly` )
    return data
}

export const getTwilioMessagingServices = async (gatewayId) => {
    const { data } = await fetchData(`/config/gateway/messaging/${gatewayId}/services/twilio`)
    return data
}

export const resendActivationCode = async (email) => {
    const { data } = await axios.post(`${apiAuthBaseUrl}/email/resend`, {email})
    return data
}

export const resetPassword = async (body) => {
    const { data } = await axios.post(`${apiAuthBaseUrl}/password/reset`, body)
    return data
}

export const changePassword = async (body) => {
    const { data } = await axios.post(`${apiAuthBaseUrl}/password/change`, body)
    return data
}

export const updateUserInfo = async ({id, ...body}) => {
    const { data } = await axios.put(`${apiDataBaseUrl}/user/${id}`, body)
    return data
}

export const updateUserStatus = async ({id, blocked}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/user/${id}/block`, { blocked })
    return data
}

export const getLookupList = async (page, limit, search, order) => {

    const { data } = await fetchData('/lookup', {
        page,
        limit,
        search,
        order
    })
    return data
}

export const storeLookup = async body => {
    const { data } = await axios.post(`${apiDataBaseUrl}/lookup`, body)
    return data
}

export const   updateLookup = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/lookup/${id}`, body)
    return data
}

export const   updateLookupStatus = async ({id, status}) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/lookup/${id}/status`, {status})
    return data
}

export const removeLookup = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/lookup/${id}`)
    return data
}

export const balanceTransactions = async (page, limit, sorting) => {
    const { data } = await fetchData('/balance/transactions', {
        page, limit, sorting
    })
    return data
}

export const balanceTransactionsTotal = async () => {
    const { data } = await fetchData('/balance/transactions/total')
    return data.data
}

export const balanceTopUp = async ({amount, redirectUrl}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/balance/topup`, {amount, redirect_url: redirectUrl})
    return data.data
}

export const balanceSubscribe = async (planId) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/balance/subscribe`, {
        plan_id: planId
    })
    return data
}

export const balanceShare = async ({amount, memberId, autoRefill = false}) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/balance/share`, {amount, memberId, autoRefill})
    return data.data
}

export const sendSingleSms = async (params) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/leads/single-sms`, params)
    return data
}

export const getSubscriptions = async () => {
    const { data } = await fetchData('/subscriptions', )
    return data
}

export const getInviteInfo = async (token) => {
    const { data } = await fetchData(`/invite/${token}`)
    return data
}

export const getInvitations = async () => {
    const { data } = await fetchData('/invitations', )
    return data
}

export const getTeam = async () => {
    const { data } = await fetchData('/team', )
    return data
}

export const storeInvitation= async (body) => {
    const { data } = await axios.post(`${apiDataBaseUrl}/invitations`, body)
    return data
}

export const  resendInvitation = async (id) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/invitations/${id}/resend`)
    return data
}

export const  updateInvitation = async ({id, ...body}) => {
    const {data} = await axios.put(`${apiDataBaseUrl}/invitations/${id}`, body)
    return data
}

export const removeInvitation = async id => {
    const { data } = await axios.delete(`${apiDataBaseUrl}/invitations/${id}`)
    return data
}

export const  subscribe = async (planId) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/subscribe`, {
        plan_id: planId
    })
    return data
}

export const  subscriptionTest = async (key) => {
    const {data} = await axios.post(`${apiDataBaseUrl}/subscriptions/test`, {
        key
    })
    return data
}

export const   storeLeadAttribute = async ({lead_id, attribute_id, value, type}) => {
    let suffix = 'values'
    if (type) {
        if (type === 'phone') {
            suffix = 'phones'
        }
        if (type === 'email') {
            suffix = 'emails'
        }
    }

    const {data} = await axios.post(`${apiDataBaseUrl}/leads/${lead_id}/lead-attributes/${attribute_id}/lead-${suffix}`, { value })
    return data
}

export const   updateLeadAttribute = async ({id, value, type}) => {
    let url = `${apiDataBaseUrl}/lead-values/${id}`
    if (type) {
        if (type === 'phone') {
            url = `${apiDataBaseUrl}/lead-phones/${id}`
        }
        if (type === 'email') {
            url = `${apiDataBaseUrl}/lead-emails/${id}`
        }
    }

    const {data} = await axios.put(url, { value })
    return data
}
