import {useAppState} from '../../Contexts/AppContext'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getAttributes, getCompletedCampaigns} from '../../api'
import {
    campaignEngagementOptions,
    campaignSmsStatusOptions,
    engagementPeriodOptions,
    leadStatusesOptions
} from '../../config'


const useSegmentFilterAvailableOptions = () => {

    const {availableSources} = useAppState()
    const [campaignOptions, setCampaignOptions] = useState([])
    const [attributeOptions, setAttributeOptions] = useState([])

    /*
     * Fetch Campaigns Options
     */
    const completedCampaignsRequest = useQuery(
        ['completedCampaigns',],
        () => getCompletedCampaigns(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (completedCampaignsRequest.isSuccess) {
            setCampaignOptions(completedCampaignsRequest.data.data.map(item => ({
                id: item.id,
                name: item.name
            })))
        }
    }, [completedCampaignsRequest.isSuccess, completedCampaignsRequest.data])


    /*
     * Fetch Attributes Options
     */
    const attributesRequest = useQuery(
        ['attributes'],
        () => getAttributes(),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (attributesRequest.isSuccess) setAttributeOptions(attributesRequest.data.data)
    },[attributesRequest.isSuccess, attributesRequest.data])

    return {
        sourceOptions: availableSources,
        campaignOptions,
        attributeOptions,
        campaignEngagementOptions,
        campaignSmsStatusOptions,
        leadStatusesOptions,
        engagementPeriodOptions
    }
}

export default useSegmentFilterAvailableOptions
