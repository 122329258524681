import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useQuery} from "react-query";
import {balanceTransactions, balanceTransactionsTotal} from "../../api";
import {useTranslation} from "react-i18next";
import ListTable from "../../components/ListTable/ListTable";

const TransactionsOld = () => {
    const { t } = useTranslation();

    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [pagination, setPagination] = useState(null)
    const [transactions, setTransactions] = useState([])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['transactions', page, limit, sorting],
        () => balanceTransactions(page, limit, sorting),
        {keepPreviousData: true},
    )

    const queryTotal = useQuery(
        ['transactions.total'],
        () => balanceTransactionsTotal(),
        {keepPreviousData: true},
    )

    useEffect(() => {
        isSuccess ? setTransactions(data.data) : setTransactions([])
    }, [isSuccess, data, sorting])

    useEffect(() => {
        if(queryTotal.isSuccess) setPagination({...queryTotal.data, current_page: page})
    }, [queryTotal.isSuccess, page, queryTotal.data])

    const handleLimitChange = useCallback(limit => {
        setPage(1)
        setLimit(limit)
    }, [])

    const tableColumns = useMemo(() => {
        return [
            {Header: 'id', accessor: 'id'},
            {
                Header: 'Description', accessor: 'description',
                Cell: ({value, row}) => {
                    if(row.original.type === 'balance') {
                        return 'Replenishment of the balance'
                    } else {
                        return 'Costs'
                    }
                }
            },
            {Header: 'Amount',
                accessor: 'amount',
                Cell: ({value, row}) => {
                    return ''
                    // return row.original.type === 'balance' ? <div className="chipLike success">{valAmount}</div> : <div className="chipLike warning">- {valAmount}</div>
                }
            },
            {Header: t('Leads List.Updated Date'), accessor: 'created_at'}
        ]
    }, [t])

    const transactionList = useMemo(() => {
        return transactions.map(t => {
            return {...t, description: 'test'}
        })
    }, [transactions])

    return (
        <div className='pageHolder'>
            <ListTable
                columns={tableColumns}
                data={transactionList}
                loading={isLoading}
                fetching={isFetching}
                emptyDataText='Transactions Not Found'
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                onSort={setSorting}
                disableSearchBlock={true}
            />
        </div>
    )
}

export default TransactionsOld
