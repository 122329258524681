import React, {useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Button, CircularProgress} from "@material-ui/core";
import {useAuth} from "../../Contexts/AuthProvider";
import ConfirmModal from "../../components/modals/ConfirmModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    border: '0.5px solid #E5E5E5',
    borderRadius: '8px'
  }
});

const PlanItemCard = ({ item, active = false, disabled = false, onSelect }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { subscriptions } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const selectPlan = () => {
    if (onSelect) {
      setLoading(true);
      onSelect(item).then(() => setLoading(false));
    }
  };
  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{backgroundColor: '#F1F3F4'}}>
              {item.features.map((feature) => {
                return (
                  <TableCell style={{ fontSize: "12px", fontWeight: "500", padding: '5px 20px 5px 20px'}}>
                    {/* {t(`PlansCards.${feature.name}`)} */}
                    {feature.name}
                  </TableCell>
                );
              })}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                {item.features &&
                  item.features.length &&
                  item.features.map((feature) => (
                    <TableCell align="center" key={feature.id} style={{padding: '5px 20px 5px 20px'}}>
                      {t(`PlansCards.${feature.value}`, {
                        defaultValue: feature.value,
                      })}
                    </TableCell>
                  ))}
                   <TableCell align="right" style={{display: 'flex'}}>
                    {/* <div className="btnHolder"> */}
                        <Button
                            onClick={e => {
                                if (Array.isArray(subscriptions) && subscriptions.length > 0) {
                                    setShowConfirmModal(true)
                                }
                                else {
                                    selectPlan()
                                }
                            }
                            }
                            disabled={disabled || item.name === 'Enterprise'}
                            className='btn-billing'
                            // color='primary'
                            variant='contained'
                            size="small"
                        >
                            {loading ? <CircularProgress size={18} color="inherit" /> :
                                (item.name === 'Enterprise' ? t('Subscriptions.Contact us') : t('Subscriptions.Subscribe'))}
                        </Button>
                        {/* <a href="/" className="btnLike">{t("New Profile.SubscriptionPage.Cancel Subscription")}</a> */}
                    {/* </div> */}
                </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className="splItemContent">
                 <table className="spInfoList">
                    <tbody>
                    {item.features.map((feature) => {return <th style={{fontSize: '14px'}}>{t(`PlansCards.${feature.name}`)}</th>})}
                        {
                            item.features && item.features.length &&
                            item.features.map(feature => (
                                    <td key={feature.id}>{t(`PlansCards.${feature.value}`, { defaultValue: feature.value })}</td>
                            ))
                        }
                    </tbody>

                </table>
                <div>
                    <div>
                      {item.features.map((feature) => {return <span style={{fontSize: '14px'}}>{t(`PlansCards.${feature.name}`)}</span>})}
                    </div>
                    <div>
                        {item.features && item.features.length &&
                            item.features.map(feature => (
                                    <span key={feature.id}>{t(`PlansCards.${feature.value}`, { defaultValue: feature.value })}</span>
                        ))}
                    </div>
                </div>
                <div className="btnHolder">
                    <Button
                        onClick={e => {
                            if (Array.isArray(subscriptions) && subscriptions.length > 0) {
                                setShowConfirmModal(true)
                            }
                            else {
                                selectPlan()
                            }
                        }
                        }
                        disabled={disabled || item.name === 'Enterprise'}
                        className='btn-billing'
                        // color='primary'
                        variant='contained'
                        size="small"
                    >
                        {loading ? <CircularProgress size={18} color="inherit" /> :
                            (item.name === 'Enterprise' ? t('Subscriptions.Contact us') : t('Subscriptions.Subscribe'))}
                    </Button>
                    <a href="/" className="btnLike">{t("New Profile.SubscriptionPage.Cancel Subscription")}</a>
                </div>
            </div> */}
      <ConfirmModal
        message={t("Modals.Do you really want to buy a new plan?")}
        open={showConfirmModal}
        handleClose={(e) => setShowConfirmModal(false)}
        handleConfirm={(e) => {
          selectPlan();
          setShowConfirmModal(false);
        }}
      />
    </>
  );
};

export default PlanItemCard;
