import React, {useEffect, useState} from 'react'
import SelectRow from './filters/SelectRow'
import {campaignEngagementOptions, campaignSmsStatusOptions} from '../config'
import AddAttributeButton from '../components/AudienceFilter/AddAttributeButton';
import {useCampaignDispatch, useCampaignState} from '../Contexts/CampaignContext';
import {filterHasEmptyValuesItems} from '../helpers';
import SegmentFilterItem from '../components/SegmentFilter/SegmentFilterItem';

const CampaignStatisticFilters = ({onChange}) => {

    // const [smsStatuses, setSmsStatuses] = useState([])
    // const [engagements, setEngagements] = useState([])
    const {smsStatuses, engagements} = useCampaignState()
    const {setSmsStatuses, setEngagements} = useCampaignDispatch()
    const [filters, setFilters] = useState([])
    const [filterId, setFilterId] = useState([])
    const [attributeMenuOptions, setAttributeMenuOptions] = useState([
        {id: 1, name: 'Sms Status', type: 'Sms Status'},
        {id: 2, name: 'Engagement', type: 'Engagement'},
    ])

    useEffect(() => {
        if (onChange) {
            const filters = {}
            if (smsStatuses.length) {
                filters['sms_status'] = smsStatuses
            }
            if (engagements.length) {
                filters['engagement'] = engagements
            }
            onChange(filters)
        }
    }, [smsStatuses, engagements, onChange])
    const renderAddFilterButton = () => {
        return (
            <AddAttributeButton disabled={filterId.length === 2 || filters[0]?.id === null} onClick={addEmptyFilterItem}/>
        )
    }
    const addEmptyFilterItem = () => {
        setFilters(prev => {
            // add new item if prev state has no any items with empty values
            return filterHasEmptyValuesItems(prev)
                ? prev
                : [...prev, {id: null, 'operator': null, values: [], type: null}]
        })
    }
    const updateFilterRowAttribute = (index, itemAttribute) => {
        setFilters(prevFilters => {
            if (prevFilters[index].id === itemAttribute.id) return prevFilters

            const updatedFilters = [...prevFilters]
            updatedFilters[index] = {
                ...updatedFilters[index],
                id: itemAttribute.id,
                name: itemAttribute.name,
                type: itemAttribute.type,
                values: [],
                operator: null //TODO: or set default operator for selected attribute
            }
            return updatedFilters
        })
    }

    return (
        <div className="segmentsFilterHolder">
            {/*<p className="mb30">Showing leads matching the following:</p>*/}
            <div className="segmentsFilter">
                {filterId.includes('Sms Status') && <SelectRow
                    items={campaignSmsStatusOptions}
                    selectedItems={smsStatuses}
                    onChange={setSmsStatuses}
                    entityLabel={'Sms Status'}
                    onRemove={(entityLabel) => {setFilterId(filterId.filter((e)=> e !== entityLabel));}}
                />}
                {filterId.includes('Engagement') && <SelectRow
                    items={campaignEngagementOptions}
                    selectedItems={engagements}
                    onChange={setEngagements}
                    entityLabel={'Engagement'}
                    onRemove={(entityLabel) => {setFilterId(filterId.filter((e)=> e !== entityLabel))}}
                />}
                <div className="segmentsFilterHolder" style={{position: 'relative'}}>
                    <div className="segmentsFilter">
                        {
                            filters.map((item, index) => {
                                return (
                                    <SegmentFilterItem
                                        item={item}
                                        key={index}
                                        attributeOptions={attributeMenuOptions}
                                        operatorOptions={[]}
                                        valueOptions={null}
                                        onSelectAttribute={(attr) => {setFilterId([...filterId, attr.name]); setFilters([])}}
                                        onSelectOperator={() => {}}
                                        onUpdateValues={() => {}}
                                        onRemove={() => {}}
                                        disabled={false}
                                        singleValue={false}
                                    />
                                )
                            })
                        }
                        {renderAddFilterButton()}
                    </div>
                </div>  
            </div>
        </div>
    )
}

export default CampaignStatisticFilters
