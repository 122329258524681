import React from 'react'
import {Tooltip} from '@material-ui/core'

const TableProgressCell = ({value, value2, tooltip, showProgressBar}) => {

    const renderProgressBar = (value, secondary = false) => {
        if (value === undefined) return null

        const percent = Math.floor(value)

        const barClass = secondary ? 'tableProgressBarItem secondary': 'tableProgressBarItem'

        const body = (
            <div className={barClass}>
                {!showProgressBar && <div className="pbItemHolder">
                    <div className="pbItem" style={{width: `${percent}%`}}/>
                </div>}
                <div className="qnt">{`${percent}%`}</div>
            </div>
        )

        return tooltip ? <Tooltip title={tooltip}>{body}</Tooltip> : body
    }

    return (
        <>
            {renderProgressBar(value)}
            {renderProgressBar(value2, true)}
        </>
    )
}

export default TableProgressCell
