import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getInvitations, removeInvitation, resendInvitation} from '../../../api';
import SaveInviteModal from '../../../components/modals/SaveInviteModal';
import ListTable from '../../../components/ListTable/ListTable';
import {useTranslation} from 'react-i18next';
import DeleteModal from '../../../components/modals/DeleteModal';
import InvitationItemContextMenu from '../../../components/InvitationItemContextMenu';
import {toServerDatetimeFormat} from '../../../Utils/dates';
import AlertToastr from '../../../Utils/alert';

const Invitations = () => {
    const { t } = useTranslation();
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [, setSorting] = useState([]);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState(null)
    const [currentModel, setCurrentModel] = useState(false);

    const deleteRequest = useMutation(removeInvitation);
    const resendRequest = useMutation(resendInvitation);

    const handleResend = useCallback(
        async (model) => {
            setCurrentModel(model);
            await resendRequest.mutateAsync(model.id);
            AlertToastr.showAlert(t('Team.Messages.Success Resend'))
        }, [resendRequest, t]);



    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Team.Fields.Email'),
                accessor: 'email',
            },
            {
                Header: t('Team.Fields.Role'),
                accessor: 'role',
                Cell: ({value}) => t(`Team.Roles.${value}`)
            },
            {
                Header: t('Team.Fields.Created At'),
                accessor: 'created_at',
                Cell: ({value}) => toServerDatetimeFormat(value)
            },
            {
                Header: '',
                id: 'actions',
                className: 'text-right',
                Cell: ({ value, row }) => {
                    return (
                        <InvitationItemContextMenu
                            item={row.original}
                            onEdit={() => handleEdit(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                            onResend={() => handleResend(row.original)}
                        />
                    );
                },
            },
        ];
    }, [t, handleResend]);

    const queryClient = useQueryClient();

    const invitationsRequest = useQuery(
        ['invitations'],
        () => getInvitations(),
        { keepPreviousData: true },
    );

    useEffect(() => {
        if (invitationsRequest.isSuccess) {
            setData(invitationsRequest.data.data);
            setPagination(invitationsRequest.data.meta);
        }
    }, [invitationsRequest.isSuccess, invitationsRequest.data]);

    const handleEdit = (model) => {
        setCurrentModel(model);
        setShowUpdateModal(true);
    };


    const showDeleteConfirmation = (model) => {
        setCurrentModel(model);
        setShowDeleteModal(true);
    };

    const deleteAttribute = async () => {
        if (!currentModel) return;

        await deleteRequest.mutateAsync(currentModel.id);
        setShowDeleteModal(false);
        await queryClient.invalidateQueries('invitations')
        setCurrentModel(null);
    };

    return (
        <Fragment>
            <ListTable
                columns={tableColumns}
                data={data}
                loading={invitationsRequest.isLoading}
                fetching={invitationsRequest.isFetching}
                onSort={setSorting}
                emptyDataText={t('Team.Lists.Invitations.Empty Text')}
                disableSearchBlock={true}
                pagination={pagination}
                disablePagination={true}
            />

            {
                currentModel && (
                    <SaveInviteModal
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setShowUpdateModal(false)
                        }}
                    />
                )
            }

            <DeleteModal
                loading={deleteRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteAttribute}
                handleClose={() => setShowDeleteModal(false)}
                instance={t('Team.Delete Instance')}
            />
        </Fragment>
    );
};

export default Invitations;
