import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {useAuth} from "../../Contexts/AuthProvider";

const MenuItem = ({ item, disabled, setActiveMenu = () => {} }) => {
  const { label, link, icon, action, submenu, isLogout, support, redirect } = item;
  const { logout } = useAuth();
  const location = useLocation();
  const handleOnSubmit = () => {
    logout();
  };
  const openSubMenu = () => {
    setActiveMenu(item);
    let body = document.getElementsByTagName("body");
    if (body[0].classList.contains("subMenuOpened")) {
      body[0].classList.remove("subMenuOpened");
    } else {
      body[0].classList.add("subMenuOpened");
    }
  };
  if (disabled) {
    return (
      <Tooltip title={label} placement="right" arrow enterDelay={0}>
        <div className="menuListItem" style={{ opacity: 0.5 }}>
          <img src={icon} className="ico" alt={label} />
        </div>
      </Tooltip>
    );
  }
  if (support && redirect) {
    return (
      <Tooltip
        title={label}
        placement="right"
        arrow
        enterDelay={0}
      >
        <a href="https://t.me/leadzspace_bot" rel="noreferrer" target="_blank" className="menuListItem marginTopAuto">
          <img src={icon} className="ico" alt={label} />
        </a>
      </Tooltip>
    );
  }
  if (link && !submenu)
    return (
      <Tooltip title={label} placement="right" arrow enterDelay={0}>
        <NavLink to={link} className="menuListItem" activeClassName="active">
          <img src={icon} className="ico" alt={label} />

          {/*<span className="caption">{label}</span>*/}
        </NavLink>
      </Tooltip>
    );

  if (action) {
    return (
      <Tooltip title={label} placement="right" arrow enterDelay={0}>
        <div className="menuListItem" onClick={() => setActiveMenu(item)}>
          <img src={icon} className="ico" alt={label} />
          {/*<span className="caption">{label}</span>*/}
        </div>
      </Tooltip>
    );
  }
  if (isLogout) {
    return (
      <Tooltip
        title={label}
        placement="right"
        arrow
        enterDelay={0}
        onClick={handleOnSubmit}
      >
        <div className="menuListItem">
          <img src={icon} className="ico" alt={label} />
        </div>
      </Tooltip>
    );
  }
  if (submenu && link) {
    return (
      <Tooltip
        title={label}
        placement="right"
        arrow
        enterDelay={0}
        onClick={openSubMenu}
      >
        <NavLink
          to={
            location.pathname.includes("/settings") ||
            location.pathname === "/lookup-management"
              ? location.pathname
              : link
          }
          className="menuListItem"
          activeClassName="active"
        >
          <img src={icon} className="ico" alt={label} />
          {/*<span className="caption">{label}</span>*/}
        </NavLink>
      </Tooltip>
    );
  }

  return null;
};

export default MenuItem;
