import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import {useAsyncDebounce} from 'react-table'
import {useTranslation} from "react-i18next";

const SearchInput = ({search, onChange}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(search)

    const onSearchChange = useAsyncDebounce(value => {
        onChange(value || undefined)
    }, 500)

    return (
        <TextField
            value={value || ''}
            onChange={e => {
                setValue(e.target.value)
                onSearchChange(e.target.value)
            }}
            id="search"
            label={<div className="SearchField">{t('search')}</div>}
            type='search'
            variant="outlined"
        />
    )
}

export default SearchInput
