import React from 'react'
import {useTranslation} from "react-i18next";
import EmptyImg from "../assets/img-new/empty-table.svg"

const TableEmptyListMessage = ({children}) => {
    const { t } = useTranslation();
    return (
        <tr className='emptyTableRow'>
            <td colSpan="100%">
              <div className="emptyTableHolder">
                <div className="imgHolder">
                  <img src={EmptyImg} alt=""/>
                </div>
                <p className="text-center">
                  { children || t('Table Empty List Message.No records found')}
                </p>
              </div>
            </td>
        </tr>
    )
}

export default TableEmptyListMessage
