import React, {useMemo} from 'react';
import {useAuth} from "../../Contexts/AuthProvider";
import Ava1 from "../../assets/img-new/pava.png";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom'
import {Button} from '@material-ui/core';
import LimitCounter from "../../components/LimitCounter";
import Pii1 from "../../assets/img-new/pii1.svg"
import Pii2 from "../../assets/img-new/pii2.svg"
import Pii3 from "../../assets/img-new/levelIcon.svg"

const ProfileDetails = ({handleChange}) => {
    const history = useHistory()
    const {user, userName, subscriptions} = useAuth()
    const { t } = useTranslation();

    const subscriptionName = useMemo(() => {
        return (Array.isArray(subscriptions) && subscriptions.length > 0) ? subscriptions[0].name : null
    }, [subscriptions])
    console.log(user)
    return (
        <>
            <div className="mainSubscriptionPlanHolder">
                <div className="mainSubscriptionPlan">
                    <div className="userInfoWrapper">
                        <div className="userInfoHolder" style={{width: '20%'}}>
                            <div className="userInfo">
                                <div className="avaHolder">
                                    <img src={Ava1} alt="" className="ava" />
                                </div>
                            </div>
                                <div className="userNameHolder">
                                    <div className="titleLike">{t("New Profile.ProfileDetails.Profile Details")}</div>
                                    <div className="userName">{userName}</div>
                                </div>
                                {/* <div className="expDate">exp. 16/12/2021</div> */}
                        </div>
                        <div className="planInfoHolder border padding30" style={{width: '40%'}}>
                            <div className="planInfoTitleHolder">
                                <div>
                                    <div className="subName">{t("New Profile.ProfileDetails.Current Plan")}</div>
                                    <div className="name">{subscriptionName ? t(`PlansCards.${subscriptionName}`) : ''}</div>
                                </div>
                                <div className="btnHolder">
                                    <Button
                                      onClick={(e) => {history.push("/profile/subscriptions"); handleChange('', 2)}}
                                      className='btn-billing'
                                      color='secondary'
                                      variant='contained'
                                      size="small"
                                    >
                                        {t("New Profile.ProfileDetails.Change plan")}
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div className="subName">{t("New Profile.ProfileDetails.level")}</div>
                                <div className="name">Pro</div>
                                    <div className="infoLevel">
                                        <div className="icoHolder">
                                            <img src={Pii3} alt=""/>
                                        </div>
                                        <div>
                                            <div className="title">Total SMS sent: 12200</div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="planInfoHolder" style={{width: '40%'}}>
                            <div className="planInfoTitleHolder">
                                <div className="title">{t("New Profile.ProfileDetails.Current Plan")}</div>
                                <div className="btnHolder">
                                    <Button
                                      onClick={(e) => {history.push("/profile/subscriptions"); handleChange('', 2)}}
                                      className='btn-billing'
                                      variant='contained'
                                      size="small"
                                    >
                                        {t("New Profile.ProfileDetails.Change plan")}
                                    </Button>
                                </div>
                            </div>
                            <ul className="spInfoList">
                                <li className="spInfoListItem">
                                    <div className="spInfoListItemContent">
                                        <div className="icoHolder">
                                            <img src={Pii1} alt=""/>
                                        </div>
                                        <div className="progressHolder">
                                            <div className="progressTitle">{t("New Profile.ProfileDetails.Imported Contacts")}</div>
                                            <div className="progressBar">
                                                <div className="bar" style={{width: '65%'}}/>
                                            </div>
                                        </div>
                                        <div className="countQnt">
                                            <LimitCounter limitName={'import-lim'} />
                                        </div>
                                    </div>
                                </li>
                                <li className="spInfoListItem">
                                    <div className="spInfoListItemContent">
                                        <div className="icoHolder">
                                            <img src={Pii2} alt=""/>
                                        </div>
                                        <div className="progressHolder">
                                            <div className="progressTitle">{t("New Profile.ProfileDetails.Free SMS")}</div>
                                            <div className="progressBar">
                                                <div className="bar" style={{width: '75%'}}/>
                                            </div>
                                        </div>
                                        <div className="countQnt">
                                            <LimitCounter limitName={'free-sms-lim'} />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileDetails
