import React, {useEffect, useMemo, useState} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import SourcesHeader from '../../components/SourcesHeader'
import LoadingValue from '../../components/LoadingValue'
import {useQueries, useQuery} from 'react-query'
import SearchInput from '../../components/SearchInput'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {getSourceAggregations, getSources} from '../../api'
import {useAppDispatch} from '../../Contexts/AppContext'
import SourcesPageActions from './SourcesPageActions'
import BasicTable from '../../components/BasicTable'
import TableProgressCell from '../../components/TableProgressCell'
import profileTabIcon from '../../assets/img-new/profileTabIcon.svg';
import tabSecurityIcon from '../../assets/img-new/tabSecurityIcon.svg';
import {useTranslation} from "react-i18next";

const SourcesPage = () => {
    const { t } = useTranslation();
    const [list, setList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const history = useHistory()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [selectedRows, setSelectedRows] = useState([])
    const [valueTabs, setValueTabs] = useState(1);
    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
    };
    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Sources.Source Name'),
                accessor: 'name'
            },
            {
                Header: t('Sources.Total Leads'),
                accessor: 'total_leads',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Today'),
                accessor: 'total_today',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Last 30 Days'),
                accessor: 'total_last_30_days',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Duplicate'),
                accessor: 'duplicate',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Flagged'),
                accessor: 'total_flagged_leads',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Blacklisted'),
                accessor: 'total_blacklisted_leads',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.VerificationLookup'),
                accessor: 'percentage_verified',
                disableSortBy: true,
                Cell: ({value, row}) => {
                    const {percentage_verified, percentage_lookup} = row.original
                    const progress = <TableProgressCell value={percentage_verified} value2={percentage_lookup} />
                    return <LoadingValue value={value} renderedValue={progress} />
                }
            },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     disableSortBy: true,
            //     className: 'text-right',
            //     Cell: ({value}) => {
            //         const chipClass = value ? 'chipLike success' : 'chipLike blackList'
            //         const chipLabel = value ? 'Active' : 'Inactive'
            //         return (
            //             <div className={chipClass}>
            //                 <span className="chipLikeLabel">{chipLabel}</span>
            //             </div>
            //         )
            //     }
            // }
        ]
    }, [t])
    const tableData = useMemo(() => {
        return list.map(item => {
            return {...item, status: true}
        })
    }, [list])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['sourcesList', page, limit, search, sorting],
        () => getSources(page, limit, search, sorting),
        {keepPreviousData: true}
    )

    const sourceAggregationsRequests = useQueries(list.map(item => {
        return {
            queryKey: ['sourceAggregations', item.id],
            queryFn: () => getSourceAggregations(item.id),
            cacheTime: 1000 * 300,
            refetchOnMount: false,
        }
    }))

    useEffect(() => {
        sourceAggregationsRequests.forEach(req => {
            if (req.isSuccess) {
                setList(prev => {
                    const updatedItem = req.data.data;
                    const newList = [...prev]
                    const index = newList.findIndex(s => s.id === updatedItem.id)
                    if (index !== -1) {
                        newList[index] = {
                            ...newList[index],
                            total_leads: updatedItem.total_leads,
                            total_today: updatedItem.total_today,
                            total_last_30_days: updatedItem.total_last_30_days,
                            duplicate: updatedItem.duplicate,
                            total_blacklisted_leads: updatedItem.total_blacklisted_leads,
                            total_flagged_leads: updatedItem.total_flagged_leads,
                            percentage_lookup: updatedItem.percentage_lookup,
                            percentage_verified: updatedItem.percentage_verified,
                            // leads_count: updatedSegment.leads_count,
                            // duplicates_count: updatedSegment.duplicates_count,
                        }
                    }
                    return newList
                })
            }
        })
    }, [sourceAggregationsRequests])

    // const {
    //     isSuccess: isAggregationSuccess,
    //     isFetching: isAggregationFetching,
    //     data: aggregationData
    // } = useQuery(
    //     ['sourcesListAggregation', page, limit, search, sorting],
    //     () => getSourcesWithAggregations(page, limit, search, sorting),
    //     {keepPreviousData: true, enabled: !isFetching}
    // )

    useEffect(() => {
        setBreadcrumbs([{label: t('Sources.Sources')}])
        setSection('sources')
    }, [setBreadcrumbs, setSection, t])

    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            setPagination(data.meta)
        }
    }, [isSuccess, data])

    // useEffect(() => {
    //     if (isAggregationSuccess) setList(aggregationData.data)
    // }, [isAggregationSuccess, aggregationData])

    useEffect(() => {
        setPage(1)
    }, [search])

    const handleItemClick = (source) => {
        history.push(`/leads/sources/${source.id}`)
    }
    const renderLink = (to, title, description, img, hide = false) => {
        if (hide) return null;
        return (
                <NavLink to={to}>
                    <div className="profileItemContent">
                        <div className="profileItemContent profileItemContent_title_description">
                            <div className="profileItemContent profileItemContent_title_description title">{title}</div>
                        </div>
                    </div>
                </NavLink>
        );
    };
    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={s => setSearch(s)}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="pageHolder">
            <div className="pageTableBlock">
            <div className="LeadsTabs">
                <Tabs value={valueTabs} onChange={handleChangeTabs}>
                    <Tab className="LeadsTabsTab" label={renderLink('/leads', t('New Profile.Leads'), t('New Profile.Change Personal Information'), profileTabIcon)}/>
                    <Tab className="LeadsTabsTab" label={renderLink('/leads/sources', t('New Profile.Sources'), t('New Profile.Change Password'), tabSecurityIcon)}/>
                </Tabs>
            </div>
            <div className="searchWrapper">
                {renderSearchBlock()}
                <div className="pageHeadingBlock mb15">
                    <SourcesHeader/>
                </div>
            </div>
                <div className="tableHolder hasCheckboxes rowClickable">
                    <BasicTable
                        columns={tableColumns}
                        data={tableData}
                        loading={isLoading}
                        fetching={isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onRowClick={handleItemClick}
                        onSort={setSorting}
                        emptyDataText={t('Sources.No Sources Found')}
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={(l) => {
                            setPage(1)
                            setLimit(l)
                        }}
                        showTabs={true}
                        tabsValue={1}
                    />
                </div>
            </div>
            <SourcesPageActions
                selectedRows={selectedRows}
                bulkDone={() => {
                    setList(prev => [...prev])
                }}
            />
        </div>
    )
}

export default SourcesPage
