import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import ApiService from '../Services/Api.service';
import DataService from '../Services/DataService';
import axios from 'axios';
import AlertToastr from '../Utils/alert';
import {storageKeys} from '../config';
import {useQuery} from 'react-query';
import SubscriptionRequiredModal from '../components/modals/SubscrptionRequiredModal';
import {useHistory, useLocation} from 'react-router-dom';
import User from '../models/User';

const STORAGE_ACCESS_TOKEN_KEY = '_t';
const AuthContext = createContext({});
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [subscriptions, setSubscriptions] = useState();
    const [freeSmsCount, setFreeSmsCount] = useState();
    const [balance, setBalance] = useState(null);
    const [userId, setUserId] = useState(0);
    const [role, setRole] = useState(null);
    const [accessDenied, setAccessDenied] = useState(false);
    const [subscriptionShowed, setSubscriptionShowed] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const userRequest = useQuery(
        'authUser',
        () => DataService.getUserInfo(),
        { keepPreviousData: true, enabled: !!accessToken },
    );

    useEffect(() => {
        if (userRequest.isSuccess && userRequest.data) {
            const user = userRequest?.data?.data?.data;
            setUser(new User(user));
            setUserName(user.name);
            setUserId(user.id);
            setBalance(user.balance);
            setUserPhone(user.phone);
            setSubscriptions(user.subscriptions);
            setFreeSmsCount(user.free_sms);
            setRole(user.role)
            if (!user.email_verified_at) {
                setAccessDenied(true);
            }
        }
    }, [
        userRequest.isSuccess, userRequest.data,
    ]);

    const setAuthData = useCallback(async (token, isFirstLogin) => {
        ApiService.init();
        if (!token) {
            localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
            localStorage.removeItem(storageKeys.leadsHiddenColumnsInList);
            localStorage.removeItem(storageKeys.leadsHiddenColumnsInCampaign);
            localStorage.removeItem(storageKeys.leadsHiddenColumnsInLeads);
            localStorage.removeItem(storageKeys.leadsHiddenColumnsInCampaignAudience);
            localStorage.removeItem(storageKeys.leadsHiddenColumnsInSegment);
            localStorage.removeItem(storageKeys.leadsHiddenColumnsInCampaignsList);
            setIsAuthenticated(false);
            setAccessToken('');
            setUserName('');
            setUserPhone('');
            setSubscriptions(undefined);
            setBalance(0);
            setUserId(0);
            setAccessDenied(false);
            setRole(null);
            setUser(null);
            ApiService.removeAuthHeader();
            return;
        }
        try {
            if (!isFirstLogin) {
                setAccessToken(token);
                setIsAuthenticated(true);
            }
            ApiService.setAuthHeader(token);

            if (isFirstLogin) {
                setAccessToken(token);
                setIsAuthenticated(true);
            }
        } catch (e) {
            AlertToastr.showErrorAlert('Error while fetching user name');
        }

    }, []);

    const loginHandler = useCallback((token, isFirstLogin = false) => {
        setAuthData(token, isFirstLogin).then();
        localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, token);
    }, [setAuthData]);

    const forbiddenHandler = useCallback(() => {
        setAccessDenied(true);
    }, [setAccessDenied]);

    const logoutHandler = useCallback(() => {
        setAuthData().then();
        setSubscriptionShowed(false)
    }, [setAuthData]);

    // init auth status
    useEffect(() => {
        const at = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
        if (at) {
            setAuthData(at);
        }
        setUserLoading(false);
    }, [setAuthData]);

    // api errors handler
    useEffect(() => {
        const authInterceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response) {
                    const status = error.response.status || 500;

                    if (status === 404) {
                        console.log('api endpoint not found');
                        return;
                    }

                    if (status === 401) {
                        logoutHandler();
                    }

                    if (status === 403) {
                        //TODO: make this handler more user friendly
                        // if (error.response.data && error.response.data.type === 'auth@not-approved') {
                        if (error.response.data) {
                            if (error.response.data.message === 'account_blocked') {
                                logoutHandler()
                            } else if (error.response.data.message === 'This action is unauthorized.') {
                                logoutHandler()
                                // AlertToastr.showErrorAlert(error.response.data.message)
                            } else {
                                forbiddenHandler();
                            }
                        } else {
                            logoutHandler();
                        }
                        return;
                    }
                }
                throw error;
            });
        return () => {
            axios.interceptors.request.eject(authInterceptor);
        };
    }, [
        forbiddenHandler,
        logoutHandler,
    ]);


    const hasActiveSubscriptions = () => {
        if (userLoading) return true;
        if (!subscriptions) return true;
        return !(subscriptions && !subscriptions.length);
    }


    const showSubscriptionModal =
        !subscriptionShowed
        && !accessDenied
        && !hasActiveSubscriptions()
        && !!userName
        && (user && user.role === 'team_owner')
        && !location.pathname.includes('profile')


    const allowActions = hasActiveSubscriptions()

    return (
        <AuthContext.Provider value={{
            user,
            userIsLoading: userRequest.isLoading,
            accessDenied: accessDenied,
            isLoggedIn: isAuthenticated,
            userName: userName,
            userPhone: userPhone,
            userId: userId,
            balance: balance,
            token: accessToken,
            login: loginHandler,
            logout: logoutHandler,
            setUserName: setUserName,
            setUserPhone: setUserPhone,
            role,
            subscriptions,
            freeSmsCount,
            allowActions
        }}>

            {!userLoading && children}
            {/*{!!user && children}*/}
            <SubscriptionRequiredModal
                open={showSubscriptionModal}
                handleConfirm={() => {
                    setSubscriptionShowed(true);
                    history.push('/profile/subscriptions')
                }}
                handleClose={() => {
                    setSubscriptionShowed(true);
                }}
            />
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
