import React, {Fragment, useEffect, useMemo} from 'react';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {useMutation, useQueryClient} from 'react-query';
import {storeInvitation, updateInvitation,} from '../../api';
import AlertToastr from '../../Utils/alert';
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from '../form/ReactHookFormSelect';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

const SaveInviteModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
        onSuccess
    } = props;

    const roleOptions = useMemo(() => {
        return [
            { key: 'team_admin', label: t('Team.Roles.team_admin') },
            { key: 'team_operator', label: t('Team.Roles.team_operator') },
        ];
    }, [t]);

    const queryClient = useQueryClient();

    const {
        control,
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });

    const storeMutation = useMutation(storeInvitation);
    const updateMutation = useMutation(updateInvitation);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error);
        }
    }, [storeMutation.error]);

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error);
        }
    }, [updateMutation.error]);

    const onSubmit = handleSubmit(async data => {

        if (defaultValues) {
            await updateMutation.mutateAsync({ id: defaultValues.id, ...data });
            handleClose();
            queryClient.invalidateQueries('invitations').then();
        } else {
            try {
                await storeMutation.mutateAsync(data);
                onSuccess();
                handleClose();
                queryClient.invalidateQueries('invitations').then();
            } catch (e) {
                console.log(e);
            }
        }

    });


    const title = defaultValues ? t('Team.Update Invitation') : t('Team.New Invitation');
    const saveButtonTitle = defaultValues ? t('Modals.Save') : t('Team.Invite');
    const isLoading = storeMutation.isLoading || updateMutation.isLoading;

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal"
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            disabled={!!defaultValues?.email}
                            inputRef={register}
                            name="email"
                            label={t('Modals.Email')}
                            variant="outlined"
                            margin="normal"
                        />
                        <ReactHookFormSelect
                            variant="outlined"
                            margin="normal"
                            id="role-select"
                            name="role"
                            label={t('Modals.Role')}
                            control={control}
                            defaultValue=""
                        >
                            {roleOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>

                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {saveButtonTitle}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default SaveInviteModal;
