import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import {ReactComponent as AddCircleIcon} from '../assets/img-new/addFundsIcon.svg';
import AddBalanceModal from "./modals/AddBalanceModal";
import {useAuth} from "../Contexts/AuthProvider";
import {useAppState} from "../Contexts/AppContext";
import {CircularProgress, Tooltip} from "@material-ui/core";
import UserBalance from "./balance/UserBalance";

export const isBalance = balance => balance != null

export const HasBalance = ({ not = null, children }) => {
    const { isLoggedIn } = useAuth()
    const { balance } = useAppState()

    if (!isLoggedIn) return null

    return isBalance(balance) ? <>{children}</> : not
}

const HeaderBalanceButton = () => {
    const { role } = useAuth()
    const { balance } = useAppState()
    const [open, setOpen] = useState(false)

    const handleBalanceClick = () => {
        if(role === 'team_operator') return false
        if (isBalance(balance)) setOpen(true)
    }

    return (
      <div className="headerBalance">
          <HasBalance
            not={<Button size='small' variant='contained' color={'primary'}><CircularProgress size={19} /></Button>}>
              {role === 'team_operator' ? (
                <></>
              ) : (
                <Tooltip title="Add Funds" arrow>
                  <div className="menuList">
                    <div className="menuListItem" style={{padding: '4px'}} onClick={handleBalanceClick}>
                        <AddCircleIcon height="24px" width="24px"/>
                    </div>
                  </div>
                </Tooltip>
              )}
              <div className="qntHolder">
                  Balance: &nbsp;
                  <UserBalance />
              </div>
            <AddBalanceModal open={open} handleClose={() => setOpen(false)} />
        </HasBalance>
      </div>
    )
}

export default HeaderBalanceButton

