import React from 'react'
import InlineLoader from './loaders/InlineLoader'

const LoadingValue = ({value, renderedValue, suffix}) => {
    let render = renderedValue || value
    if (suffix) {
        if (suffix === '%' && render < 0.01) {
            render = '0'
        }
        render = `${render} ${suffix}`
    }
    return (value === 'loading' ? <InlineLoader /> : render)
}

export default LoadingValue
